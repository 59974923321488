import React, { PureComponent } from 'react';
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert, Container } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment-timezone';
import styled from 'styled-components';
import * as responsesActions from './actions/responsesActions';
import * as surveysActions from './actions/surveysActions';
import * as settingsActions from './actions/settingsActions';
import * as videosActions from './actions/videosActions';
import Header from './views/blocks/Header';
import Footer from './views/blocks/Footer';
import BurstSummary from './views/BurstSummary';
import Calculators from './views/Calculators';
import Home from './views/Home';
import Messages from './views/Messages';
import Photos from './views/Photos';
import Resources from './views/Resources';
import Settings from './views/Settings';
import Survey from './views/Survey';
import SurveyCompletion from './views/SurveyCompletion';
import Taker from './views/Taker';
import Tips from "./views/Tips";
import Toolkit from './views/Toolkit';
import Videos from './views/Videos';
import WeeklySummary from './views/WeeklySummary';
import BacChart from "./views/BacChart";
import ErrorBoundary from "./views/blocks/ErrorBoundary";
import MessagesHelpful from "./views/MessagesHelpful";
import StandardDrinks from "./views/StandardDrinks";
import ContactUs from "./views/ContactUs";

library.add(fal, fas);

const PageSize = styled.div`
    margin-top: 56px;
    margin-bottom: 80px;
    height: calc(100vh - 56px - 80px);
    overflow-y: hidden;

    @media (min-width: 768px) {
        margin-bottom: 0px;
        height: calc(100vh - 56px);
    }
`;

const ContainerSize = styled.main`
    height: 100%;
    overflow-y: auto;
`;

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      unsubmittedSurveysCount: 0,
    };
  }

  componentDidMount() {
    this.updateApp();
    this.setSurveysInterval = setInterval(() => {
      this.updateApp();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.setSurveysInterval);
  }

  submitSurvey() {
    const schedule = this.props.surveys.schedule.schedule;
    const now = moment();
    const unsubmittedSurveys = [];

    if (this.props.settings.isOnline === false) {
      return;
    }

    if (this.props.surveys.openSurveys.length > 0) {
      return;
    }

    for (const burst in schedule) {
      if (schedule.hasOwnProperty(burst)) {
        const burstSched = schedule[burst];
        for (const survey in burstSched) {
          if (burstSched.hasOwnProperty(survey)
            && this.props.responses.hasOwnProperty(burstSched[survey].slug)
            && typeof this.props.responses[burstSched[survey].slug].responses !== 'undefined'
            && Object.keys(this.props.responses[burstSched[survey].slug].responses).length > 0) {
            const responses = this.props.responses[burstSched[survey].slug];
            let closed = false;
            let complete = false;

            if (responses.meta.uploaded_at !== null) {
              if (responses.hasOwnProperty('responses')) {
                this.props.responsesActions.responseCleanup(
                  burstSched[survey].slug
                );
              }
              continue;
            }

            if (responses.meta.isUploading !== null && now.diff(moment(responses.meta.isUploading)) < 360000) {
              continue;
            }

            if (burstSched[survey].closed_at !== null && now.diff(moment(burstSched[survey].closed_at), 'minutes') > 5) {
              closed = true;
            }

            if (responses.meta.completed_at !== null) {
              complete = true;
            }

            if (closed === false && complete === false) {
              continue;
            }

            unsubmittedSurveys.push(burstSched[survey].slug);
          }
        }
      }
    }

    this.setState({
      unsubmittedSurveysCount: unsubmittedSurveys.length,
    });

    if (unsubmittedSurveys.length > 0) {
      // pick one of the surveys at random to submit so in case of an error we won't always retry the same survey
      const keyToSubmit = Math.floor(Math.random() * unsubmittedSurveys.length);

      this.props.responsesActions.submitResponses(
        unsubmittedSurveys[keyToSubmit],
      );
    }
  }

  submitVideo() {
    const videos = this.props.videos;
    const now = moment();
    let unsubmittedVideos = [];

    if (this.props.settings.isOnline === false) {
      return;
    }

    for (let i = 1; i <= 5; i++) {
      if (videos[i].uploading !== null && now.diff(videos[i].uploading) < 36000) {
        console.log('now.diff: ', now.diff(videos[i].uploading));
        continue;
      }

      if ( videos[i].events.length > 0) {
        unsubmittedVideos.push(i);
      }
    }

    console.log(unsubmittedVideos);

    if (unsubmittedVideos.length > 0) {
      // pick one of the videos at random to submit so in case of an error we won't always retry the same survey
      const keyToSubmit = Math.floor(Math.random() * unsubmittedVideos.length);

      this.props.videosActions.submitEvents(
        unsubmittedVideos[keyToSubmit],
      );
    }

  }

  updateApp() {
    this.props.settingsActions.fetchConnectionStatus();
    this.props.surveysActions.setOpenSurveys(this.props.surveys.schedule.schedule, this.props.responses);
    this.props.surveysActions.setFutureSurveys(this.props.surveys.schedule.schedule);
    this.props.surveysActions.setPastSurveys(this.props.surveys.schedule.schedule, this.props.responses);
    this.submitSurvey();
    this.submitVideo();
  }

  render() {
    const noAuthAlert = (
      <Alert color="danger">
        You are not logged in. Please
        { ' ' }
        <Link to="/settings">log in</Link>
        { ' ' }
        to participate in the study.
      </Alert>
    );

    // const offlineAlert = (
    //   <Alert color="primary">
    //     You are currently offline. Please connect to a stable network when available.
    //   </Alert>
    // );

    const storageAlertWarning = (
      <Alert color="warning">
        It has been awhile since your data has been uploaded. Please connect to a stable network to upload your data.
      </Alert>
    );

    const storageAlertDanger = (
      <Alert color="danger">
        Your storage levels have reached a critical level. Please connect to a stable network to upload your data.
      </Alert>
    );

    const submitSurveysAlert = (
      <Alert color="warning">
        You have { this.state.unsubmittedSurveysCount } completed survey(s) that have not been uploaded.
      </Alert>
    );

    // we are going to only show the most important alert, so order them least important to most important
    let notification = null;

    if (this.state.unsubmittedSurveysCount > 1) {
      notification = submitSurveysAlert;
    }

    // if (!this.props.settings.isOnline) {
    //   notification = offlineAlert;
    // }

    if (this.props.settings.usage.usagePercent > 10) {
      notification = storageAlertWarning;
    }

    if (this.props.settings.usage.usagePercent > 20) {
      notification = storageAlertDanger;
    }

    if (this.props.settings.auth.signedIn === false) {
      notification = noAuthAlert;
    }

    return (
      <BrowserRouter>
        <div>
          <Header/>
          <PageSize>
            <ContainerSize>
              { notification }
              <Container
                className="py-3 h-100"
              >
                <ErrorBoundary>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={ Home }
                    />
                    <Route
                      path="/bac-chart"
                      component={ BacChart }
                    />
                    <Route
                      path="/burst-summary"
                      component={ BurstSummary }
                    />
                    <Route
                      path="/calculators"
                      component={ Calculators }
                    />
                    <Route
                      path="/contact-us"
                      component={ ContactUs }
                    />
                    <Route
                      path="/helpful-messages"
                      component={ MessagesHelpful }
                    />
                    <Route
                      path="/messages"
                      component={ Messages }
                    />
                    <Route
                      path="/photos"
                      component={ Photos }
                    />
                    <Route
                      path="/resources"
                      component={ Resources }
                    />
                    <Route
                      path="/settings"
                      component={ Settings }
                    />
                    <Route
                      path="/standard-drinks"
                      component={ StandardDrinks }
                    />
                    <Route
                      path="/survey"
                      component={ Survey }
                    />
                    <Route
                      path="/survey-completion"
                      component={ SurveyCompletion }
                    />
                    <Route
                      path="/taker/:slug"
                      component={ Taker }
                    />
                    <Route
                      path="/tips"
                      component={ Tips }
                    />
                    <Route
                      path="/tool-kit"
                      component={ Toolkit }
                    />
                    <Route
                      path="/videos"
                      component={ Videos }
                    />
                    <Route
                      path="/weekly-summary"
                      component={ WeeklySummary }
                    />
                    <Redirect to="/"/>
                  </Switch>
                </ErrorBoundary>
              </Container>
            </ContainerSize>
          </PageSize>
          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    surveysActions: bindActionCreators(surveysActions, dispatch),
    videosActions: bindActionCreators(videosActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
