import React, { Component } from 'react';

export default class StandardDrinks extends Component {
  render() {
    return (
      <div className={'mb-3'}>
        <img
          src={'/img/standard-drinks.png'}
          alt={'Illustration comparing standard drinks'}
        />
      </div>
    );
  }
}