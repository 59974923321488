import axios from 'axios';

export const FETCHING_SUMMARY = 'FETCHING_SUMMARY';
export const SET_SUMMARY = 'SET_SUMMARY';

export const setFetching = (bool) => {
  return {
    type: FETCHING_SUMMARY,
    value: bool,
  };
};

export const setSummary = (summary) => {
  return {
    type: SET_SUMMARY,
    period: summary.period,
    summary,
  };
};

export const incrementViewed = (page, subPage, elapsed) => {
  return (dispatch, getState) => {
    const summary = getState().summaries[subPage];

    if (typeof summary.props === 'undefined') {
      summary.props = {};
    }

    if (typeof summary.props.viewedSeconds === 'undefined') {
      summary.props.viewedSeconds = 0;
    }

    summary.props.viewedSeconds += elapsed;

    dispatch({
      type: SET_SUMMARY,
      period: summary.period,
      summary,
    });
  };
};

export const fetchSummary = (period) => {
  return function (dispatch, getState) {
    dispatch(setFetching(true));

    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ getState().settings.auth.accessToken }`,
      },
    };

    return (dispatch) => {
      axios.get(`${ process.env.REACT_APP_API_URL }/summary/${ period }`, config)
        .then((res) => {
          dispatch(setSummary(
            res.data,
          ));
        })
        .catch((error) => {
          // error handling
          const log = {
            error: JSON.stringify({
              app: 'uwamps_pwa',
              altpid: getState().participant.data.altpid || 'not set',
              endpoint: error.config.url,
              message: error.message,
              origin: 'summaryAction.fetchSummary',
              period: period,
            })
          };

          axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
        })
        .then(() => {
          // this will always fire
          dispatch(setFetching(false));
        });
    };
  };
};