export default {
  calculators: {
    bac: {
      sex: null,
      weight: null,
      drinks: 0.00,
      hours: 0.0,
      gf: {
        0: 7.5,
        1: 9,
      },
      bac: 0,
      timeToZero: 0,
    },
    abv: {
      abv: 0,
      drinkSizeEquiv: '',
      abvContainerSize: 0,
      drinksPerContainer: '',
    },
  },
  interventionMessages: {
    isFetching: false,
    isUpdating: false,
    isFetchingCustom: false,
    isSelectingDefault: false,
    isTruingUp: false,
    messages: [
      'day_0_am',
      'day_0_pm',
      'day_1_am',
      'day_1_pm',
      'day_2_am',
      'day_2_pm',
      'day_3_am',
      'day_3_pm',
      'day_4_am',
      'day_4_pm',
      'day_5_am',
      'day_5_pm',
      'day_6_am',
      'day_6_pm',
      'day_7_am',
      'day_7_pm',
      'day_8_am',
      'day_8_pm',
      'day_9_am',
      'day_9_pm',
      'day_10_am',
      'day_10_pm',
      'day_11_am',
      'day_11_pm',
      'day_12_am',
      'day_12_pm',
      'day_13_am',
      'day_13_pm',
      'day_14_am',
      'day_14_pm',
      'day_15_am',
      'day_15_pm',
      'day_16_am',
      'day_16_pm',
      'day_17_am',
      'day_17_pm',
      'day_18_am',
      'day_18_pm',
      'day_19_am',
      'day_19_pm',
      'day_20_am',
      'day_20_pm',
      'day_21_am',
      'day_21_pm',
      'day_22_am',
      'day_22_pm',
    ],
    am: {
      day_0: {
        custom: null,
        default: null,
      },
      day_1: {
        custom: null,
        default: null,
      },
      day_2: {
        custom: null,
        default: null,
      },
      day_3: {
        custom: null,
        default: null,
      },
      day_4: {
        custom: null,
        default: null,
      },
      day_5: {
        custom: null,
        default: null,
      },
      day_6: {
        custom: null,
        default: null,
      },
      day_7: {
        custom: null,
        default: null,
      },
      day_8: {
        custom: null,
        default: null,
      },
      day_9: {
        custom: null,
        default: null,
      },
      day_10: {
        custom: null,
        default: null,
      },
      day_11: {
        custom: null,
        default: null,
      },
      day_12: {
        custom: null,
        default: null,
      },
      day_13: {
        custom: null,
        default: null,
      },
      day_14: {
        custom: null,
        default: null,
      },
      day_15: {
        custom: null,
        default: null,
      },
      day_16: {
        custom: null,
        default: null,
      },
      day_17: {
        custom: null,
        default: null,
      },
      day_18: {
        custom: null,
        default: null,
      },
      day_19: {
        custom: null,
        default: null,
      },
      day_20: {
        custom: null,
        default: null,
      },
      day_21: {
        custom: null,
        default: null,
      },
      day_22: {
        custom: null,
        default: null,
      },
    },
    pm: {
      day_0: {
        custom: null,
        default: null,
      },
      day_1: {
        custom: null,
        default: null,
      },
      day_2: {
        custom: null,
        default: null,
      },
      day_3: {
        custom: null,
        default: null,
      },
      day_4: {
        custom: null,
        default: null,
      },
      day_5: {
        custom: null,
        default: null,
      },
      day_6: {
        custom: null,
        default: null,
      },
      day_7: {
        custom: null,
        default: null,
      },
      day_8: {
        custom: null,
        default: null,
      },
      day_9: {
        custom: null,
        default: null,
      },
      day_10: {
        custom: null,
        default: null,
      },
      day_11: {
        custom: null,
        default: null,
      },
      day_12: {
        custom: null,
        default: null,
      },
      day_13: {
        custom: null,
        default: null,
      },
      day_14: {
        custom: null,
        default: null,
      },
      day_15: {
        custom: null,
        default: null,
      },
      day_16: {
        custom: null,
        default: null,
      },
      day_17: {
        custom: null,
        default: null,
      },
      day_18: {
        custom: null,
        default: null,
      },
      day_19: {
        custom: null,
        default: null,
      },
      day_20: {
        custom: null,
        default: null,
      },
      day_21: {
        custom: null,
        default: null,
      },
      day_22: {
        custom: null,
        default: null,
      },
    },
  },
  participant: {
    isFetching: false,
    data: {
      lastFetch: 0,
    },
  },
  photos: {
    uploaded: [],
  },
  responses: {},
  settings: {
    auth: {
      isFetching: false,
      signedIn: false,
      slug: '',
      phone: '',
      accessToken: '',
      accessTokenDecoded: {},
    },
    isOnline: true,
    permissions: {
      push: false,
    },
    timezone: null,
    usage: {
      quota: 0,
      quotaStr: 0,
      usage: 0,
      usageStr: 0,
      usagePercent: 0,
    },
  },
  surveys: {
    futureSurveys: [],
    openSurveys: [],
    pastSurveys: [],
    schedule: {
      isFetching: false,
      lastFetch: 0,
      schedule: {},
    },
    data: {
      isFetching: false,
      lastFetch: 0,
    },
  },
  videos: {
    1: {
      name: 'AMPS Intro Video',
      src: 'https://vimeo.com/394974400/35845ea5cf',
      started: false,
      completed: false,
      progress: 0,
      events: [],
      uploading: null,
    },
    2: {
      name: 'AMPS Sleep Video',
      src: 'https://vimeo.com/394974440/0260b2c492',
      started: false,
      completed: false,
      progress: 0,
      events: [],
      uploading: null,
    },
    3: {
      name: 'BARLAB Video',
      src: 'https://vimeo.com/394974286/377db8f5d9',
      started: false,
      completed: false,
      progress: 0,
      events: [],
      uploading: null,
    },
    4: {
      name: 'Tolerance Video',
      src: 'https://vimeo.com/394974465/0b730eaef4',
      started: false,
      completed: false,
      progress: 0,
      events: [],
      uploading: null,
    },
    5: {
      name: 'Bill Nye at the BARLAB',
      src: 'https://youtu.be/8EWqy6MGpxo',
      started: false,
      completed: false,
      progress: 0,
      events: [],
      uploading: null,
    },
  },
  summaries: {
    isFetching: false,
    'week-1': {},
    'week-2': {},
    'week-3': {},
    'burst-1': {},
  },
};
