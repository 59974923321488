import React, { Component } from 'react';

export default class Resources extends Component {
  render() {
    return (
      <div>
        <h2>Additional Resources</h2>

        <div class="mt-4">
          <div><span className="font-weight-bold">Contact Us</span> (during regular business hours)</div>
          <div className={ 'pl-3' }>
            <div>
              <a href="mailto:amps@uw.edu">amps@uw.edu</a><br/>
            </div>
            <div>
              <a href="tel:206-221-6390">206-221-6390</a>
            </div>
          </div>
        </div>

        <p className="mt-4">
          <span className="font-weight-bold">If you are in imminent danger, please call 911</span>
        </p>

        <div className="mt-4">
          <div className="font-weight-bold">University of Washington</div>
          <div className={ 'pl-3' }>
            <div>
              <a
                href="https://depts.washington.edu/livewell"
                target="_blank"
                rel="noopener noreferrer">https://depts.washington.edu/livewell</a>
            </div>
            <p className={ 'text-muted' }>
              LiveWell – a safe and confidential space for UW students to consult about issues with alcohol and other
              drug use, distress, relationship violence, sexual harassment, and sexual assault.
            </p>
          </div>
        </div>

        <div className="mt-4">
          <div className="font-weight-bold">Edmonds Community College</div>
          <div className={ 'pl-3' }>
            <div>
              <a
                href="https://edcc.edu/counseling/"
                target="_blank"
                rel="noopener noreferrer">https://edcc.edu/counseling</a>
            </div>
            <p className={ 'text-muted' }>
              Counseling and Resource Center offers free and confidential personal, career, and student success
              counseling to their students.
            </p>
          </div>
        </div>

        <div className="mt-4">
          <div className="font-weight-bold">Shoreline Community College</div>
          <div className={ 'pl-3' }>
            <div>
              <a
                href="https://shoreline.edu/counseling-center/"
                target="_blank"
                rel="noopener noreferrer">https://shoreline.edu/counseling-center</a>
            </div>
            <p className={ 'text-muted' }>
              The Counseling Center provides free, and confidential and professional mental health counseling services,
              resources, and referral to support the academic and personal success, health, and well-being of their
              students and campus community.
            </p>
          </div>
        </div>

        <div class="mt-4">
          <div className="font-weight-bold">24/7 SAMHSA’s National helpline</div>
          <div className={ 'pl-3' }>
            <div>
              <a href="tel:1-800-662-4357">1-800-662-HELP (4357)</a>
            </div>
            <div>
              <a
                href="https://findtreatment.samhsa.gov/"
                target="_blank"
                rel="noopener noreferrer">https://findtreatment.samhsa.gov/</a>
            </div>
            <p className={ 'text-muted' }>
              This Helpline provides 24-hour free and confidential treatment referral and information about mental
              and/or
              substance use disorders, prevention, and recovery in English and Spanish.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
