import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import * as responsesActions from '../../../actions/responsesActions';
import HiddenInput from './HiddenInput';

class Calculation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containsPipe: false,
      showElement: false,
    };
  }

  render() {
    return (
      <div className={ 'mt-3 taker__element taker__calculation d-none' }>
        <HiddenInput
          element_var_name={ this.props.var_name }
          parent={ this.props.slug }
          props={ this.props.answers.answer_group_0[0].props }
          { ...this.props.answers.answer_group_0[0] }
          participantWindow={ this.props.participantWindow }
          showElement={ this.state.showElement }/>;
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calculation);
