import React, { Component } from 'react';
import style from 'styled-components';

const LoadingWrapper = style.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100%;
`;

export default class Loading extends Component {
  render() {
    return (
      <LoadingWrapper>
        <h2>Loading...</h2>
      </LoadingWrapper>
    );
  }
}
