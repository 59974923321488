import produce from 'immer';
import initialState from '../initialState';
import {
  FETCHING_SCHEDULE,
  FETCHING_SURVEY,
  SET_FUTURESURVEYS,
  SET_OPENSURVEYS,
  SET_PASTSURVEYS,
  UPDATE_SCHEDULE,
  UPDATE_SURVEY,
} from '../actions/surveysActions';

export default function update(state = initialState.surveys, action) {
  let newState;
  switch (action.type) {
    case FETCHING_SCHEDULE:
      if (state.schedule.isFetching === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.schedule.isFetching = action.value;
      });
      return newState;

    case FETCHING_SURVEY:
      if (state.data.isFetching === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.data.isFetching = action.value;
      });
      return newState;

    case SET_FUTURESURVEYS:
      if (state.futureSurveys === action.surveys) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.futureSurveys = action.surveys;
      });
      return newState;

    case SET_OPENSURVEYS:
      if (state.openSurveys === action.surveys) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.openSurveys = action.surveys;
      });
      return newState;

    case SET_PASTSURVEYS:
      if (state.pastSurveys === action.surveys) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.pastSurveys = action.surveys;
      });
      return newState;

    case UPDATE_SCHEDULE:
      newState = produce(state, (draft) => {
        draft.schedule.schedule = action.schedule;
        draft.schedule.map = action.map;
        draft.schedule.lastFetch = action.lastFetch;
      });
      return newState;

    case UPDATE_SURVEY:
      newState = produce(state, (draft) => {
        draft.data[action.slug] = action.data;
        draft.data.lastFetch = action.lastFetch;
      });
      return newState;

    default:
      return state;
  }
}
