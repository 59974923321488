import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as responsesActions from '../../../actions/responsesActions';
import React, { Component } from 'react';
import { Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb';

class TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasLabel: false,
      maskAs: null,
      name: this.props.parent + '|' + this.props.slug,
      showAnswer: true,
      value: '',
      valuePcs: [],
    };

    if (props.label !== null && props.label.length > 0) {
      this.state.hasLabel = true;
    }
  }

  componentDidMount() {
    let recordedVal = '';

    if (typeof this.props.responses[this.props.participantWindow.slug].responses[this.state.name] !== 'undefined') {
      recordedVal = this.props.responses[this.props.participantWindow.slug].responses[this.state.name].value;
    }
    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value) {
      recordedVal = '';
    }

    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value) {
      recordedVal = '';
    }

    if (recordedVal.length > 0 && recordedVal !== this.state.value) {
      this.setState({
        value: recordedVal,
      });
    }

    if (this.props.props !== null && typeof this.props.props.mask !== undefined) {
      this.setState({
        maskAs: this.props.props.mask[0].name,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showElement === false && prevProps.showElement !== this.props.showElement) {
      this.answerHasHidden();
    }

    if (this.state.showAnswer === false && prevState.showAnswer !== this.state.showAnswer) {
      this.answerHasHidden();
    }

    if (prevProps.responses[this.props.participantWindow.slug].responses !== this.props.responses[this.props.participantWindow.slug].responses) {
      this.evalShowIf();
    }

    if (this.props.showElement === true && this.state.showAnswer === true) {
      this.answerHasShown();
    }
  }

  answerHasHidden() {
    // unset value and record not_shown
    this.setState({ value: '' });

    const answer = this.props.slug;
    const element = this.props.parent;
    const name = this.state.name;
    const value = this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value;
    const participantWindow = this.props.participantWindow.slug;

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  answerHasShown() {
    const answer = this.props.slug;
    const element = this.props.parent;
    const name = this.state.name;
    const participantWindow = this.props.participantWindow.slug;
    let value = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;

    if (this.state.value.length > 0) {
      value = this.state.value;
    }

    if (typeof this.props.responses[participantWindow].responses[name] !== 'undefined' &&
      value === this.props.responses[participantWindow].responses[name].value) {
      return;
    }

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  calculatedValue() {
    if (typeof this.state.value === 'undefined') {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    if (this.state.value.length < 1) {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    return this.state.value;
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = this.props.show_if;
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showElement) {
      this.setState({
        showElement: show,
      });
    }
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleTimeChange = (event, piece) => {
    const arr = this.state.valuePcs;
    let value = this.state.value;

    for (let i = 0; i < 3; i++) {
      if (piece === i) {
        arr[i] = event.target.value;
      } else if (typeof arr[i] === 'undefined') {
        arr[i] = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
      }
    }

    if (arr.length === 3) {
      value = arr[0] + ':' + arr[1] + ' ' + arr[2];
    }

    this.setState({
      value: value,
      valuePcs: arr,
    });
  };

  handleSliderChange = event => {
    this.setState({ value: event[0] });
  };

  render() {
    const input =
      <Input
        className={ 'input response' }
        name={ this.state.name }
        type="text"
        value={ this.state.value }
        onChange={ this.handleChange }/>;

    const hidden =
      <Input
        className={ 'input response' }
        name={ this.state.name }
        type="hidden"
        value={ this.state.value }
        onChange={ this.handleChange }/>;

    const wrappedInput =
      <InputGroup>
        { input }
        <InputGroupAddon addonType="append">
          { this.props.label }
        </InputGroupAddon>
      </InputGroup>;

    const wrapperClasses = classnames({
      'd-none': (!this.state.showAnswer),
    });

    if (this.state.maskAs === 'time--standard') {
      let hour = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
      let minute = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
      let ampm = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
      const timePcs = this.state.value.split(/[ :]+/);

      if (timePcs.length === 3) {
        hour = timePcs[0];
        minute = timePcs[1];
        ampm = timePcs[2];
      }

      return (
        <div className={ wrapperClasses }>
          { hidden }
          <Row>
            <Col>
              <Input
                type="select"
                value={ hour }
                onChange={ (e) => {
                  this.handleTimeChange(e, 0);
                } }>
                <option value={ this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value }>--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Input>
            </Col>
            <Col>
              <Input
                type="select"
                value={ minute }
                onChange={ (e) => {
                  this.handleTimeChange(e, 1);
                } }>
                <option value={ this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value }>--</option>
                <option value="00">00</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </Input>
            </Col>
            <Col>
              <Input
                type="select"
                value={ ampm }
                onChange={ (e) => {
                  this.handleTimeChange(e, 2);
                } }>
                <option value={ this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value }>--</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </Input>
            </Col>
          </Row>
        </div>
      );
    }

    if (this.state.maskAs === 'slider--single') {
      const mask = this.props.props.mask[0];
      let initial = parseInt(this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value);
      let min = parseInt(this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value);
      let decimals = 0;
      let suffix = '';
      let max = 100;
      let start = 0;
      let step = 1;

      // override from object
      if (typeof this.props.non_value === 'number') {
        initial = this.props.non_value;
      }
      if (typeof this.props.non_value === 'number') {
        min = this.props.non_value;
      }
      if (typeof mask.mask.decimals !== 'undefined') {
        decimals = mask.mask.decimals;
      }
      if (typeof mask.mask.start !== 'undefined') {
        start = mask.mask.start;
      }
      if (typeof mask.mask.max !== 'undefined') {
        max = mask.mask.max;
      }
      if (typeof mask.mask.suffix !== 'undefined') {
        suffix = mask.mask.suffix;
      }
      if (typeof mask.mask.step !== 'undefined') {
        step = mask.mask.step;
      }

      let startLabel = start + suffix;
      let maxLabel = max + suffix;

      if (typeof mask.mask.startLabel !== 'undefined') {
        startLabel = mask.mask.startLabel;
      }
      if (typeof mask.mask.maxLabel !== 'undefined') {
        maxLabel = mask.mask.maxLabel;
      }

      // override from state
      if (!isNaN(parseFloat(this.state.value))) {
        initial = parseFloat(this.state.value);
      }

      return (
        <div className={ 'mt-5 mr-5' }>
          <Nouislider
            behaviour={ 'drag' }
            connect={ [true, false] }
            start={ [initial] }
            range={ {
              'min': [min, Math.abs(min) + start],
              '5%': [start, step],
              'max': max
            } }
            tooltips={ [
              wNumb({
                decimals: decimals,
                suffix: suffix,
                edit: function (a) {
                  if (a === min + suffix) {
                    return 'Select';
                  } else if (a === start + suffix) {
                    return startLabel
                  } else if (a === max + suffix) {
                    return maxLabel
                  } else {
                    return a;
                  }
                }
              })
            ] }
            onChange={ (e) => {
              this.handleSliderChange(e);
            } }
          />
        </div>
      );
    }

    if (this.state.hasLabel) {
      return (
        <div className={ wrapperClasses }>
          { wrappedInput }
        </div>
      );
    }

    return (
      <div className={ wrapperClasses }>
        { input }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextInput);