import moment from 'moment-timezone';
import axios from 'axios';

export const FETCHING_PARTICIPANT = 'FETCHING_PARTICIPANT';
export const SET_DATA = 'SET_DATA';

export const setParticipantData = (data) => {
  const newData = {
    ...data,
    lastFetch: moment().unix(),
  };
  return { type: SET_DATA, data: newData };
};

export const unsetData = () => {
  return { type: SET_DATA, data: { lastFetch: 0 } };
};

export const fetchParticipantData = (slug, accessToken) => {
  const url = `${ process.env.REACT_APP_API_URL }/participants/${ slug }`;
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ accessToken }`,
    },
  };

  return (dispatch, getState) => {
    dispatch({
      type: FETCHING_PARTICIPANT,
      value: true,
    });

    axios.get(url, config)
      .then((res) => {
        dispatch(
          setParticipantData(res.data.data),
        );
      })
      .catch((error) => {
        // error handling
        const log = {
          error: JSON.stringify({
            app: 'uwamps_pwa',
            altpid: getState().participant.data.altpid || 'not set',
            endpoint: error.config.url,
            message: error.message,
            origin: 'participantActions.fetchParticipantData',
            slug: slug,
          })
        };

        axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: FETCHING_PARTICIPANT,
          value: false,
        });
      });
  };
};
