import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InterventionMessage from './blocks/InterventionMessage';
import * as interventionMessagesActions from '../actions/interventionMessagesActions';
import moment from 'moment-timezone';
import ReactSwipe from 'react-swipe';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: -1,
      showMessages: false,
      loadingMessage: 'truing up with server...',
      lastSurvey: null,
      burstDay: 0,
      burstSurvey: 0,
      calledFetchServerMessages: false,
      calledFetchCustomMessage: false,
      calledSelectDefaultMessages: false,
      calledTrueUpMessages: false,
    };

    this.topRef = React.createRef();
    this.reactSwipe = React.createRef();
    this.incrementActiveIndex = this.incrementActiveIndex.bind(this);
  }

  componentDidMount() {
    const lastSurvey = [
      'burst_1',
      'survey_0',
      null,
      {
        burst: 1,
        burst_day: 0,
        burst_survey: 0,
        period: 'am',
      }
    ];
    const now = moment();

    if (typeof this.props.surveys.schedule.schedule.burst_1.survey_1 === 'undefined') {
      return;
    }

    if (this.props.surveys.pastSurveys.length === 1) {
      if (now.hour() >= 19 && now.minute() >= 30) {
        lastSurvey[3].period = 'pm';
      }

      if (moment(this.props.surveys.schedule.schedule.burst_1.survey_1.opened_at).diff(now, 'hours') < 12) {
        lastSurvey[3].period = 'pm';
      }
    }

    if (this.props.surveys.pastSurveys.length > 1) {
      // set these individually to prevent using the ref
      lastSurvey[0] = this.props.surveys.pastSurveys[this.props.surveys.pastSurveys.length - 1][0];
      lastSurvey[1] = this.props.surveys.pastSurveys[this.props.surveys.pastSurveys.length - 1][1];
      lastSurvey[2] = this.props.surveys.pastSurveys[this.props.surveys.pastSurveys.length - 1][2];
      lastSurvey[3] = {
        burst: this.props.surveys.pastSurveys[this.props.surveys.pastSurveys.length - 1][3].burst,
        burst_day: this.props.surveys.pastSurveys[this.props.surveys.pastSurveys.length - 1][3].burst_day,
        burst_survey: this.props.surveys.pastSurveys[this.props.surveys.pastSurveys.length - 1][3].burst_survey,
        period: this.props.surveys.pastSurveys[this.props.surveys.pastSurveys.length - 1][3].period,
      };
    }

    if (this.props.surveys.pastSurveys.length === 43) {
      const lastClosed = moment(this.props.surveys.schedule.map[lastSurvey[2]].closed_at);
      const hoursAgo = now.diff(lastClosed, 'hours');

      if (hoursAgo >= 12) {
        lastSurvey[1] = 'survey_99';
        lastSurvey[2] = null;
        lastSurvey[3] = {
          burst: 1,
          burst_day: 22,
          burst_survey: 99,
          period: 'am'
        }
      }

      if (hoursAgo >= 24) {
        lastSurvey[3].period = 'pm';
      }
    }

    this.setState({
      lastSurvey: lastSurvey,
      burstDay: lastSurvey[3].burst_day,
      burstSurvey: lastSurvey[3].burst_survey,
    });

    const trueUp = this.props.interventionMessages.messages.filter((item) => {
      return typeof item !== 'string';
    });

    // we will true up if we have any messages, otherwise we will just fetch from the server.
    // either way the operation will be tracked for completion as a "true up"
    if (trueUp.length) {
      this.props.dispatch(this.props.interventionMessagesActions.trueUpMessages());
    } else {
      this.props.dispatch(this.props.interventionMessagesActions.fetchServerMessages());
    }

    this.setState({
      calledTrueUpMessages: true,
    });
  }

  componentWillUnmount() {
    this.props.dispatch(this.props.interventionMessagesActions.trueUpMessages());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.interventionMessages.isTruingUp === true
      && this.props.interventionMessages.isTruingUp === false) {
      console.log('done truing up');

      // we have finished truing up with the server, begin step 2
      this.setState({
        loadingMessage: 'building new message...',
        calledFetchCustomMessage: true,
      });
      this.props.dispatch(this.props.interventionMessagesActions.fetchCustomMessage(
        `day_${ this.state.burstDay }_${ this.state.lastSurvey[3].period }`
      ));
    }

    if (prevProps.interventionMessages.isFetchingCustom === true
      && this.props.interventionMessages.isFetchingCustom === false) {
      console.log('done fetching custom');

      // we have finished retrieving the custom message from the server, begin step 3
      this.setState({
        loadingMessage: 'getting messages from list...',
        calledSelectDefaultMessages: true,
      });

      this.props.dispatch(this.props.interventionMessagesActions.selectDefaultMessages(
        `day_${ this.state.burstDay }_${ this.state.lastSurvey[3].period }`
      ));
    }

    if ((prevProps.interventionMessages.isSelectingDefault === true
      || this.state.calledSelectDefaultMessages === true)
      && this.props.interventionMessages.isSelectingDefault === false
      && this.state.showMessages === false) {
      console.log('done selecting default');

      // we have finished selecting any default messages from the list, we can show the messages now
      this.props.interventionMessagesActions.setLastBuild();
      this.showNewOrLastViewed();
      this.setState({
        showMessages: true,
      });
    }
  }

  showNewOrLastViewed() {
    const messages = this.props.interventionMessages.messages
      .filter((item) => {
        return typeof item !== 'string';
      });

    // if last message has not been viewed, show that one
    if (messages[messages.length -1].viewed_at === null) {
      console.log('last message has not been viewed, show that one');
      this.incrementActiveIndex(-1);
      return;
    }

    const lastViewed = {
      index: -1,
      viewed_last: null,
    };
    for(let i = 0; i < this.props.interventionMessages.messages.length - 1; i++) {
      const item = this.props.interventionMessages.messages[i];

      if (typeof item === 'string') {
        console.log('message ' + i + ' is still a string');
        continue;
      }

      if (typeof item.viewed_last === 'undefined' || item.viewed_last === null) {
        console.log('message ' + i + ' item.viewed_last is not a time');
        continue;
      }

      if (lastViewed.viewed_last === null || item.viewed_last >= lastViewed.viewed_last) {
        console.log('setting message ' + i + ' as last viewed');
        lastViewed.index = i;
        lastViewed.viewed_last = item.viewed_last;
      }
    }

    if (lastViewed.index > -1) {
      console.log('last viewed message will be shown');
      this.setActiveIndex(lastViewed.index);
      return;
    }

    console.log('default action');
    this.incrementActiveIndex(-1);
  }

  incrementActiveIndex(increment) {
    this.setActiveIndex(this.state.activeIndex + increment);
  }

  setActiveIndex(index) {
    const min = 0;
    const max = this.props.interventionMessages.messages
      .filter(item => typeof item !== 'string')
      .length - 1;

    let newIndex = index;

    if (this.props.interventionMessages.messages.constructor !== Array) {
      return;
    }

    if (newIndex > max) {
      newIndex = min;
    }

    if (newIndex < min) {
      newIndex = max;
    }

    this.setState({
      activeIndex: newIndex,
    });


  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to="/tool-kit"/>;
    }

    if (typeof this.props.surveys.schedule.schedule.burst_1.survey_1 === 'undefined') {
      return <Redirect to="/settings"/>;
    }

    if (this.props.participant.data.rand_code.indexOf('INTERVENTION') < 0) {
      return <Redirect to="/tool-kit"/>;
    }

    const swipeOptions = {
      continuous: true,
      startSlide: this.state.activeIndex,
      transitionEnd: (index) => {
        this.setActiveIndex(index);
        this.topRef.current.scrollIntoView(true);
      }
    };

    const loading = (
      <div className="text-center">
        <div className="my-5 text-primary">
          <FontAwesomeIcon
            icon={ ['fal', 'atom'] }
            spin
            transform="grow-20"
          />
        </div>
        <p>{ this.state.loadingMessage }</p>
      </div>
    );

    let interventionMessages = [];

    if (this.state.showMessages === true
      && this.props.interventionMessages.messages.constructor === Array) {
      interventionMessages = this.props.interventionMessages.messages
        .filter((item) => typeof item !== 'string')
        .map((item, index) => (
          <div key={ index }>
            <InterventionMessage
              message={ item.message }
              active={ index === this.state.activeIndex }
              helpful={ typeof item.props.helpful === 'undefined' ? null : item.props.helpful }
              rating={ typeof item.props.rating === 'undefined' ? null : item.props.rating }
              templateId={ item.template_id }
              msgIndex={ index }
              incrementActiveIndex={ this.incrementActiveIndex }
            />
          </div>
        ));
    }

    if (interventionMessages.length > 0) {
      return (
        <div
          style={{
            marginTop: '-1rem',
            paddingTop: '1rem',
          }}
          ref={ this.topRef }>
          <p className={ 'text-center' }>
            { this.state.activeIndex + 1 } of { interventionMessages.length }
            <br/>(swipe to see more)
          </p>
          <hr/>
          <ReactSwipe
            ref={ this.reactSwipe }
            childCount={ interventionMessages.length }
            swipeOptions={ swipeOptions }
            setActiveIndex={ this.setActiveIndex }
          >
            { interventionMessages }
          </ReactSwipe>
        </div>
      );
    }

    return (
      <div>{ loading }</div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    interventionMessagesActions: bindActionCreators(interventionMessagesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Messages);
