import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment-timezone';
import * as surveysActions from '../actions/surveysActions';
import * as responsesActions from '../actions/responsesActions';
import queryString from 'query-string';

class Survey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toTaker: false,
    };
  }

  componentDidMount() {
    this.props.surveysActions.setOpenSurveys(this.props.surveys.schedule.schedule, this.props.responses);
    this.props.surveysActions.setFutureSurveys(this.props.surveys.schedule.schedule);
    this.props.surveysActions.setPastSurveys(this.props.surveys.schedule.schedule, this.props.responses);

    setTimeout(() => {
      this.beginOnboardingSurvey();
    }, 250)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  beginOnboardingSurvey() {
    try{
      if (this.props.surveys.openSurveys[0][1] === 'survey_0') {
        this.handleStartSurvey();
      }
    } catch (e) {}
  }

  handleStartSurvey() {
    this.props.responsesActions.beginCollection(this.props.surveys.openSurveys[0][2]);

    this.setState({
      toTaker: true,
    });
  }

  render() {
    const params = queryString.parse(this.props.location.search);
    let upcoming = <p>You have no scheduled surveys.</p>;
    let thankYou = null;
    let takerButton = null;

    if (this.props.surveys.futureSurveys.length > 0) {
      const openedAtStr = this.props.surveys.schedule.schedule[this.props.surveys.futureSurveys[0][0]][this.props.surveys.futureSurveys[0][1]].opened_at;
      const openedAt = moment(openedAtStr);
      upcoming = <p>
        Your next survey will begin { openedAt.fromNow() }.
      </p>;
    }

    if (params.msg === 'thank-you') {
      thankYou = <div>
        <p className={ 'my-3' }>
          Thank you for completing your survey.
        </p>
      </div>;
    }

    if (params.msg === 'thank-you-final') {
      upcoming = null;
      thankYou = <div>
        <p className={ 'my-3' }>
          Thank you for your participation in Project AMPS.
        </p>
        <p className={ 'my-3' }>
          This phase of the study is now complete. Please watch your email in about a month for the next phase of the
          project.
        </p>
      </div>
    }

    if (typeof params.msg === 'undefined' && this.props.surveys.pastSurveys.length >= 43) {
      upcoming = null;
      thankYou = <div>
        <p className={ 'my-3' }>
          Thank you for your participation in Project AMPS.
        </p>
        <p className={ 'my-3' }>
          This phase of the study is now complete. Please watch your email for the next phase of the project.
        </p>
      </div>
    }

    if (this.props.surveys.openSurveys.length > 0) {
      upcoming = null;

      takerButton = (
        <Button
          outline
          color="success"
          onClick={ () => {
            this.handleStartSurvey();
          } }
        >
          Begin
        </Button>
      );
    }

    if (this.state.toTaker === true && this.props.surveys.openSurveys.length > 0) {
      const takerLinkStr = `/taker/${ this.props.surveys.openSurveys[0][2] }`;
      return <Redirect to={ takerLinkStr }/>;
    }

    return (
      <div>
        <h2>Survey</h2>
        { thankYou }

        { upcoming }

        <div className={ 'my-5' }>
          { takerButton }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
    surveysActions: bindActionCreators(surveysActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Survey);
