import React, { Component } from 'react';
import SettingSignIn from './blocks/SettingSignIn';
import SettingStorage from './blocks/SettingStorage';

export default class Settings extends Component {
  render() {
    return (
      <div>
        <h2>Settings</h2>
        <hr/>
        <SettingSignIn/>
        <hr/>
        <SettingStorage/>
        <hr/>
        <div className={ 'd-flex justify-content-between' }>
          <p>Build number:</p>
          <p>{ process.env.REACT_APP_BUILDNUM }</p>
        </div>
      </div>
    );
  }
}