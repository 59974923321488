import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as responsesActions from '../../../actions/responsesActions';
import React, { Component } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';

class NumberInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasLabel: false,
      name: this.props.parent + '|' + this.props.slug,
      showAnswer: true,
      value: '',
      restrictMin: false,
      restrictMax: false,
      restrictStep: false,
    };

    if (props.label !== null && props.label.length > 0) {
      this.state.hasLabel = true;
    }

    if (!isNaN(props.props.min)) {
      this.state.restrictMin = true;
    }

    if (!isNaN(props.props.max)) {
      this.state.restrictMax = true;
    }

    if (!isNaN(props.props.step)) {
      this.state.restrictStep = true;
    }
  }

  componentDidMount() {
    let recordedVal = '';

    if (typeof this.props.responses[this.props.participantWindow.slug].responses[this.state.name] !== 'undefined') {
      recordedVal = this.props.responses[this.props.participantWindow.slug].responses[this.state.name].value;
    }
    if (recordedVal === this.props.nonValue) {
      recordedVal = '';
    }

    if (recordedVal === this.props.notShown) {
      recordedVal = '';
    }

    if (recordedVal.length > 0 && recordedVal !== this.state.value) {
      this.setState({ value: recordedVal });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showElement === false && prevProps.showElement !== this.props.showElement) {
      this.answerHasHidden();
    }

    if (this.state.showAnswer === false && prevState.showAnswer !== this.state.showAnswer) {
      this.answerHasHidden();
    }

    if (prevProps.responses[this.props.participantWindow.slug].responses !== this.props.responses[this.props.participantWindow.slug].responses) {
      this.evalShowIf();
    }

    if (this.props.showElement === true && this.state.showAnswer === true) {
      this.answerHasShown();
    }
  }

  answerHasHidden() {
    // unset value and record not_shown
    this.setState({ value: '' });

    const answer = this.props.slug;
    const element = this.props.parent;
    const name = this.state.name;
    const participantWindow = this.props.participantWindow.slug;

    this.props.responsesActions.recordResponse(
      answer, element, name, this.props.notShown, participantWindow
    );
  }

  answerHasShown() {
    const answer = this.props.slug;
    const element = this.props.parent;
    const name = this.state.name;
    const participantWindow = this.props.participantWindow.slug;
    let value = this.props.nonValue;

    if (this.state.value.length > 0) {
      value = this.state.value;
    }

    if (typeof this.props.responses[participantWindow].responses[name] !== 'undefined' &&
      value === this.props.responses[participantWindow].responses[name].value) {
      return;
    }

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  calculatedValue() {
    if (typeof this.state.value === 'undefined') {
      return this.props.nonValue;
    }

    if (this.state.value.length < 1) {
      return this.props.nonValue;
    }

    return this.state.value;
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.participantWindow,
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.showIf));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showElement) {
      this.setState({
        showElement: show,
      });
    }
  }

  handleChange = event => {
    const min = Number.parseFloat(this.props.props.min);
    const max = Number.parseFloat(this.props.props.max);
    const step = Number.parseFloat(this.props.props.step);
    let newValue = Number.parseFloat(event.target.value);

    if (isNaN(newValue)) {
      newValue = '';
    }

    if (this.state.restrictMin) {
      if (newValue < min) {
        newValue = this.props.props.min;
      }
    }

    if (this.state.restrictMax) {
      if (newValue > max) {
        newValue = max;
      }
    }

    if (this.state.restrictStep) {
      if (newValue % step !== 0) {
        newValue = Math.ceil((newValue) / step ) * step;
      }
    }

    this.setState({ value: newValue.toString() });
  };

  render() {
    const input =
      <Input
        className={ 'input response' }
        name={ this.state.name }
        type="number"
        min={ isNaN(this.props.props.min) ? '' : this.props.props.min }
        max={ isNaN(this.props.props.max) ? '' : this.props.props.max }
        step={ isNaN(this.props.props.step) ? 'any' : this.props.props.step }
        value={ this.state.value }
        inputmode="numeric"
        pattern="[0-9]*"
        onChange={ this.handleChange }/>;

    const wrappedInput =
      <InputGroup>
        { input }
        <InputGroupAddon addonType="append">
          { this.props.label }
        </InputGroupAddon>
      </InputGroup>;

    const wrapperClasses = classnames({
      'd-none': (!this.state.showAnswer),
    });

    if (this.state.hasLabel) {
      return (
        <div className={ wrapperClasses }>
          { wrappedInput }
        </div>
      );
    }

    return (
      <div className={ wrapperClasses }>
        { input }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberInput);