import axios from 'axios';
import moment from 'moment-timezone';
// import initialState from '../reducers/initialState';

export const FETCHING_MESSAGES = 'FETCHING_MESSAGES';
export const FETCHING_CUSTOM_MESSAGE = 'FETCHING_CUSTOM_MESSAGE';
export const TRUING_UP = 'TRUING_UP';
export const SELECTING_DEFAULT_MESSAGES = 'SELECTING_DEFAULT_MESSAGES';
export const UPDATING_MESSAGES = 'UPDATING_MESSAGES';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_DEFAULT_MESSAGES = 'SET_DEFAULT_MESSAGES';
export const SET_LAST_BUILD = 'SET_LAST_BUILD';

export const setHelpful = (key, helpful, message, accessToken) => {
  const url = `${ process.env.REACT_APP_API_URL }/intervention-messages/${ message.slug }`;
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ accessToken }`,
    },
    timeout: 1000 * 5, // Wait for 5 seconds
  };
  const props = {
    ...message.props,
    helpful: message.props.helpful || helpful,
  };
  const msg = {
    ...message,
  };
  msg.props = props;

  const data = {
    ...msg,
    action: 'message-helpful',
  };

  return (dispatch) => {
    dispatch({
      type: UPDATING_MESSAGES,
      value: true,
    });

    dispatch({
      type: SET_MESSAGE,
      key,
      message: msg,
    });

    axios.patch(url, data, config)
      .then((res) => {
        dispatch({
          type: SET_MESSAGE,
          key,
          message: res.data.data,
        });
      })
      .catch(() => {
        // error handling
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: UPDATING_MESSAGES,
          value: false,
        });
      });
  };
};

export const setRating = (key, rating, message, accessToken) => {
  const url = `${ process.env.REACT_APP_API_URL }/intervention-messages/${ message.slug }`;
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ accessToken }`,
    },
    timeout: 1000 * 5, // Wait for 5 seconds
  };
  const props = {
    ...message.props,
    rating: message.props.rating || rating,
  };
  const msg = {
    ...message,
  };
  msg.props = props;

  const data = {
    ...msg,
    action: 'message-rating',
  };

  return (dispatch) => {
    dispatch({
      type: UPDATING_MESSAGES,
      value: true,
    });

    dispatch({
      type: SET_MESSAGE,
      key,
      message: msg,
    });

    axios.patch(url, data, config)
      .then((res) => {
        dispatch({
          type: SET_MESSAGE,
          key,
          message: res.data.data,
        });
      })
      .catch(() => {
        // error handling
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: UPDATING_MESSAGES,
          value: false,
        });
      });
  };
};

export const setViewedAt = (key, message, accessToken) => {
  const url = `${ process.env.REACT_APP_API_URL }/intervention-messages/${ message.slug }`;
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ accessToken }`,
    },
    timeout: 1000 * 5, // Wait for 5 seconds
  };
  const msg = {
    ...message,
    viewed_at: message.viewed_at || moment(),
  };
  const data = {
    ...msg,
    action: 'message-viewed',
  };

  return (dispatch) => {
    dispatch({
      type: UPDATING_MESSAGES,
      value: true,
    });

    dispatch({
      type: SET_MESSAGE,
      key,
      message: msg,
    });

    axios.patch(url, data, config)
      .then((res) => {
        dispatch({
          type: SET_MESSAGE,
          key,
          message: res.data.data,
        });
      })
      .catch(() => {
        // error handling
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: UPDATING_MESSAGES,
          value: false,
        });
      });
  };
};

export const setViewedLast = (key) => {
  return (dispatch, getState) => {
    const msg = getState().interventionMessages.messages[key];

    msg.viewed_last = moment();

    dispatch({
      type: SET_MESSAGE,
      key,
      message: msg,
    });
  };
};

export const setFetching = (bool) => {
  return {
    type: FETCHING_MESSAGES,
    value: bool,
  };
};

export const setTruing = (bool) => {
  return {
    type: TRUING_UP,
    value: bool,
  };
};

export const setFetchingCustom = (bool) => {
  return {
    type: FETCHING_CUSTOM_MESSAGE,
    value: bool,
  };
};

export const setSelectingDefault = (bool) => {
  return {
    type: SELECTING_DEFAULT_MESSAGES,
    value: bool,
  };
};

export const setLastBuild = () => {
  return {
    type: SET_LAST_BUILD,
    value: moment(),
  };
};

export const incrementViewed = (key, elapsedSeconds) => {
  return (dispatch, getState) => {
    const msg = getState().interventionMessages.messages[key];

    if (typeof msg.props.viewedSeconds === 'undefined') {
      msg.props.viewedSeconds = 0;
    }

    if (typeof msg.props.viewedCount === 'undefined') {
      msg.props.viewedCount = 0;
    }

    if (elapsedSeconds >= 1) {
      msg.props.viewedSeconds += elapsedSeconds;
    }

    if (elapsedSeconds >= 2) {
      msg.props.viewedCount++;
    }

    dispatch({
      type: SET_MESSAGE,
      key,
      message: msg,
    });
  };
};


export const updateCustomMessage = (messageList, data) => {
  const messages = messageList.messages.slice(0);

  // check if slot string is still in existing messages, if so insert the downloaded message
  const indexOfSlot = messages.indexOf(data.slot);
  if (indexOfSlot > -1) {
    messages[indexOfSlot] = data;
  }

  return { type: SET_MESSAGES, messages };
};

export const updateDefaultMessages = (period, messageList, messages) => {
  for (let i = 0; i < messages.length; i++) {
    messageList[`day_${ messages[i].priority }`].default = messages[i];
  }

  return { type: SET_DEFAULT_MESSAGES, period, messages: messageList };
};

export const updateInterventionMessages = (existingMessages, data) => {
  const messages = existingMessages.slice(0);

  for (let i = 0; i < data.length; i++) {
    // check if slot string is still in existing messages, if so insert the downloaded message
    const indexOfSlot = messages.indexOf(data[i].slot);
    if (indexOfSlot > -1) {
      messages[indexOfSlot] = data[i];
      continue;
    }

    if (messages[i].slug === null) {
      messages[i].slug = data[i].slug;
      continue;
    }
  }

  return { type: SET_MESSAGES, messages };
};


export const fetchServerMessages = () => {
  return function (dispatch, getState) {
    dispatch(setTruing(true));

    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ getState().settings.auth.accessToken }`,
      },
      timeout: 1000 * 5, // Wait for 5 seconds
    };

    return (dispatch) => {
      axios.get(`${ process.env.REACT_APP_API_URL }/intervention-messages`, config)
        .then((res) => {
          dispatch(updateInterventionMessages(
            getState().interventionMessages.messages,
            res.data.data.messages,
          ));
          dispatch(updateDefaultMessages(
            'am',
            getState().interventionMessages.am,
            res.data.data.am_default,
          ));
          dispatch(updateDefaultMessages(
            'pm',
            getState().interventionMessages.pm,
            res.data.data.pm_default,
          ));
        })
        .catch((error) => {
          // error handling
          const log = {
            error: JSON.stringify({
              app: 'uwamps_pwa',
              altpid: getState().participant.data.altpid || 'not set',
              endpoint: error.config.url,
              message: error.message,
              origin: 'interventionMessagesAction.fetchServerMessages',
            })
          };

          axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
        })
        .then(() => {
          // this will always fire
          dispatch(setTruing(false));
        });
    };
  };
};

export const fetchCustomMessage = (slot) => {
  return function (dispatch, getState) {
    dispatch(setFetchingCustom(true));

    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ getState().settings.auth.accessToken }`,
      },
      timeout: 1000 * 5, // Wait for 5 seconds
    };

    return (dispatch) => {
      axios.get(`${ process.env.REACT_APP_API_URL }/custom-intervention-message/${ slot.toLowerCase() }`, config)
        .then((res) => {
          dispatch(updateCustomMessage(
            getState().interventionMessages,
            res.data.data,
          ));
        })
        .catch((error) => {
          // error handling
          const log = {
            error: JSON.stringify({
              app: 'uwamps_pwa',
              altpid: getState().participant.data.altpid || 'not set',
              endpoint: error.config.url,
              message: error.message,
              origin: 'interventionMessagesAction.fetchCustomMessage',
              slot: slot,
            })
          };

          axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
        })
        .then(() => {
          // this will always fire
          dispatch(setFetchingCustom(false));
        });
    };
  };
};

export const selectDefaultMessages = (lastSlot) => {
  return function (dispatch, getState) {
    dispatch(setSelectingDefault(true));

    const messages = getState().interventionMessages.messages.slice(0);

    for (let i = 0; i < messages.length; i++) {
      // if the messages array does not contain a slot string for this index, we are done
      if (typeof messages[i] === 'object') {
        if (messages[i].slot.toLowerCase() === lastSlot.toLowerCase()) {
          break;
        }
        continue;
      }

      // decode the slot string to determine which message to check for
      const slot = messages[i];
      const slotPcs = slot.split('_');
      const possibilities = getState().interventionMessages[slotPcs[2]][`${ slotPcs[0] }_${ slotPcs[1] }`];

      // splice the message from the list into the messages array, keeping the index
      const newMessage = {
        created_at: moment(),
        updated_at: moment(),
        deleted_at: null,
        sent_at: null,
        viewed_at: null,
        participant: getState().settings.auth.slug,
        slot: slot,
        template_id: possibilities.default.id,
        message: possibilities.default.template,
        props: {},
        slug: null,
        links: null,
      };
      messages.splice(i, 1, newMessage);

      if (slot.toLowerCase() === lastSlot.toLowerCase()) {
        break;
      }
    }

    return (dispatch) => {
      dispatch({ type: SET_MESSAGES, messages });
      dispatch(setSelectingDefault(false));
    };
  };
};

export const trueUpMessages = () => {
  return function (dispatch, getState) {
    dispatch(setTruing(true));

    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ getState().settings.auth.accessToken }`,
      },
      timeout: 1000 * 5, // Wait for 5 seconds
    };

    return (dispatch) => {
      axios.post(
        `${ process.env.REACT_APP_API_URL }/intervention-messages`,
        getState().interventionMessages.messages
          .filter((item) => typeof item !== 'string'),
        config
      )
        .then((res) => {
          dispatch(updateInterventionMessages(
            getState().interventionMessages.messages,
            res.data.data.messages,
          ));
          dispatch(updateDefaultMessages(
            'am',
            getState().interventionMessages.am,
            res.data.data.am_default,
          ));
          dispatch(updateDefaultMessages(
            'pm',
            getState().interventionMessages.pm,
            res.data.data.pm_default,
          ));
        })
        .catch((error) => {
          // error handling
          const log = {
            error: JSON.stringify({
              app: 'uwamps_pwa',
              altpid: getState().participant.data.altpid || 'not set',
              endpoint: error.config.url,
              message: error.message,
              origin: 'interventionMessagesAction.trueUpMessages',
            })
          };

          axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
        })
        .then(() => {
          // this will always fire
          dispatch(setTruing(false));
        });
    };
  };
};