import produce from 'immer';
import initialState from '../initialState';
import { SAVE_PHOTO } from '../actions/photosActions';

export default function update(state = initialState.photos, action) {
  let newState;
  switch (action.type) {
    case SAVE_PHOTO:
      newState = produce(state, (draft) => {
        draft.uploaded = state.uploaded.concat(action.images);
      });
      return newState;

    default:
      return state;
  }
}
