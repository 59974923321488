import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import SummaryChartAlcohol from './SummaryChartAlcohol';
import SummaryChartSleepMood from './SummaryChartSleepMood';
import moment from 'moment-timezone';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import classnames from 'classnames';
import * as summaryActions from '../../../actions/summaryActions';
import isEqual from 'react-fast-compare/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTwo from "./PageTwo";


const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WeeklySummary = styled.div`
    height: 100%;
`;

class WeeklySummaryBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chart: {
        drinks: [],
        maxDomain: 0,
        mood: [],
        sleep: [],
        xCategories: [],
      },
      dataLoaded: false,
      moodStatement: 0,
      moodAverage: 0,
      period: `week-${ this.props.buildWeek }`,
      selectedCategory: 0,
      sleepAverage: 0,
      viewStart: null,
      viewEnd: null,
      visible: this.props.currentWeek === this.props.buildWeek,
    };
  }

  componentDidMount() {
    const loaded = this.isDataLoaded();

    if (loaded) {
      this.moodStatement(0);
      this.moodAverage(0);
      this.sleepAverage(0);
    }

    this.props.dispatch(this.props.summaryActions.fetchSummary(this.state.period));
  }

  componentWillUnmount() {
    if (this.state.viewStart !== null) {
      this.viewEnd();
    }
  }

  isDataLoaded() {
    let loaded = (
      typeof this.props.summaries === "object" &&
      typeof this.props.summaries[this.state.period] === "object" &&
      Object.entries(this.props.summaries[this.state.period]).length > 0 &&
      this.state.dataLoaded === false);

    if (loaded) {
      this.setState({
        dataLoaded: true
      });

      this.viewStart();
      this.setBarChart();
    }

    return loaded;
  }

  componentDidUpdate() {
    this.isDataLoaded();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(this.props.summaries, nextProps.summaries)) {
      return true;
    }

    if (nextProps.viewPage !== this.props.viewPage) {
      return true;
    }

    if (!isEqual(nextState, this.state)) {
      return true;
    }

    return false;
  }

  viewStart() {
    this.setState({
      viewStart: moment(),
    });
  }

  viewEnd() {
    const viewEnd = moment();
    const elapsed = Math.ceil((viewEnd - this.state.viewStart) / 1000);

    this.props.summaryActions.incrementViewed('weekly-summary', this.state.period, elapsed);

    this.setState({
      viewStart: null
    });
  }

  setBarChart() {
    if (this.state.chart.xCategories.length > 0) {
      return;
    }

    let maxDomain = 0;

    const xCategories = Object.keys(this.props.summaries[this.state.period].day)
      .map((item) => {
        return moment(item).format('ddd');
      });

    const drinks = Object.values(this.props.summaries[this.state.period].day)
      .map((item) => {
        let val = parseInt(item.AM_ALC02);

        if (parseInt(item.AM_ALC01) === 0) {
          val = 0;
        }

        // this needs to set a very specific positive value, to allow for detection and labeling
        val = isNaN(val) ? 0.001 : val;

        if (val > maxDomain) {
          maxDomain = val;
        }

        return val;
      });

    const mood = Object.values(this.props.summaries[this.state.period].day)
      .map((item) => {
        let val = parseInt(item.AM_DAYMOOD);

        // this needs to set a very specific positive value, to allow for detection and labeling
        val = isNaN(val) ? 0.001 : val;

        if (val > maxDomain) {
          maxDomain = val;
        }

        return val;
      });

    const sleep = Object.values(this.props.summaries[this.state.period].day)
      .map((item) => {
        let val = parseInt(item.AM_SLEEP04);

        // this needs to set a very specific positive value, to allow for detection and labeling
        val = isNaN(val) ? 0.001 : val;

        if (val > maxDomain) {
          maxDomain = val;
        }

        return val;
      });

    this.setState({
      chart: {
        drinks,
        mood,
        sleep,
        xCategories,
        maxDomain,
      },
    })
  }

  moodAverage(selectedCategory) {
    const summary = this.props.summaries[this.state.period];
    let moodAverage = summary.avg.AM_DAYMOOD;

    if (selectedCategory === 0) {
      moodAverage = summary.avg['AM_DAYMOOD_NON-DRINK'];
    }

    if (selectedCategory === 1) {
      moodAverage = summary.avg['AM_DAYMOOD_NON-BINGE'];
    }

    if (selectedCategory === 2) {
      moodAverage = summary.avg['AM_DAYMOOD_BINGE'];
    }

    this.setState({ moodAverage });
  }

  moodStatement(selectedCategory) {
    const summary = this.props.summaries[this.state.period];
    let moodStatement = 0;

    if (selectedCategory === 1) {
      // compare NON-BINGE with NON-DRINK
      moodStatement = 2;
      if (summary.avg['AM_DAYMOOD_NON-BINGE'] > summary.avg['AM_DAYMOOD_NON-DRINK']) {
        moodStatement = 1;
      }
    }

    if (selectedCategory === 2) {
      // compare BINGE with NON-DRINK
      moodStatement = 2;
      if (summary.avg['AM_DAYMOOD_BINGE'] > summary.avg['AM_DAYMOOD_NON-DRINK']) {
        moodStatement = 1;
      }
    }

    this.setState({ moodStatement });
  }

  sleepAverage(selectedCategory) {
    const summary = this.props.summaries[this.state.period];
    let sleepAverage = summary.avg.AM_SLEEP04;

    if (selectedCategory === 0) {
      sleepAverage = summary.avg['AM_SLEEP04_NON-DRINK'];
    }

    if (selectedCategory === 1) {
      sleepAverage = summary.avg['AM_SLEEP04_NON-BINGE'];
    }

    if (selectedCategory === 2) {
      sleepAverage = summary.avg['AM_SLEEP04_BINGE'];
    }

    this.setState({ sleepAverage });
  }

  render() {
    // show message if we aren't there yet
    if (this.props.currentWeek < this.props.buildWeek) {
      return (
        <h4>Check back after week { this.props.buildWeek }.</h4>
      );
    }

    // we are there but we don't have the data yet
    if (this.state.dataLoaded === false) {
      return <h4>Loading data...</h4>
    }

    // we have the data, now begin the regular render
    const summary = this.props.summaries[this.state.period];

    return (
      <WeeklySummary>
        <PageWrapper
          className={ classnames({ 'd-none': this.props.viewPage !== 1 }) }>

          <div className={ 'border border-secondary rounded mt-3 mb-5 py-3 text-secondary' }>
            <Row>
              <Col className={ 'text-center d-flex flex-column justify-content-between' }>
                <div className={ 'mb-1' }>
                  <FontAwesomeIcon
                    icon={ ['fal', 'bed'] }
                    transform="grow-6"
                  />
                </div>
                <small
                  className={ 'd-block' }>
                  { summary.avg.AM_SLEEP04 > 0 ? Math.round(summary.avg.AM_SLEEP04 * 10) / 10 + '/5' : 'NR' }<br/>
                  Average Sleep<br/>
                  Quality
                </small>
              </Col>
              <Col className={ 'text-center d-flex flex-column justify-content-between' }>
                <div className={ 'mb-1' }>
                  <FontAwesomeIcon
                    icon={ ['fal', 'smile'] }
                    transform="grow-6"
                  />
                </div>
                <small className={ 'd-block' }>
                  { summary.avg.AM_DAYMOOD > 0 ? Math.round(summary.avg.AM_DAYMOOD * 10) / 10 + '/5' : 'NR' }<br/>
                  Average<br/>
                  Mood
                </small>
              </Col>
              <Col className={ 'text-center d-flex flex-column justify-content-between' }>
                <div className={ 'mb-1' }>
                  <FontAwesomeIcon
                    icon={ ['fal', 'beer'] }
                    transform="grow-6 right-2"
                  />
                </div>
                <small className={ 'd-block' }>
                  { summary.sum.AM_ALC02 }<br/>
                  Drinks<br/>
                  consumed
                </small>
              </Col>
            </Row>
            <div className={'text-center px-3'}>
              <hr />
              <small>
                NR = No report
              </small>
            </div>
          </div>

          <SummaryChartSleepMood
            dataSet={ [
              { x: this.state.chart.xCategories[0], y: this.state.chart.sleep[0], },
              { x: this.state.chart.xCategories[1], y: this.state.chart.sleep[1], },
              { x: this.state.chart.xCategories[2], y: this.state.chart.sleep[2], },
              { x: this.state.chart.xCategories[3], y: this.state.chart.sleep[3], },
              { x: this.state.chart.xCategories[4], y: this.state.chart.sleep[4], },
              { x: this.state.chart.xCategories[5], y: this.state.chart.sleep[5], },
              { x: this.state.chart.xCategories[6], y: this.state.chart.sleep[6], },
            ] }
            dependantTicks={ this.state.chart.xCategories }
            labelText={ 'Sleep Quality' }
          />

          <SummaryChartSleepMood
            dataSet={ [
              { x: this.state.chart.xCategories[0], y: this.state.chart.mood[0], },
              { x: this.state.chart.xCategories[1], y: this.state.chart.mood[1], },
              { x: this.state.chart.xCategories[2], y: this.state.chart.mood[2], },
              { x: this.state.chart.xCategories[3], y: this.state.chart.mood[3], },
              { x: this.state.chart.xCategories[4], y: this.state.chart.mood[4], },
              { x: this.state.chart.xCategories[5], y: this.state.chart.mood[5], },
              { x: this.state.chart.xCategories[6], y: this.state.chart.mood[6], },
            ] }
            dependantTicks={ this.state.chart.xCategories }
            labelText={ 'Mood' }

          />

          <SummaryChartAlcohol
            dataSet={ [
              { x: this.state.chart.xCategories[0], y: this.state.chart.drinks[0], },
              { x: this.state.chart.xCategories[1], y: this.state.chart.drinks[1], },
              { x: this.state.chart.xCategories[2], y: this.state.chart.drinks[2], },
              { x: this.state.chart.xCategories[3], y: this.state.chart.drinks[3], },
              { x: this.state.chart.xCategories[4], y: this.state.chart.drinks[4], },
              { x: this.state.chart.xCategories[5], y: this.state.chart.drinks[5], },
              { x: this.state.chart.xCategories[6], y: this.state.chart.drinks[6], },
            ] }
            dependantTicks={ this.state.chart.xCategories }
          />

          <p>
            What patterns do you see in your week?
          </p>

          <p>
            All things taken together, how would you rate your week? What expectations or hopes do you have for the
            upcoming week?
          </p>
        </PageWrapper>

        <PageWrapper
          className={ classnames({ 'd-none': this.props.viewPage !== 2 }) }>

          <PageTwo
            buildWeek={this.props.buildWeek}
            summary={summary} />
        </PageWrapper>
      </WeeklySummary>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    summaryActions: bindActionCreators(summaryActions, dispatch),
  };
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklySummaryBlock);