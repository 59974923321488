import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as calculatorsActions from '../actions/calculatorsActions';
import React, { Component } from 'react';
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import produce from 'immer';

class Calculators extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      bac: '',
      sex: '',
      weight: '',
      drinks: '',
      hours: '',
      timeToZero: '',
      abv: '',
      drinkSizeEquiv: '',
      abvContainerSize: '',
      drinksPerContainer: ''
    };

    this.state.sex = this.props.calculators.bac.sex;
    this.state.weight = this.props.calculators.bac.weight;
    this.state.drinks = this.props.calculators.bac.drinks;
    this.state.hours = this.props.calculators.bac.hours;
    this.state.bac = this.props.calculators.bac.bac;
    this.state.timeToZero = this.props.calculators.bac.timeToZero;

    this.state.abv = this.props.calculators.abv.abv;
    this.state.drinkSizeEquiv = this.props.calculators.abv.drinkSizeEquiv;
    this.state.abvContainerSize = this.props.calculators.abv.abvContainerSize;
    this.state.drinksPerContainer = this.props.calculators.abv.drinksPerContainer;
  }

  myNumberPropertyAsString(key) {
    return this[key] + '';
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }

  componentDidMount() {
    this.setBac();
    this.setAbv();
  }

  handleBacChange = event => {
    const target = event.target;
    const name = target.name;

    if (target.type === 'checkbox' || target.type === 'radio') {
      if (!target.checked) {
        return;
      }
    }
    const value = Number(target.value);

    this.props.calculatorsActions.setBac(name, value);

    this.setState(
      produce(this.state, draft => {
        draft[name] = value;
      }), () => {
        this.setBac();
      }
    );
  };

  handleAbvChange = event => {
    const target = event.target;
    const name = target.name;

    if (target.type === 'checkbox' || target.type === 'radio') {
      if (!target.checked) {
        return;
      }
    }

    let value = Number(target.value);

    if (name === 'abv' && value > 100) {
      value = 100;
    }

    value = Math.round(value * 10) / 10;

    this.props.calculatorsActions.setAbv(name, value);

    this.setState(
      produce(this.state, draft => {
        draft[name] = value;
      }), () => {
        this.setAbv();
      }
    );
  };

  roundHalf(num) {
    return Math.round(num * 2) / 2;
  }

  setAbv() {
    let drinkSizeEquiv = 0;
    let drinksPerContainer = 0;

    if (this.state.abv > 0) {
      drinkSizeEquiv = this.roundHalf(14 / ((this.state.abv / 100) * 23.34));
    }

    if (this.state.abvContainerSize > 0 && drinkSizeEquiv > 0) {
      drinksPerContainer = ((Math.round((this.state.abvContainerSize / drinkSizeEquiv) * 10)) / 10);
    }

    this.props.calculatorsActions.setAbv('drinkSizeEquiv', drinkSizeEquiv);
    this.props.calculatorsActions.setAbv('drinksPerContainer', drinksPerContainer);

    this.setState(
      produce(this.state, draft => {
        draft.drinkSizeEquiv = drinkSizeEquiv;
        draft.drinksPerContainer = drinksPerContainer;
      })
    );
  }

  setBac() {
    const sex = this.state.sex;
    const weight = this.state.weight;
    const drinks = this.state.drinks;
    const hours = this.state.hours;
    const gf = this.props.calculators.bac.gf[sex];
    let bac = 0;
    let timeToZero;

    if (drinks > 0 && weight > 0) {
      bac = ((drinks / 2) * (gf / weight)) - (0.016 * hours);
    }

    if (bac < 0) {
      bac = 0;
    }

    timeToZero = (bac / .016).toFixed(1);
    bac = bac.toFixed(2);

    if (sex === null || weight === null) {
      bac = '??';
      timeToZero = '??';
    }

    this.props.calculatorsActions.setBac('timeToZero', timeToZero);
    this.props.calculatorsActions.setBac('bac', bac);

    this.setState(
      produce(this.state, draft => {
        draft.bac = bac;
        draft.timeToZero = timeToZero;
      })
    );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div>
        <h2>Calculators</h2>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={ classnames({ active: this.state.activeTab === '1' }) }
              onClick={ () => {
                this.toggle('1');
              } }>BAC Tab</NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={ classnames({ active: this.state.activeTab === '2' }) }
              onClick={ () => {
                this.toggle('2');
              } }>Calculators</NavLink>
          </NavItem>

        </Nav>

        <TabContent activeTab={ this.state.activeTab }>
          <TabPane tabId="1">
            <div className={ 'mt-3' }>
              <p>
                Below you can estimate BAC based on sex, weight, number of drinks, and amount of time spent drinking.
              </p>
              <Row form>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="sex"
                        value="0"
                        checked={ this.state.sex === 0 }
                        onChange={ this.handleBacChange }
                      />
                      Male
                    </Label>
                  </FormGroup>
                  <FormGroup
                    check
                    disabled>
                    <Label check>
                      <Input
                        type="radio"
                        name="sex"
                        value="1"
                        checked={ this.state.sex === 1 }
                        onChange={ this.handleBacChange }
                      /> Female</Label>
                  </FormGroup>
                </Col>

                <Col>
                  <InputGroup>
                    <Input
                      type="number"
                      name="weight"
                      step="5"
                      min="0"
                      value={ this.state.weight + '' }
                      onChange={ this.handleBacChange }
                    />
                    <InputGroupAddon
                      addonType="append">Weight</InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>

              <Row
                form
                className={ 'mt-3' }>
                <Col>
                  <InputGroup>
                    <Input
                      type="number"
                      name="drinks"
                      step="1"
                      min="0"
                      value={ this.state.drinks + '' }
                      onChange={ this.handleBacChange }
                    />
                    <InputGroupAddon addonType="append">Drinks</InputGroupAddon>
                  </InputGroup>
                </Col>

                <Col>
                  <InputGroup>
                    <Input
                      type="number"
                      name="hours"
                      step="1"
                      min="0"
                      value={ this.state.hours + '' }
                      onChange={ this.handleBacChange }
                    />
                    <InputGroupAddon addonType="append">Hours</InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>

              <div className={ 'mt-5 d-flex justify-content-between' }>
                <h4>Estimated BAC:</h4>
                <h4>{ this.state.bac }%</h4>
              </div>
              <div className={ 'mt-3 d-flex justify-content-between' }>
                <h4>Time to 0.00%:</h4>
                <h4>{ this.state.timeToZero } hours</h4>
              </div>
            </div>
            <small
              className="form-text text-muted my-3">
              These are estimates and may underestimate true BAC.
              These values are not intended to convey that any drinking is safe.
              Alcohol use is illegal if under 21 and driving after any drinking is <u>not</u> recommended.
            </small>
          </TabPane>

          <TabPane tabId="2">
            <div className={ 'mt-3' }>
              <p>
                Use this calculator to determine how many ounces are in one standard drink based on % alcohol, as well
                as the number of standard drinks based on # of ounces.
              </p>
              <Row
                form
                className={ 'mt-3' }>
                <Col>
                  What's the alcohol content of the beverage?
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col>
                  <InputGroup>
                    <Input
                      type="number"
                      name="abv"
                      step="0.1"
                      min="0"
                      max="100"
                      value={ this.state.abv + '' }
                      onChange={ this.handleAbvChange }
                    />
                    <InputGroupAddon addonType="append">% ABV</InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>

              <Row className={ 'mt-3' }>
                <Col>
                  U.S. standard drink (or drink-equivalent) size:
                </Col>
              </Row>

              <Row>
                <Col></Col>
                <Col>
                  <InputGroup>
                    <Input
                      value={ this.state.drinkSizeEquiv }
                      disabled
                    />
                    <InputGroupAddon addonType="append">fl. oz.</InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>

              <Row className={ 'mt-3' }>
                <Col>
                  What's the container size?
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col>
                  <InputGroup>
                    <Input
                      type="number"
                      name="abvContainerSize"
                      step="1"
                      min="0"
                      value={ this.state.abvContainerSize + '' }
                      onChange={ this.handleAbvChange }
                    />
                    <InputGroupAddon addonType="append">fl. oz.</InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>

              <Row className={ 'mt-3' }>
                <Col>
                  Number of U.S. standard drinks (or drink-equivalents) per container:
                </Col>
              </Row>

              <Row>
                <Col></Col>
                <Col>
                  <InputGroup>
                    <Input
                      value={ this.state.drinksPerContainer }
                      disabled
                    />
                    <InputGroupAddon addonType="append">drinks</InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    calculatorsActions: bindActionCreators(calculatorsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calculators);