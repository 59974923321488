import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as responsesActions from '../../../actions/responsesActions';
import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import jsonLogic from 'json-logic-js';

class GridCheckboxInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAnswer: true,
      value: '',
    };
  }

  componentDidMount() {
    let recordedVal = '';

    if (typeof this.props.responses[this.props.participantWindow.slug].responses[this.props.name] !== 'undefined') {
      recordedVal = this.props.responses[this.props.participantWindow.slug].responses[this.props.name].value;
    }
    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value) {
      recordedVal = '';
    }

    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value) {
      recordedVal = '';
    }

    if (recordedVal.length > 0 && recordedVal !== this.state.value) {
      this.setState({ value: recordedVal });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showElement === false && prevProps.showElement !== this.props.showElement) {
      this.answerHasHidden();
    }

    if (this.state.showAnswer === false && prevState.showAnswer !== this.state.showAnswer) {
      this.answerHasHidden();
    }

    if (prevProps.responses[this.props.participantWindow.slug].responses !== this.props.responses[this.props.participantWindow.slug].responses) {
      this.evalShowIf();
    }

    if (this.props.showElement === true && this.state.showAnswer === true) {
      this.answerHasShown();
    }
  }

  answerHasHidden() {
    // unset value and record not_shown
    this.setState({ value: '' });

    const answer = this.props.slug;
    const element = this.props.element;
    const name = this.props.name;
    const value = this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value;
    const participantWindow = this.props.participantWindow.slug;

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  answerHasShown() {
    const answer = this.props.slug;
    const element = this.props.element;
    const name = this.props.name;
    const participantWindow = this.props.participantWindow.slug;
    let value = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;

    if (this.state.value.length > 0) {
      value = this.state.value;
    }

    if (typeof this.props.responses[participantWindow].responses[name] !== 'undefined' &&
      value === this.props.responses[participantWindow].responses[name].value) {
      return;
    }

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  calculatedValue() {
    if (typeof this.state.value === 'undefined') {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    if (this.state.value.length < 1) {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    return this.state.value;
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showElement) {
      this.setState({
        showElement: show,
      });
    }
  }

  handleChange = event => {
    this.setState({ value: event.target.checked ? event.target.value : '' });
  };

  render() {
    return (
      <FormGroup check>
        <Label check>
          <Input
            className={ 'input response position-relative' }
            name={ this.props.name }
            type="checkbox"
            value={ this.props.value }
            onBlur={ this.handleBlur }
            onChange={ this.handleChange }
            checked={ this.props.value === this.state.value }/>
          <span className={ 'd-inline d-md-none ml-1' }>
                        { this.props.label }
                    </span>
        </Label>
      </FormGroup>
    );

  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridCheckboxInput);