import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import moment from 'moment-timezone';
import styled from 'styled-components';
import produce from 'immer';
import Block from './blocks/taker/Block';
import Checkbox from './blocks/taker/Checkbox';
import Number from './blocks/taker/Number';
import Radio from './blocks/taker/Radio';
import Select from './blocks/taker/Select';
import TakerPage from './blocks/taker/TakerPage';
import Text from './blocks/taker/Text';
import GridCheckbox from './blocks/taker/GridCheckbox';
import GridNumber from './blocks/taker/GridNumber';
import GridRadio from './blocks/taker/GridRadio';
import GridSelect from './blocks/taker/GridSelect';
import GridText from './blocks/taker/GridText';
import TakerButton from './blocks/taker/TakerButton';
import * as responsesActions from '../actions/responsesActions';
import * as surveysActions from '../actions/surveysActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Calculation from "./blocks/taker/Calculation";

const TakerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
`;

class Taker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectCounter: 7,
      redirectString: null,
      redirectTimer: null,
      participantWindow: {},
      currentPage: 1,
      pageHeights: {},
      burstStats: {
        count: {
          started: 0,
          completed: 0,
        },
        streak: {
          started: {
            status: null,
            length: 0,
          },
          completed: {
            status: null,
            length: 0,
          }
        }
      },
      spinning: false,
      submitted: false,
    };

    this.topRef = React.createRef();

    this.pageCount = 0;

    for (const burstKey in this.props.surveys.schedule.schedule) {
      const burst = this.props.surveys.schedule.schedule[burstKey];

      for (const participantWindowKey in burst) {
        const participantWindow = burst[participantWindowKey];

        if (participantWindow.slug === this.props.match.params.slug) {
          this.state.participantWindow = participantWindow;
        }
      }
    }

    const burst1Len = Object.keys(this.props.surveys.schedule.schedule['burst_1']).length - 1;
    const now = moment();

    if (burst1Len > 0) {

      // calculate started streak
      for (let i = burst1Len; i > 0; i--) {
        const participantWindowKey = 'survey_' + i;
        const checkPW = this.props.surveys.schedule.schedule['burst_1'][participantWindowKey];
        let startedAt = checkPW.started_at;

        if (moment(checkPW.closed_at) > now) {
          continue;
        }

        if (startedAt === null) {
          // check the response store
          if (typeof this.props.responses[checkPW.slug] !== 'undefined') {
            startedAt = this.props.responses[checkPW.slug].meta.started_at;
          }
        }

        let status = '-';
        if (startedAt !== null) {
          status = '+';
        }

        if (this.state.burstStats.streak.started.status === null) {
          this.state.burstStats.streak.started.status = status;
        }

        if (this.state.burstStats.streak.started.status === status) {
          this.state.burstStats.streak.started.length++;
        }

        if (this.state.burstStats.streak.started.status !== status) {
          break;
        }
      }

      // calculate completed streak
      for (let i = burst1Len; i > 0; i--) {
        const participantWindowKey = 'survey_' + i;
        const checkPW = this.props.surveys.schedule.schedule['burst_1'][participantWindowKey];
        let completedAt = checkPW.completed_at;

        if (moment(checkPW.closed_at) > now) {
          continue;
        }

        if (completedAt === null) {
          // check the response store
          if (typeof this.props.responses[checkPW.slug] !== 'undefined') {
            completedAt = this.props.responses[checkPW.slug].meta.completed_at;
          }
        }

        let status = '-';
        if (completedAt !== null) {
          status = '+';
        }

        if (this.state.burstStats.streak.completed.status === null) {
          this.state.burstStats.streak.completed.status = status;
        }

        if (this.state.burstStats.streak.completed.status === status) {
          this.state.burstStats.streak.completed.length++;
        }

        if (this.state.burstStats.streak.completed.status !== status) {
          break;
        }
      }

      // calculate counts
      for (let i = burst1Len; i > 0; i--) {
        const participantWindowKey = 'survey_' + i;
        const checkPW = this.props.surveys.schedule.schedule['burst_1'][participantWindowKey];
        let startedAt = checkPW.started_at;
        let completedAt = checkPW.completed_at;

        if (startedAt === null) {
          // check the response store
          if (typeof this.props.responses[checkPW.slug] !== 'undefined') {
            startedAt = this.props.responses[checkPW.slug].meta.started_at;
          }
        }

        if (completedAt === null) {
          // check the response store
          if (typeof this.props.responses[checkPW.slug] !== 'undefined') {
            completedAt = this.props.responses[checkPW.slug].meta.completed_at;
          }
        }

        if (startedAt !== null) {
          this.state.burstStats.count.started++;
        }

        if (completedAt !== null) {
          this.state.burstStats.count.completed++;
        }
      }

    }

    this.updatePageHeight = this.updatePageHeight.bind(this);
  }

  componentDidMount() {
    let closed = false;
    let completed = false;

    if (this.state.participantWindow.closed_at !== null &&
      moment() > moment(this.state.participantWindow.closed_at).add(5, 'minutes')) {
      closed = true;
    }

    if (typeof this.props.responses[this.state.participantWindow.slug] !== undefined
      && this.props.responses[this.state.participantWindow.slug].meta.completed_at !== null) {
      completed = true;
    }

    if (closed === true || completed === true) {
      this.setState({
        redirect: true
      });
    }

    if (typeof this.props.responses[this.state.participantWindow.slug] !== undefined
      && this.props.responses[this.state.participantWindow.slug].meta.page !== null) {
      this.setState({
        currentPage: this.props.responses[this.state.participantWindow.slug].meta.page
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.redirectTimer);
  }

  handleNav(increment) {
    const oldPage = this.state.currentPage;
    const newPage = oldPage + increment;
    const percent = Math.round((oldPage / this.pageCount) * 100);

    if (newPage <= 0) {
      return;
    }

    if (newPage > this.pageCount) {
      return;
    }

    this.setState({
        currentPage: newPage
      }, () => this.skipEmptyPage(increment),
    );

    this.props.responsesActions.updatePage(this.state.participantWindow.slug, oldPage, percent);

    this.topRef.current.scrollIntoView(true);
  }

  handleSubmit() {
    this.setState({
      spinning: true,
      submitted: true,
      currentPage: this.pageCount + 1,
    });

    this.startCountdown();

    this.props.responsesActions.endCollection(
      this.state.participantWindow.slug,
      Math.min(this.state.currentPage, this.pageCount),
      Math.min(Math.round((this.state.currentPage / this.pageCount) * 100), 100)
    );

    this.props.responsesActions.submitResponses(
      this.state.participantWindow.slug,
    );

    setTimeout(() => {
      this.props.surveysActions.setPastSurveys(
        this.props.surveys.schedule.schedule,
        this.props.responses
      );

      this.props.surveysActions.setOpenSurveys(
        this.props.surveys.schedule.schedule,
        this.props.responses
      );

      this.props.surveysActions.setFutureSurveys(
        this.props.surveys.schedule.schedule
      );
    }, 4000);

    setTimeout(() => {
      console.log('Redirect NOW!!');
      this.setState({
        redirect: true,
      });
    }, 7000);
  }

  skipEmptyPage(increment) {
    // TODO: do this better, without setTimeout

    setTimeout(() => {
      if (this.state.currentPage === this.pageCount) {
        this.setState({
          spinning: false
        });
      } else if (this.state.pageHeights[this.state.currentPage] === 0) {
        this.setState({
          spinning: true
        });
        this.handleNav(increment);
      } else {
        this.setState({
          spinning: false
        });
      }
    }, 15);
  }

  startCountdown() {
    const redirectTimer = setInterval(() => {
      const redirectCounter = this.state.redirectCounter - 1;
      let redirectString = `Redirecting in  ${redirectCounter} seconds...`;

      if (redirectCounter === 1) {
        redirectString = `Redirecting in  ${redirectCounter} second...`;
      }

      if (redirectCounter < 1) {
        return;
      }

      this.setState({
        redirectString,
        redirectCounter,
      })
    }, 1000);

    const redirectString = `Redirecting in  ${this.state.redirectCounter} seconds...`;

    this.setState({
      redirectString,
      redirectTimer,
    });
  }

  updatePageHeight(page, height) {
    if (this.state.pageHeights[page] !== height) {
      this.setState(
        produce(this.state, (draft) => {
          draft.pageHeights[page] = height;
        }),
      );
    }
  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to={ '/tool-kit' }/>;
    }

    if (this.state.redirect === true) {
      if (this.props.participant.data.rand_code.indexOf('INTERVENTION') > -1) {
        return <Redirect to={ '/messages' }/>;
      }

      // onboarding survey
      if (this.state.participantWindow.props.burst_survey === 0) {
        return <Redirect to="/settings"/>;
      }

      // last survey
      if (this.state.participantWindow.props.burst_survey === 42) {
        return <Redirect to="/survey?msg=thank-you-final"/>;
      }

      // normal burst survey
      return <Redirect to="/survey?msg=thank-you"/>;
    }

    const now = moment();

    if (moment(this.state.participantWindow.opened_at) > now) {
      return (
        <div>
          <h3>The window for this survey has not yet opened.</h3>
        </div>
      );
    }

    if (this.state.participantWindow.closed_at !== null &&
      now.diff(moment(this.state.participantWindow.closed_at), 'minutes') > 5) {
      return (
        <div>
          <h3>The window for this survey has closed.</h3>
        </div>
      );
    }

    if (this.state.participantWindow.completed_at !== null) {
      return (
        <div>
          <h3>This survey has already been completed.</h3>
        </div>
      );
    }

    // convert the survey elements into react components and arrange by page
    const pages = [];
    let i = 1;
    const surveyCollections = this.props.surveys.data[this.state.participantWindow.survey].survey.collections;
    for (const collectionKey in surveyCollections) {
      const col = surveyCollections[collectionKey];

      // start a new page at the beginning of each collection
      let page = [];

      for (const elementGroupKey in col.elements) {
        const elementGroup = col.elements[elementGroupKey];
        let surveyComponent = false;
        let element;
        let
          elements;

        switch (elementGroup[0].type) {
          case 'block':
            element = elementGroup[0];
            surveyComponent = (
              <Block
                key={ element.slug }
                { ...element }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
                count={ this.state.burstStats.count }
              />
            );
            break;
          case 'calculation':
            element = elementGroup[0];
            surveyComponent = (
              <Calculation
                key={ element.slug }
                { ...element }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
                count={ this.state.burstStats.count }
              />
            );
            break;
          case 'checkbox':
            element = elementGroup[0];
            surveyComponent = (
              <Checkbox
                key={ element.slug }
                { ...element }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'number':
            element = elementGroup[0];
            surveyComponent = (
              <Number
                key={ element.slug }
                { ...element }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'radio':
            element = elementGroup[0];
            surveyComponent = (
              <Radio
                key={ element.slug }
                { ...element }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'select':
            element = elementGroup[0];
            surveyComponent = (
              <Select
                key={ element.slug }
                { ...element }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'text':
            element = elementGroup[0];
            surveyComponent = (
              <Text
                key={ element.slug }
                { ...element }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'grid_checkbox':
            elements = elementGroup;
            surveyComponent = (
              <GridCheckbox
                key={ elements[0].slug }
                elements={ elements }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'grid_number':
            elements = elementGroup;
            surveyComponent = (
              <GridNumber
                key={ elements[0].slug }
                elements={ elements }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'grid_radio':
            elements = elementGroup;
            surveyComponent = (
              <GridRadio
                key={ elements[0].slug }
                elements={ elements }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'grid_select':
            elements = elementGroup;
            surveyComponent = (
              <GridSelect
                key={ elements[0].slug }
                elements={ elements }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          case 'grid_text':
            elements = elementGroup;
            surveyComponent = (
              <GridText
                key={ elements[0].slug }
                elements={ elements }
                participantWindow={ this.state.participantWindow }
                streak={ this.state.burstStats.streak }
              />
            );
            break;
          // case 'calculation':
          //   console.log('Element type:', elementGroup[0].type)
          //   break;
          default:
            console.error('Unknown element type:', elementGroup[0].type);
            break;
        }

        if (surveyComponent !== false) {
          page.push(surveyComponent);
        }

        // close out the page if the master element has a break after
        if (elementGroup[0].break_after && page.length > 0) {
          pages.push(
            <TakerPage
              key={ i }
              page={ i }
              currentPage={ this.state.currentPage }
              elements={ page }
              pageNumber={ i }
              updatePageHeight={ this.updatePageHeight }
            />,
          );
          page = [];
          i++;
        }
      }

      // close out the page at the end of the collection
      if (page.length > 0) {
        pages.push(
          <TakerPage
            key={ i }
            page={ i }
            currentPage={ this.state.currentPage }
            elements={ page }
            pageNumber={ i }
            updatePageHeight={ this.updatePageHeight }
          />,
        );
        page = [];
        i++;
      }
    }

    this.pageCount = pages.length;

    return (
      <TakerWrapper>
        <div ref={ this.topRef }>
          { pages }
        </div>

        <div
          className={ classnames(
            'text-center',
            {
              'd-none': !this.state.spinning
            }
          ) }>
          <div className="my-5 text-primary">
            <FontAwesomeIcon
              icon={ ['fal', 'circle-notch'] }
              spin
              transform="grow-20"
            />
          </div>
          <p>{ this.state.redirectString }</p>
        </div>

        <Row className="my-3 justify-content-center">
          <Col
            xs="6"
            sm="4"
            md="3"
            xl="2"
          >
            <div>
              <TakerButton
                color="secondary"
                disabled={ this.state.currentPage === 1 }
                visible
                label="back"
                handleClick={ () => this.handleNav(-1) }
              />
            </div>
          </Col>
          <Col
            xs="6"
            sm="4"
            md="3"
            xl="2"
          >
            <div>
              <TakerButton
                color="primary"
                disabled={ this.state.currentPage >= pages.length }
                visible={ this.state.currentPage < pages.length }
                label="next"
                handleClick={ () => this.handleNav(1) }
              />
            </div>
            <div>
              <TakerButton
                color="success"
                disabled={ this.state.currentPage < pages.length || this.state.submitted }
                visible={ this.state.currentPage >= pages.length }
                label="submit"
                handleClick={ () => this.handleSubmit() }
              />
            </div>
          </Col>
        </Row>
      </TakerWrapper>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    responsesActions: bindActionCreators(responsesActions, dispatch),
    surveysActions: bindActionCreators(surveysActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Taker);
