import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as participantActions from '../../actions/participantActions';
import * as settingsActions from '../../actions/settingsActions';
import * as signOutActions from '../../actions/signOutActions';
import * as surveysActions from '../../actions/surveysActions';
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import initialState from '../../initialState';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import isEqual from 'lodash/isEqual';

class SettingSignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login_pin: '',
      phone: '',
    };
  }

  componentDidMount() {
    this.parseLoginId();
    this.setCookie();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings.auth.signedIn === false && this.props.settings.auth.signedIn === true) {
      this.fetchAllData();
    }

    if (prevProps.settings.auth.signedIn === true && this.props.settings.auth.signedIn === false) {
      window.location.reload();
    }
  }

  fetchAllData() {
    this.props.participantActions.fetchParticipantData(this.props.settings.auth.slug, this.props.settings.auth.accessToken);
    this.props.surveysActions.fetchSchedule(this.props.settings.auth.accessToken);
    this.props.surveysActions.fetchSurveyData('p3AQ3W', this.props.settings.auth.accessToken);
    this.props.surveysActions.fetchSurveyData('VP5yPO', this.props.settings.auth.accessToken);
    this.props.surveysActions.fetchSurveyData('MqryDd', this.props.settings.auth.accessToken);
  }

  handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  setCookie() {
    const token = this.props.settings.auth.accessToken;
    const slug = this.props.settings.auth.slug;

    if (token.length < 1 || slug.length < 1) {
      return;
    }

    Cookies.set('token', token, { expires: 365 });
    Cookies.set('participant', slug, { expires: 365 });
  }

  signIn() {
    this.props.settingsActions.fetchAuthUser(this.state.phone, this.state.login_pin);
  }

  signOut() {
    if (window.confirm('Any data that has not been uploaded will be lost. Click OK to delete all data.')) {
      this.props.signOutActions.resetState();
    }
  }

  tokenSignIn(token, slug) {
    this.props.settingsActions.signIn(token, slug);
    setTimeout(() => {
      this.fetchAllData();
    }, 100);
  }

  parseLoginId() {
    const queryStr = this.props.location.search;
    const regex = /login_pin=([a-zA-Z0-9]{16})/;

    if (queryStr === 0) {
      return;
    }

    const match = queryStr.match(regex);

    if (typeof match === 'undefined' || match === null) {
      return;
    }

    this.setState({
      login_pin: match[1],
    });

    this.props.history.push(this.props.location.pathname);
  }

  redirectToOnboardingSurvey() {
    if (this.props.settings.auth.signedIn !== true) {
      console.log('can not redirect - not signed in');
      return false;
    }

    if (this.props.participant.data.lastFetch === 0) {
      console.log('can not redirect - participant data not fetched');
      return false;
    }

    if (this.props.surveys.schedule.lastFetch === 0) {
      console.log('can not redirect - schedule not fetched');
      return false;
    }

    if (this.props.surveys.data.lastFetch === 0) {
      console.log('can not redirect - survey data not fetched');
      return false;
    }

    if (typeof this.props.surveys.schedule.schedule.burst_1 === 'undefined' ||
      typeof this.props.surveys.schedule.schedule.burst_1.survey_0 === 'undefined') {
      console.log('should not redirect - unexpected schedule');
      return false;
    }

    const onboardingSurvey = this.props.surveys.schedule.schedule.burst_1.survey_0;

    if (onboardingSurvey.completed_at !== null) {
      console.log('should not redirect - onboarding complete');
      return false;
    }

    if (typeof this.props.responses[onboardingSurvey.slug] === 'undefined' ||
      this.props.responses[onboardingSurvey.slug].meta.started_at === null) {
      console.log('survey has not been started - REDIRECT NOW!');
      return true;
    }

    console.log('should not redirect - did not match any conditionals');
    return false;
  }

  render() {
    let hasAuth = null;
    let hasToken = null;
    let noAuth = null;
    let clearState = null;
    let emptyState = true;
    const token = Cookies.get('token') || false;
    const slug = Cookies.get('participant') || false;

    if (this.redirectToOnboardingSurvey()) {
      return <Redirect to={ '/survey' }/>;
    }

    if (this.props.settings.auth.signedIn === true) {
      hasAuth =
        <div>
          <div className={ 'd-flex justify-content-between' }>
            <div className={ '' }>
              Participant:&nbsp;
            </div>
            <div className={ 'text-muted' }>
              { this.props.participant.data.slug }
            </div>
          </div>
          <p className={ 'text-muted' }>
            Authentication token expires { moment.unix(this.props.settings.auth.accessTokenDecoded.exp).fromNow() }.
          </p>
          <Row
            form
            className={ 'mt-3' }>
            <Col className={ 'text-right' }>
              <Button
                outline
                color="danger"
                size="sm"
                onClick={ () => this.signOut() }
                disabled={ this.props.settings.auth.signedIn !== true }
              >Sign out</Button>
            </Col>
          </Row>

        </div>;
    }

    if (token !== false && slug !== false && hasAuth === null) {
      hasToken =
        <div>
          <p className={ 'text-muted' }>
            Sign in with stored token
          </p>

          <Row
            form
            className={ 'mt-3' }>
            <Col className={ 'text-right' }>
              <Button
                outline
                color="primary"
                size="sm"
                onClick={ () => this.tokenSignIn(token, slug) }
                disabled={ this.props.settings.auth.isFetching || this.props.settings.auth.signedIn }
              >{ this.props.settings.auth.isFetching ? 'Signing in...' : 'Sign in' }</Button>
            </Col>
          </Row>

          <hr/>
        </div>;
    }

    if (hasAuth === null) {
      noAuth =
        <div>
          <p className={ 'text-muted' }>
            Sign in with phone and pin
          </p>
          <Row
            form
            className={ 'mt-3' }>
            <Col>
              Phone
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <Input
                  type="text"
                  name="phone"
                  placeholder={ "555-555-5555" }
                  value={ this.state.phone }
                  disabled={ this.props.settings.auth.isFetching }
                  onChange={ this.handleInputChange }
                />
                <InputGroupAddon addonType="append">Phone</InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          <Row
            form
            className={ 'mt-3' }>
            <Col>
              Login Pin
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <Input
                  type="text"
                  name="login_pin"
                  value={ this.state.login_pin }
                  disabled={ this.props.settings.auth.isFetching }
                  onChange={ this.handleInputChange }
                />
                <InputGroupAddon addonType="append">Pin</InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          <Row
            form
            className={ 'mt-3' }>
            <Col className={ 'text-right' }>
              <Button
                outline
                color="primary"
                size="sm"
                onClick={ () => this.signIn() }
                disabled={ this.props.settings.auth.isFetching || this.props.settings.auth.signedIn }
              >{ this.props.settings.auth.isFetching ? 'Signing in...' : 'Sign in' }</Button>
            </Col>
          </Row>
        </div>;
    }

    for (let property in initialState) {
      if (!initialState.hasOwnProperty(property)) {
        continue;
      }

      if (property === 'settings') {
        continue;
      }

      if (!isEqual(initialState[property], this.props[property])) {
        emptyState = false;
      }

    }

    if (hasAuth === null && emptyState !== true) {
      clearState = <div>
        <p className={ 'text-muted' }>
          { emptyState ? 'State is empty.' : '**STATE IS NOT EMPTY**' }
        </p>
        <Row
          form
          className={ 'mt-3' }>
          <Col className={ 'text-right' }>
            <Button
              outline
              color="danger"
              size="sm"
              onClick={ () => this.signOut() }
            >Clear app</Button>
          </Col>
        </Row>
      </div>
    }


    let participantString = 'Participant data missing.';
    if (this.props.participant.data.lastFetch > 0) {
      participantString = 'Last updated ' + moment.unix(this.props.participant.data.lastFetch).fromNow() + '.';
    }
    if (this.props.participant.isFetching) {
      participantString = 'Updating...';
    }

    let scheduleString = 'Survey schedule missing.';
    if (this.props.surveys.schedule.lastFetch > 0) {
      scheduleString = 'Last updated ' + moment.unix(this.props.surveys.schedule.lastFetch).fromNow() + '.';
    }
    if (this.props.surveys.schedule.isFetching) {
      scheduleString = 'Updating...';
    }

    let surveyDataString = 'Survey data missing.';
    if (this.props.surveys.data.lastFetch > 0) {
      surveyDataString = 'Last updated ' + moment.unix(this.props.surveys.data.lastFetch).fromNow() + '.';
    }
    if (this.props.surveys.data.isFetching) {
      surveyDataString = 'Updating...';
    }

    const participantData =
      <div>
        <hr/>
        <h5>App Data</h5>
        <div className={ 'd-flex justify-content-between' }>
          <div className={ '' }>
            Participant:&nbsp;
          </div>
          <div className={ 'text-muted' }>
            { participantString }
          </div>
        </div>
        <div className={ 'd-flex justify-content-between' }>
          <div className={ '' }>
            Schedule:&nbsp;
          </div>
          <div className={ 'text-muted' }>
            { scheduleString }
          </div>
        </div>
        <div className={ 'd-flex justify-content-between' }>
          <div className={ '' }>
            Survey:&nbsp;
          </div>
          <div className={ 'text-muted' }>
            { surveyDataString }
          </div>
        </div>
        <Row
          form
          className={ 'mt-3' }>
          <Col className={ 'text-right' }>
            <Button
              outline
              color="primary"
              size="sm"
              disabled={ (this.props.participant.isFetching || this.props.surveys.schedule.isFetching || this.props.surveys.data.isFetching) }
              onClick={ () => this.fetchAllData() }
            >{ (this.props.participant.isFetching || this.props.surveys.schedule.isFetching || this.props.surveys.data.isFetching) ? 'Updating...' : 'Update' }</Button>
          </Col>
        </Row>
      </div>;


    return (
      <div>
        <h5>Authentication</h5>
        { hasAuth }

        { hasToken }

        { noAuth }

        {/*{ this.props.settings.auth.signedIn === true ? hasAuth : noAuth }*/ }

        { this.props.settings.auth.signedIn === true ? participantData : null }

        { clearState }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    participantActions: bindActionCreators(participantActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    signOutActions: bindActionCreators(signOutActions, dispatch),
    surveysActions: bindActionCreators(surveysActions, dispatch),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingSignIn));