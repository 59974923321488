import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import BurstSummaryBlock from './blocks/BurstSummaryBlock';
import ErrorBoundary from "./blocks/ErrorBoundary";
import { Button, Col, Row } from "reactstrap";
import classnames from "classnames";

class BurstSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentWeek: this.countWeeks(),
      viewPage: 1,
    };

    this.burstRef = React.createRef()
  }

  componentDidMount() {
    this.handleScroll();
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  handleScroll() {
    this.burstRef.current.scrollIntoView()
  }

  countWeeks() {
    const daysPassed = Math.floor(this.props.surveys.pastSurveys.length / 2);
    return Math.floor(daysPassed / 7);
  }

  summaryPage(page) {
    this.setState({
      viewPage: page,
    })
  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to="/tool-kit"/>;
    }

    let pagination = null;
    if (this.state.currentWeek === 3) {
      pagination = <Row className="my-3 justify-content-center">
        <Col
          xs="6"
          sm="4"
          md="3"
          xl="2"
        >
          <div>
            <Button
              className={ classnames('btn-block', {
                invisible: this.state.viewPage === 1
              }) }
              color="secondary"
              outline
              label="back"
              onClick={ () => this.summaryPage(1) }
              disabled={ this.state.viewPage === 1 }
            >back</Button>
          </div>
        </Col>
        <Col
          xs="6"
          sm="4"
          md="3"
          xl="2"
        >
          <div>
            <Button
              className={ classnames('btn-block', {
                invisible: this.state.viewPage === 2
              }) }
              color="primary"
              outline
              label="next"
              onClick={ () => this.summaryPage(2) }
              disabled={ this.state.viewPage === 2 }
            >next</Button>
          </div>
        </Col>
      </Row>
    }

    return (
      <div
        className={ 'pt-3 h-100 d-flex flex-column justify-content-between' }
        ref={ this.burstRef }
        style={ { marginTop: '-1rem' } }>
        <h2>21 Day Summary</h2>

        <div className={ "mt-3" }>
          <ErrorBoundary>
            <BurstSummaryBlock
              buildWeek={ 3 }
              currentWeek={ this.state.currentWeek }
              viewPage={ this.state.viewPage }
            />
          </ErrorBoundary>
        </div>

        { pagination }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
)(BurstSummary);
