export const SET_BAC = 'SET_BAC';
export const SET_ABV = 'SET_ABV';

export const setBac = (key, value) => {
  return { type: SET_BAC, key, value };
};

export const setAbv = (key, value) => {
  return { type: SET_ABV, key, value };
};
