import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';
import SelectInput from './SelectInput';
import * as responsesActions from '../../../actions/responsesActions';

class GridSelectRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containsPipe: false,
      showRow: true,
    };

    if (props.text.indexOf('{{pipe:') > -1) {
      this.state.containsPipe = true;
    }
  }


  componentDidMount() {
    this.evalShowIf();
  }

  componentDidUpdate() {
    this.evalShowIf();
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showRow) {
      this.setState({
        showRow: show,
      });
    }
  }

  render() {
    const wrapperClasses = classnames(
      'mt-3',
      'taker__mqsa--row', {
        'd-none': !this.state.showRow,
      },
    );
    const textClasses = classnames(
      'taker__text', {
        taker__pipe: this.state.containsPipe,
      },
    );

    return (
      <Row className={ wrapperClasses }>
        <Col
          xs="12"
          md="6"
          className={ textClasses }
        >
          <ReactMarkdown>
            { this.props.text }
          </ReactMarkdown>
        </Col>
        <Col
          xs={ { size: 11, offset: 1 } }
          md={ { size: 6, offset: 0 } }
        >
          <SelectInput
            answer={ this.props.answer }
            element={ this.props.element }
            name={ this.props.name }
            options={ this.props.options }
            participantWindow={ this.props.participantWindow }
            showElement={ this.props.showElement && this.state.showRow }
          />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridSelectRow);
