import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as responsesActions from '../../../actions/responsesActions';
import React, { Component } from 'react';
import { Input } from 'reactstrap';
import moment from "moment";

class HiddenInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.parent + '|' + this.props.slug,
      value: this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value,
    };
  }

  componentDidUpdate() {
    let newVal, participant, yest;

    switch (this.props.element_var_name) {
      case 'DAYOFWEEK':
        newVal = moment().format("dddd");
        break;
      case 'REFERENCEDAY':
        newVal = moment().subtract(1, "days").format("dddd");;
        break;
      case 'WEEKENDYEST':
        yest = parseInt(moment().subtract(1, 'days').format("E"));

        newVal = 0;
        if (yest === 4 || yest === 5 || yest === 6) {
          newVal = 1;
        }
        break;
      case 'WEEKENDYEST2':
        yest = parseInt(moment().subtract(1, 'days').format("E"));

        newVal = 0;
        if (yest === 5 || yest === 6) {
          newVal = 1;
        }
        break;
      case 'BURSTNO':
        newVal = this.props.participantWindow.props.burst;
        break;
      case 'DAILYNO':
        newVal = this.props.participantWindow.props.burst_survey;
        break;
      case 'DAYNO':
        newVal = this.props.participantWindow.props.burst_day;
        break;
      case 'SEX':
        participant = JSON.parse(this.props.participant.data.props);
        newVal = participant['SEX'];
        break;
      case 'WEIGHT':
        participant = JSON.parse(this.props.participant.data.props);
        newVal = participant['WEIGHT'];
        break;
      default:
        newVal = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
        break;
    }

    if (typeof newVal !== 'undefined' && newVal !== this.state.value) {
      const answer = this.props.slug;
      const element = this.props.parent;
      const name = this.state.name;
      const participantWindow = this.props.participantWindow.slug;

      this.props.responsesActions.recordResponse(
        answer, element, name, newVal, participantWindow
      );

      this.setState({
        value: newVal,
      })
    }
  }

  render() {
    return (
      <div>
        <Input
          className={ 'input response calculation' }
          name={ this.state.name }
          type="hidden"
          value={ this.state.value }/>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HiddenInput);