import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import style from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router-dom';

const Brick = style.div`
    border-radius: .25rem;
    width: 3rem;
    height: 3rem;
    margin: 5px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
`;

class SurveyCompletion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: (this.countWeeks() + 1).toString(),
    };
  }

  countWeeks() {
    const daysPassed = Math.floor(this.props.surveys.pastSurveys.length / 2);

    return Math.min(2, Math.floor(daysPassed / 7));
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to="/tool-kit"/>;
    }

    if (typeof this.props.surveys.schedule.schedule.burst_1['survey_1'] === 'undefined') {
      return <Redirect to="/tool-kit"/>;
    }

    const rows = [];
    const counts = [[0,0],[0,0],[0,0],];
    for (let i = 1; i <= 21; i++) {
      const now = moment();
      const amPW = this.props.surveys.schedule.schedule.burst_1[`survey_${ (i * 2) - 1 }`];
      const pmPW = this.props.surveys.schedule.schedule.burst_1[`survey_${ i * 2 }`];
      let amIcon = null;
      let amStatus = amPW.completed_at === null ? 'incomplete' : 'complete';
      let pmIcon = null;
      let pmStatus = pmPW.completed_at === null ? 'incomplete' : 'complete';

      if (amStatus === 'incomplete' && typeof this.props.responses[amPW.slug] !== 'undefined') {
        // check the response store
        if (this.props.responses[amPW.slug].meta.completed_at !== null) {
          amStatus = 'complete';
        }
      }

      if (pmStatus === 'incomplete' && typeof this.props.responses[pmPW.slug] !== 'undefined') {
        // check the response store
        if (this.props.responses[pmPW.slug].meta.completed_at !== null) {
          pmStatus = 'complete';
        }
      }

      if (moment(amPW.closed_at) > now && amStatus !== 'complete') {
        amStatus = 'future';
      }

      if (moment(pmPW.closed_at) > now && pmStatus !== 'complete') {
        pmStatus = 'future';
      }

      if (amStatus === 'incomplete') {
        amIcon = (
          <FontAwesomeIcon
            icon={ ['fal', 'times'] }
            transform="grow-20"
          />
        );
      }

      if (pmStatus === 'incomplete') {
        pmIcon = (
          <FontAwesomeIcon
            icon={ ['fal', 'times'] }
            transform="grow-20"
          />
        );
      }

      let countIndex = 0;
      if (i <= 14 && i > 7 ) {
        countIndex = 1;
      }

      if (i <= 21 && i > 14 ) {
        countIndex = 2;
      }

      if (amStatus === 'complete') {
        counts[countIndex][0]++;
      }

      if (pmStatus === 'complete') {
        counts[countIndex][1]++;
      }

      rows.push(
        <Row key={ i }>
          <Col xs={5}>
            Day { i }
            <br/>
            { moment(amPW.opened_at).format('dddd') }
          </Col>
          <Col className="d-flex justify-content-around">
            <Brick
              className={ classnames('border', {
                'border-info': amStatus === 'complete',
                'border-success': (pmStatus === 'future' || pmStatus === 'incomplete'),
                'bg-info': amStatus === 'complete',
              }) }
            >
              { amIcon }
            </Brick>
          </Col>
          <Col className="d-flex justify-content-around">
            <Brick
              className={ classnames('border', {
                'border-info': pmStatus === 'complete',
                'border-success': (pmStatus === 'future' || pmStatus === 'incomplete'),
                'bg-info': pmStatus === 'complete',
              }) }
            >
              { pmIcon }
            </Brick>
          </Col>
        </Row>,
      );
    }

    return (
      <div>
        <h2>Survey Completion</h2>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={ classnames({ active: this.state.activeTab === '1' }) }
              onClick={ () => {
                this.toggle('1');
              } }
            >

              Week 1
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={ classnames({ active: this.state.activeTab === '2' }) }
              onClick={ () => {
                this.toggle('2');
              } }
            >

              Week 2
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={ classnames({ active: this.state.activeTab === '3' }) }
              onClick={ () => {
                this.toggle('3');
              } }
            >

              Week 3
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={ this.state.activeTab }>
          <TabPane tabId="1">
            <div className="mt-3">
              <Row>
                <Col xs={5}/>
                <Col className="d-flex justify-content-around">AM</Col>
                <Col className="d-flex justify-content-around">PM</Col>
              </Row>

              { rows.filter((row, index) => index < 7) }

              <hr />
              <Row>
                <Col xs={5}>Completed this week</Col>
                <Col className={'text-center'}>{ counts[0][0] }</Col>
                <Col className={'text-center'}>{ counts[0][1] }</Col>
              </Row>


              <hr />
              <Row>
                <Col xs={5}>Grand Total Complete</Col>
                <Col className={'text-center'}>{ counts[0][0] + counts[1][0] + counts[2][0] + counts[0][1] + counts[1][1] + counts[2][1] }</Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className="mt-3">
              <Row>
                <Col xs={5}/>
                <Col className="d-flex justify-content-around">AM</Col>
                <Col className="d-flex justify-content-around">PM</Col>
              </Row>

              { rows.filter((row, index) => index >= 7 && index < 14) }

              <hr />
              <Row>
                <Col xs={5}>Completed this week</Col>
                <Col className={'text-center'}>{ counts[1][0] }</Col>
                <Col className={'text-center'}>{ counts[1][1] }</Col>
              </Row>

              <hr />
              <Row>
                <Col xs={5}>Completed to date</Col>
                <Col className={'text-center'}>{ counts[0][0] + counts[1][0] }</Col>
                <Col className={'text-center'}>{ counts[0][1] + counts[1][1] }</Col>
              </Row>


              <hr />
              <Row>
                <Col xs={5}>Grand Total Complete</Col>
                <Col className={'text-center'}>{ counts[0][0] + counts[1][0] + counts[2][0] + counts[0][1] + counts[1][1] + counts[2][1] }</Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="3">
            <div className="mt-3">

              <Row>
                <Col xs={5}/>
                <Col className="d-flex justify-content-around">AM</Col>
                <Col className="d-flex justify-content-around">PM</Col>
              </Row>

              { rows.filter((row, index) => index >= 14) }

              <hr />
              <Row>
                <Col xs={5}>Completed this week</Col>
                <Col className={'text-center'}>{ counts[2][0] }</Col>
                <Col className={'text-center'}>{ counts[2][1] }</Col>
              </Row>

              <hr />
              <Row>
                <Col xs={5}>Completed to date</Col>
                <Col className={'text-center'}>{ counts[0][0] + counts[1][0] + counts[2][0] }</Col>
                <Col className={'text-center'}>{ counts[0][1] + counts[1][1] + counts[2][1] }</Col>
              </Row>

              <hr />
              <Row>
                <Col xs={5}>Grand Total Complete</Col>
                <Col className={'text-center'}>{ counts[0][0] + counts[1][0] + counts[2][0] + counts[0][1] + counts[1][1] + counts[2][1] }</Col>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
)(SurveyCompletion);
