import produce from 'immer';
import initialState from '../initialState';
import {
  FETCHING_SUMMARY,
  SET_SUMMARY,
} from '../actions/summaryActions';

export default function update(state = initialState.summaries, action) {
  let newState;
  switch (action.type) {
    case FETCHING_SUMMARY:
      if (state.isFetching === action.value) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.isFetching = action.value;
      });
      return newState;

    case SET_SUMMARY:
      if (state[action.period] === action.summary) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft[action.period] = action.summary;
      });
      return newState;

    default:
      return state;
  }
}
