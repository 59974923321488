import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import style from 'styled-components';
import * as videosActions from '../actions/videosActions';
import queryString from 'query-string';
import ReactPlayer from "react-player";

const PlayerWrapper = style.div`
    position: relative;
    padding-top: 56.25%;
    width: 100%;
`;

class Videos extends Component {
  constructor(props) {
    super(props);

    this.videoRefs = {};
  }

  filter() {
    const params = queryString.parse(this.props.location.search);
    const filters = ['intro', 'sleep', 'placebo', 'tolerance', 'barlab'];

    const keysToShow = filters.filter((item, index) => {
      if (filters.indexOf(params.show) === -1) {
        return true;
      }
      return filters.indexOf(params.show) === index;
    }).map((item) => {
      return (filters.indexOf(item) + 1).toString();
    });

    return keysToShow;
  }

  list(keysToShow) {
    const list = [];

    for (const key in this.props.videos) {
      if (keysToShow.indexOf(key) === -1) {
        continue;
      }

      list.push(
        <div
          key={ key }
          className="col-xs-12 col-lg-6 my-3"
        >
          { this.videoTitle(key) }
          { this.player(key) }
        </div>
      );
    }

    return list;
  }

  player(key) {
    return (
        <PlayerWrapper>
          <ReactPlayer
            key={ key }
            className="react-player"
            controls={ true }
            light
            url={ this.props.videos[key].src }
            playing={ true }
            playsinline={ true }
            width="100%"
            height="100%"
            onPlay={ () => this.isStarted(key) }
            onPause={ () => this.isPaused(key) }
            onEnded={ () => this.isEnded(key) }
            ref={ (ref) => {
              if (ref) {
                this.videoRefs[key] = ref;
              }
            } }
          />
        </PlayerWrapper>
    );
  }

  videoTitle(key)
  {
    let title = null;
    switch (key) {
      case '1':
        title = 'Introduction: Alcohol Expectancies and the 21-day Challenge';
        break;
      case '2':
        title = 'Alcohol and a Good Night\'s Sleep: Can I have both?';
        break;
      case '3':
        title = 'The Tale of Alcohol Expectancies';
        break;
      case '4':
        title = 'Tolerance and Biphasic Effects of Alcohol';
        break;
      case '5':
        title = 'Bill Nye at the BARLAB';
        break;
      default:
        break;
    }

    return <h5>{ title }</h5>
  }

  isEnded(key) {
    const progress = this.videoRefs[key].getCurrentTime();

    this.props.videosActions.videoCompleted(key, progress);
    this.props.videosActions.videoEvent(key, progress, 'ended');
  }

  isPaused(key) {
    const progress = this.videoRefs[key].getCurrentTime();

    this.props.videosActions.videoProgress(key, progress);
    this.props.videosActions.videoEvent(key, progress, 'paused');
  }

  isStarted(key) {
    const progress = this.videoRefs[key].getCurrentTime();

    this.props.videosActions.videoStarted(key);
    this.props.videosActions.videoEvent(key, progress, 'started');
  }

  render() {
    const keysToShow = this.filter();
    const list = this.list(keysToShow);
    let clearFilter = null;

    if (keysToShow.length !== 5) {
      clearFilter = <div className={ 'text-right' }>
        <Link to={ '/videos' }>Show all</Link>
      </div>
    }

    return (
      <div>
        <h2>Videos</h2>
        { clearFilter }
        <div className="row">
          { list }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    videosActions: bindActionCreators(videosActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Videos);
