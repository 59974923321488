import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as responsesActions from '../../../actions/responsesActions';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import GridRadioInput from './GridRadioInput';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';

class GridRadioRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedVal: '',
      containsPipe: false,
      name: this.props.name,
      showRow: true,
    };

    if (props.text.indexOf('{{pipe:') > -1) {
      this.state.containsPipe = true;
    }
  }

  componentDidMount() {
    let recordedVal = '';

    if (typeof this.props.responses[this.props.participantWindow.slug].responses[this.state.name] !== 'undefined') {
      recordedVal = this.props.responses[this.props.participantWindow.slug].responses[this.state.name].value;
    }
    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value) {
      recordedVal = '';
    }

    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value) {
      recordedVal = '';
    }

    if (recordedVal.length > 0 && recordedVal !== this.state.checkedVal) {
      this.setState({ checkedVal: recordedVal });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.evalShowIf();

    if (this.props.showElement === false && prevProps.showElement !== this.props.showElement) {
      this.answerHasHidden();
    }

    if (this.state.showRow === false && prevState.showRow !== this.state.showRow) {
      this.answerHasHidden();
    }

    if (prevProps.responses[this.props.participantWindow.slug].responses !== this.props.responses[this.props.participantWindow.slug].responses) {
      this.evalShowIf();
    }

    if (this.state.showRow === true && this.props.showElement) {
      this.answerHasShown();
    }
  }

  answerHasHidden() {
    // unset value and record not_shown
    this.setState({ checkedVal: '' });

    const answer = this.props.gridAnswers[0].slug;
    const element = this.props.element;
    const name = this.state.name;
    const value = this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value;
    const participantWindow = this.props.participantWindow.slug;

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  answerHasShown() {
    const answer = this.props.gridAnswers[0].slug;
    const element = this.props.element;
    const name = this.state.name;
    const participantWindow = this.props.participantWindow.slug;
    let value = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;

    if (this.state.checkedVal.length > 0) {
      value = this.state.checkedVal;
    }

    if (typeof this.props.responses[participantWindow].responses[name] !== 'undefined' &&
      value === this.props.responses[participantWindow].responses[name].value) {
      return;
    }

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  calculatedValue() {
    if (typeof this.state.checkedVal === 'undefined') {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    if (this.state.checkedVal.length < 1) {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    return this.state.checkedVal;
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showRow) {
      this.setState({
        showRow: show,
      });
    }
  }

  handleChange = event => {
    const answer = this.props.gridAnswers[0].slug;
    const element = this.props.element;
    const name = this.state.name;
    const value = event.target.value;
    const participantWindow = this.props.participantWindow.slug;

    this.setState(
      { checkedVal: value },
      () => {
        this.props.responsesActions.recordResponse(
          answer, element, name, value, participantWindow
        );
      }
    );
  };

  render() {
    const wrapperClasses = classnames(
      'mt-3',
      'taker__mqsa--row', {
        'd-none': !this.state.showRow,
      },
    );
    const textClasses = classnames(
      'taker__text', {
        'taker__pipe': this.state.containsPipe
      }
    );
    const inputs = this.props.gridAnswers.map((input) => {
      const key = this.props.slug + '|' + input.slug;
      return (
        <div
          key={ key }
          className={ 'col-md text-md-center align-self-start' }>
          <GridRadioInput
            name={ this.props.name }
            checkedVal={ this.state.checkedVal }
            handleChange={ this.handleChange }
            { ...input } />
        </div>
      );
    });

    return (
      <Row className={ wrapperClasses }>
        <Col
          xs="12"
          md="4"
          className={ textClasses }>
          <ReactMarkdown>
            { this.props.text }
          </ReactMarkdown>
        </Col>
        <Col
          xs={ { size: 11, offset: 1, } }
          md={ { size: 8, offset: 0, } }>
          <Row>
            <Col md={ { size: 11, offset: 1, } }>
              <Row>
                { inputs }
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridRadioRow);