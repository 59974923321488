import { connect } from 'react-redux';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import styled from "styled-components";
import classnames from "classnames";
import { ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as summaryActions from "../../actions/summaryActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BurstSummary = styled.div`
    height: 100%;
`;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

class BurstSummaryBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      viewStart: null,
      viewEnd: null,
    };

  }

  componentDidMount() {
    this.isDataLoaded();
    this.props.dispatch(this.props.summaryActions.fetchSummary('burst-1'));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.isDataLoaded();
  }

  isDataLoaded() {
    let loaded = (
      typeof this.props.summaries === "object" &&
      typeof this.props.summaries['burst-1'] === "object" &&
      Object.entries(this.props.summaries['burst-1']).length > 0 &&
      this.state.dataLoaded === false);

    if (loaded) {
      this.setState({
        dataLoaded: true
      });

      this.viewStart();
    }

    return loaded;
  }

  viewStart() {
    this.setState({
      viewStart: moment(),
    });
  }

  viewEnd() {
    this.setState({
      viewStart: null
    });
  }

  mappedCompletedSurveys() {
    let str = '?? surveys';

    if (!this.state.dataLoaded) {
      return str;
    }

    const surveys = parseInt(this.props.summaries['burst-1']['sum']['COMPLETED_SURVEYS']);

    if (surveys === 1) {
      return surveys + ' survey';
    }

    return surveys + ' surveys';
  }

  mappedMood() {
    let str = '??';

    if (!this.state.dataLoaded) {
      return str;
    }

    const avgMood = parseInt(this.props.summaries['burst-1']['avg']['AM_DAYMOOD']);

    if (avgMood <= 0) {
      return str;
    }

    if (avgMood < 1.5) {
      return 'Very bad';
    }

    if (avgMood < 2.5) {
      return 'Bad';
    }

    if (avgMood < 3.5) {
      return 'Moderate';
    }

    if (avgMood < 4.5) {
      return 'Good';
    }

    if (avgMood < 5.5) {
      return 'Very good';
    }

    return str;
  }

  mappedSleepHours() {
    let str = '?? hours';

    if (!this.state.dataLoaded) {
      return str;
    }

    const avgSleepMinutes = parseInt(this.props.summaries['burst-1']['avg']['AM_SLEEP03']);

    return Math.ceil((avgSleepMinutes / 60) * 2) / 2 + ' hours';
  }

  mappedSleepQuality() {
    let str = '??';

    if (!this.state.dataLoaded) {
      return str;
    }

    const avgSleepQuality = parseInt(this.props.summaries['burst-1']['avg']['AM_SLEEP04']);

    if (avgSleepQuality <= 0) {
      return str;
    }

    if (avgSleepQuality < 1.5) {
      return 'Very bad';
    }

    if (avgSleepQuality < 2.5) {
      return 'Bad';
    }

    if (avgSleepQuality < 3.5) {
      return 'Moderate';
    }

    if (avgSleepQuality < 4.5) {
      return 'Good';
    }

    if (avgSleepQuality < 5.5) {
      return 'Very good';
    }

    return str;
  }

  mappedPhysicalHealth() {
    let str = '??';

    if (!this.state.dataLoaded) {
      return str;
    }

    const avgPhysicalHealth = parseInt(this.props.summaries['burst-1']['avg']['AM_DAYHEALTH']);

    if (avgPhysicalHealth <= 0) {
      return str;
    }

    if (avgPhysicalHealth < 1.5) {
      return 'Very bad';
    }

    if (avgPhysicalHealth < 2.5) {
      return 'Bad';
    }

    if (avgPhysicalHealth < 3.5) {
      return 'Moderate';
    }

    if (avgPhysicalHealth < 4.5) {
      return 'Good';
    }

    if (avgPhysicalHealth < 5.5) {
      return 'Very good';
    }

    return str;
  }

  mappedExercise() {
    let str = '?? days';

    if (!this.state.dataLoaded) {
      console.log('!this.state.dataLoaded');
      return str;
    }

    const surveys = parseInt(this.props.summaries['burst-1']['sum']['PM_EXER02']);

    if (surveys === 1) {
      return surveys + ' day';
    }

    return surveys + ' days';
  }

  mappedDrinkingDays() {
    let str = '??';

    if (!this.state.dataLoaded) {
      console.log('!this.state.dataLoaded');
      return str;
    }

    const days = parseInt(this.props.summaries['burst-1']['sum']['AM_ALC01']);

    if (isNaN(days)) {
      return str;
    }

    return days;
  }

  mappedBingeDays() {
    let str = '??';

    if (!this.state.dataLoaded) {
      console.log('!this.state.dataLoaded');
      return str;
    }

    const days = parseInt(this.props.summaries['burst-1']['sum']['AM_BINGE']);

    if (isNaN(days)) {
      return str;
    }

    return days;
  }

  mappedNegativeInfluences() {
    const negInfluences = [];

    if (!this.state.dataLoaded) {
      return negInfluences;
    }

    for (let i = 1; i < 7; i++) {
      const endorsed = parseInt(this.props.summaries['burst-1']['sum']['AM_RATING0' + i]);

      if (!isNaN(endorsed) && endorsed > 0) {
        switch (i) {
          case 1:
            negInfluences.push('Overall mood');
            break;
          case 2:
            negInfluences.push('Academics or work');
            break;
          case 3:
            negInfluences.push('Relationships with friends');
            break;
          case 4:
            negInfluences.push('Relationships with parents');
            break;
          case 5:
            negInfluences.push('Relationship with current or potential dating, romantic, or sexual partner');
            break;
          case 6:
            negInfluences.push('Finances/spending money');
            break;
          case 7:
            negInfluences.push('Physical health');
            break;
          default:
            // do nothing
            break;
        }
      }
    }

    return negInfluences;
  }

  showNegativeInfluences() {
    return this.mappedNegativeInfluences().length > 0;
  }

  render() {
    if (this.props.currentWeek < this.props.buildWeek) {
      return (
        <h4>Check back after week { this.props.buildWeek }.</h4>
      );
    }

    return (
      <BurstSummary>
        <PageWrapper
          className={ classnames({ 'd-none': this.state.dataLoaded }) }>
          <div className="text-center">
            <div className="my-5 text-primary">
              <FontAwesomeIcon
                icon={ ['fal', 'atom'] }
                spin
                transform="grow-20"
              />
            </div>
            <p>Loading summary data...</p>
          </div>
        </PageWrapper>

        <PageWrapper
          className={ classnames({ 'd-none': this.state.dataLoaded === false || this.props.viewPage !== 1 }) }>
          <p className={ 'font-weight-bold' }>
            Congratulations! You have completed the 21-day challenge!
          </p>

          <p>
            In the past 3 weeks,
            you completed { this.mappedCompletedSurveys() } out of 42.
          </p>

          <p className={ 'font-weight-bold' }>
            A few summaries of what you told us across the 3 weeks:
          </p>

          <ListGroup className={ 'mb-3' }>
            <ListGroupItem className={ 'list-group-item-success' }>
              Average mood: { this.mappedMood() }
            </ListGroupItem>
            <ListGroupItem>
              Average hours of sleep: { this.mappedSleepHours() }
            </ListGroupItem>
            <ListGroupItem className={ 'list-group-item-success' }>
              Average rating of sleep quality: { this.mappedSleepQuality() }
            </ListGroupItem>
            <ListGroupItem>
              Average rating of physical health: { this.mappedPhysicalHealth() }
            </ListGroupItem>
            <ListGroupItem className={ 'list-group-item-success' }>
              Number of days of moderate or high exercise: { this.mappedExercise() }
            </ListGroupItem>
          </ListGroup>
        </PageWrapper>

        <PageWrapper
          className={ classnames({ 'd-none': this.state.dataLoaded === false || this.props.viewPage !== 2 }) }>
          <p className={ 'font-weight-bold' }>
            Your alcohol use the last 3 weeks:
          </p>

          <ListGroup className={ 'mb-3' }>
            <ListGroupItem>
              Number of drinking days: <span className={'font-weight-bold'}>{ this.mappedDrinkingDays() }</span>
            </ListGroupItem>
            <ListGroupItem>
              Number of heavy drinking days: <span className={'font-weight-bold'}>{ this.mappedBingeDays() }</span><br/><br/>
              <span className={ 'text-muted' }>*4 or more drinks for females, 5 or more drinks for males</span>
            </ListGroupItem>
          </ListGroup>

          <div className={ classnames({ 'd-none': this.showNegativeInfluences() === false }) }>
            <p className={ 'font-weight-bold' }>
              In the last 3 weeks, you indicated your drinking negatively influenced your:
            </p>

            <ul>
              { this.mappedNegativeInfluences().map((item, index) => (
                <li key={ index }>{ item }</li>
              )) }
            </ul>

            <p>
              Drinking at lower levels is <span className={ 'font-weight-bold' }><u>less</u></span> likely
              to result in negative experiences.
            </p>
          </div>

          <p>
            Remember-you can always check out the <Link to={ '/tips' }>tips</Link> in this app for strategies
            to avoid negative experiences when drinking.
          </p>

          <hr className={ 'w-100 my-3' }/>

          <div className={ 'mt-3' }>
            <p className={ classnames({ 'd-none': this.showNegativeInfluences() === false }) }>
              Now that the 21-day expectancy challenge is ending,
              please consider ways in which negative experiences related to drinking may impact your future goals.
            </p>

            <p className={ classnames({ 'd-none': this.showNegativeInfluences() === false }) }>
              Create your own challenge for ways to minimize the likelihood of experiencing negative experiences while
              still having fun! You can always look through the tips stored in the app for more information.
            </p>

            <p className={ classnames({ 'd-none': this.showNegativeInfluences() === true }) }>
              Now that the 21-day expectancy challenge is ending, please consider ways in which your drinking
              experiences may impact your future goals.
            </p>

            <p className={ classnames({ 'd-none': this.showNegativeInfluences() === true }) }>
              Create your own challenge for ways to minimize the likelihood of experiencing negative experiences while
              still having fun! You can always look through the tips stored in the app for more information.
            </p>
          </div>
        </PageWrapper>
      </BurstSummary>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    summaryActions: bindActionCreators(summaryActions, dispatch),
  };
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BurstSummaryBlock);