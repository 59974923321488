import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';
import NumberInput from './NumberInput';
import * as responsesActions from '../../../actions/responsesActions';
import moment from "moment-timezone";

class GridNumberRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayText: '',
      containsPipe: false,
      showRow: true,
    };

    if (props.text.indexOf('{{pipe:') > -1) {
      this.state.containsPipe = true;
    }
  }

  componentDidMount() {
    this.evalShowIf();
    this.evalPipes();
  }

  componentDidUpdate() {
    this.evalShowIf();
    this.evalPipes();
  }

  evalPipes() {
    const re = /{{pipe:(.+?):(.+?)}}/m;
    let matches;
    let { text } = this.props;
    let replacement = '??';

    while ((matches = re.exec(text))) {
      switch (matches[1]) {
        case 'count':
          if (matches[2] in this.props.count) {
            replacement = this.props.count[matches[2]];
          }
          break;
        case 'participant':
          if (matches[2] in this.props.participant.data) {
            replacement = this.props.participant.data[matches[2]];
          }
          break;
        case 'getCalendarString':
          switch (matches[2]) {
            case 'participantWindow.closed_at':
              replacement = moment(this.props.participantWindow.closed_at).calendar();
              break;
            default:
              break;
          }
          break;
        case 'getMomentInFormat':
          const pcs = matches[2].split('|');
          switch (pcs[0]) {
            case 'participantWindow.closed_at':
              replacement = moment(this.props.participantWindow.closed_at).format(pcs[1]);
              break;
            default:
              break;
          }
          break;
        case 'response':
          try {
            replacement = this.props.responses[this.props.participantWindow.slug].responses[matches[2]].value;
          } catch (e) {}
          break;
        default:
          break;
      }

      text = text.replace(matches[0], replacement);
    }

    if (text !== this.state.displayText) {
      this.setState({
        displayText: text,
      });
    }
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showRow) {
      this.setState({
        showRow: show,
      });
    }
  }

  render() {
    const wrapperClasses = classnames(
      'mt-3',
      'taker__mqsa--row', {
        'd-none': !this.state.showRow,
      },
    );
    const textClasses = classnames(
      'taker__text', {
        taker__pipe: this.state.containsPipe,
      },
    );

    return (
      <Row className={ wrapperClasses }>
        <Col
          xs="12"
          md="6"
          className={ textClasses }
        >
          <ReactMarkdown>
            { this.state.displayText }
          </ReactMarkdown>
        </Col>
        <Col
          xs={ { size: 11, offset: 1 } }
          md={ { size: 6, offset: 0 } }
        >
          <NumberInput
            label={ this.props.gridAnswers.label }
            parent={ this.props.slug }
            slug={ this.props.gridAnswers.slug }
            responses={ this.props.responses }
            nonValue={ this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value }
            notShown={ this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value }
            participantWindow={ this.props.participantWindow }
            props={ this.props.gridAnswers.props }
            showElement={ this.props.showElement && this.state.showRow }
            showIf={ this.props.gridAnswers.show_if}
          />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridNumberRow);
