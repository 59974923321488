import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';

const PageWrapper = styled.div`
    height: 100%;
`;

class Tips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

    const params = queryString.parse(this.props.location.search);

    if (params.show === 'when-drinking') {
      this.state.activeTab = 2;
    }
  }

  show(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to="/tool-kit"/>;
    }

    const tips0 = <div
      className={ classnames({
        'd-none': !(this.state.activeTab === 0),
      }) }
    >
      Consider the following tips to reduce risk when drinking (click on each to see tips).
          <Button
            className={ 'w-100 mt-5' }
            outline
            color={'primary'}
            onClick={ () => this.show(1) }>Before drinking</Button>
          <Button
            className={ 'w-100 mt-3' }
            outline
            color={'primary'}
            onClick={ () => this.show(2) }>When drinking</Button>
          <Button
            className={ 'w-100 mt-3' }
            outline
            color={'primary'}
            onClick={ () => this.show(3) }>To avoid serious harm</Button>
    </div>;

    const tips1 = <img
        className={ classnames('img-fluid', {
          'd-none': !(this.state.activeTab === 1),
        }) }
        src={ '/img/before-drinking.png' }
        alt={ 'Before Drinking' }/>;

    const tips2 = <img
      className={ classnames('img-fluid', {
        'd-none': !(this.state.activeTab === 2),
      }) }
      src={ '/img/when-drinking.png' }
      alt={ 'When Drinking' }/>;

    const tips3 = <img
      className={ classnames('img-fluid', {
        'd-none': !(this.state.activeTab === 3),
      }) }
      src={ '/img/avoid-harm.png' }
      alt={ 'To avoid serious harm' }/>;

    const back = <div
      className={ classnames('my-3', {
        'd-none': this.state.activeTab === 0,
      }) }
    >
      <Button
        className={ 'w-100' }
        outline
        color={'primary'}
        onClick={ () => this.show(0) }>Back</Button>
    </div>;

    return (
      <PageWrapper>
        { tips0 }
        { tips1 }
        { tips2 }
        { tips3 }
        { back }
      </PageWrapper>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
)(Tips);
