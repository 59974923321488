import produce from 'immer';
import initialState from '../initialState';
import { FETCHING_PARTICIPANT, SET_DATA } from '../actions/participantActions';

export default function update(state = initialState.participant, action) {
  let newState;
  switch (action.type) {
    case FETCHING_PARTICIPANT:
      if (state.isFetching === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.isFetching = action.value;
      });
      return newState;

    case SET_DATA:
      if (state.data === action.data) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.data = action.data;
      });
      return newState;

    default:
      return state;
  }
}
