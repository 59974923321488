import produce from 'immer';
import initialState from '../initialState';
import {
  FETCHING_CUSTOM_MESSAGE,
  FETCHING_MESSAGES,
  SELECTING_DEFAULT_MESSAGES,
  SET_DEFAULT_MESSAGES,
  SET_LAST_BUILD,
  SET_MESSAGE,
  SET_MESSAGES,
  TRUING_UP,
  UPDATING_MESSAGES,
} from '../actions/interventionMessagesActions';

export default function update(state = initialState.interventionMessages, action) {
  let newState;
  switch (action.type) {
    case FETCHING_MESSAGES:
      if (state.isFetching === action.value) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.isFetching = action.value;
      });
      return newState;

    case FETCHING_CUSTOM_MESSAGE:
      if (state.isFetchingCustom === action.value) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.isFetchingCustom = action.value;
      });
      return newState;

    case TRUING_UP:
      if (state.isTruingUp === action.value) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.isTruingUp = action.value;
      });
      return newState;

    case SELECTING_DEFAULT_MESSAGES:
      if (state.isSelectingDefault === action.value) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.isSelectingDefault = action.value;
      });
      return newState;

    case UPDATING_MESSAGES:
      if (state.isUpdating === action.value) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.isUpdating = action.value;
      });
      return newState;

    case SET_MESSAGE:
      if (state.messages[action.key] === action.message) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.messages[action.key] = action.message;
      });
      return newState;

    case SET_MESSAGES:
      if (state.messages === action.messages) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.messages = action.messages;
      });
      return newState;

    case SET_DEFAULT_MESSAGES:
      if (state[action.period] === action.messages) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft[action.period] = action.messages;
      });
      return newState;

    case SET_LAST_BUILD:
      if (state.lastBuild === action.value) {
        return state;
      }
      newState = produce(state, (draft) => {
        draft.lastBuild = action.value;
      });
      return newState;

    default:
      return state;
  }
}
