import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as responsesActions from '../../../actions/responsesActions';
import React, { Component } from 'react';
import { Input } from 'reactstrap';
import classnames from 'classnames';

class SelectInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      showAnswer: true,
    };
  }

  componentDidMount() {
    let recordedVal = '';

    if (this.props.responses[this.props.participantWindow.slug].responses[this.props.name] !== undefined) {
      recordedVal = this.props.responses[this.props.participantWindow.slug].responses[this.props.name].value;
    }
    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value) {
      recordedVal = '';
    }

    if (recordedVal === this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value) {
      recordedVal = '';
    }

    if (recordedVal.length > 0 && recordedVal !== this.state.value) {
      this.setState({ value: recordedVal });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showElement === false && prevProps.showElement !== this.props.showElement) {
      this.answerHasHidden();
    }

    if (this.state.showAnswer === false && prevState.showAnswer !== this.state.showAnswer) {
      this.answerHasHidden();
    }

    if (this.props.showElement === true && this.state.showAnswer === true) {
      this.answerHasShown();
    }
  }

  answerHasHidden() {
    // unset value and record not_shown
    this.setState({ value: '' });

    const answer = this.props.answer;
    const element = this.props.element;
    const name = this.props.name;
    const value = this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value;
    const participantWindow = this.props.participantWindow.slug;

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  answerHasShown() {
    const answer = this.props.answer;
    const element = this.props.element;
    const name = this.props.name;
    const participantWindow = this.props.participantWindow.slug;
    let value = this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;

    if (this.state.value.length > 0) {
      value = this.state.value;
    }

    if (typeof this.props.responses[participantWindow].responses[name] !== 'undefined' &&
      value === this.props.responses[participantWindow].responses[name].value) {
      return;
    }

    this.props.responsesActions.recordResponse(
      answer, element, name, value, participantWindow
    );
  }

  calculatedValue() {
    if (typeof this.state.value === 'undefined') {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    if (this.state.value.length < 1) {
      return this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value;
    }

    return this.state.value;
  }

  handleChange = event => {
    const answer = this.props.answer;
    const element = this.props.element;
    const name = this.props.name;
    const value = event.target.value;
    const participantWindow = this.props.participantWindow.slug;

    this.setState(
      { value: value },
      () => {
        this.props.responsesActions.recordResponse(
          answer, element, name, value, participantWindow
        );
      }
    );
  };

  render() {
    const options = this.props.options.map((answer) => {
      const optionClasses = classnames(
        'show-if__limit', {
          'show-if__root': answer.showIfRoot,
        }
      );

      return (
        <option
          key={ answer.slug }
          className={ optionClasses }
          value={ answer.value }>
          { answer.label }
        </option>
      );
    });

    const wrapperClasses = classnames({
      'd-none': (!this.state.showAnswer),
    });

    return (
      <div className={ wrapperClasses }>
        <Input
          className={ 'response' }
          name={ this.props.name }
          type={ 'select' }
          value={ this.state.value }
          onChange={ this.handleChange }
        >
          <option
            value={ '' }>
            Select one...
          </option>
          { options }
        </Input>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectInput);