import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import InterventionMessage from './blocks/InterventionMessage';
import * as interventionMessagesActions from '../actions/interventionMessagesActions';
import ReactSwipe from 'react-swipe';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.topRef = React.createRef();
    this.reactSwipe = React.createRef();
    this.incrementActiveIndex = this.incrementActiveIndex.bind(this);
  }

  componentDidMount() {
  }

  incrementActiveIndex(increment) {
    const min = 0;
    const max = this.props.interventionMessages.messages
      .filter(item => typeof item !== 'string')
      .length - 1;

    let newIndex = this.state.activeIndex + increment;

    if (this.props.interventionMessages.messages.constructor !== Array) {
      return;
    }

    if (newIndex > max) {
      newIndex = min;
    }

    if (newIndex < min) {
      newIndex = max;
    }

    this.setState({
      activeIndex: newIndex,
    });
  }

  setActiveIndex(index) {
    const min = 0;
    const max = this.props.interventionMessages.messages
      .filter(item => typeof item !== 'string')
      .length - 1;

    let newIndex = index;

    if (this.props.interventionMessages.messages.constructor !== Array) {
      return;
    }

    if (newIndex > max) {
      newIndex = min;
    }

    if (newIndex < min) {
      newIndex = max;
    }

    this.setState({
      activeIndex: newIndex,
    });
  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to="/tool-kit"/>;
    }

    if (typeof this.props.surveys.schedule.schedule.burst_1.survey_1 === 'undefined') {
      return <Redirect to="/settings"/>;
    }

    if (['INTERVENTION-T', 'INTERVENTION-1', 'INTERVENTION-2', 'INTERVENTION-3', 'INTERVENTION-4', 'INTERVENTION-5', 'INTERVENTION-6', 'INTERVENTION-7', 'INTERVENTION-8'].indexOf(this.props.participant.data.rand_code) < 0) {
      return <Redirect to="/tool-kit"/>;
    }

    const swipeOptions = {
      continuous: true,
      startSlide: this.state.activeIndex,
      transitionEnd: (index) => {
        this.setActiveIndex(index);
        this.topRef.current.scrollIntoView(true);
      }
    };

    let interventionMessages = [];


    if (this.props.interventionMessages.messages.constructor === Array) {
      interventionMessages = this.props.interventionMessages.messages
        .filter((item) => typeof item !== 'string')
        .filter((item) => {
          return typeof item.props.helpful !== undefined && item.props.helpful === true;
        })
        .map((item, index) => (
          <div key={ index }>
            <InterventionMessage
              message={ item.message }
              active={ index === this.state.activeIndex }
              helpful={ true }
              rating={ 4 }
              templateId={ item.template_id }
              msgIndex={ index }
              incrementActiveIndex={ this.incrementActiveIndex }
            />
          </div>
        ));
    }

    if (interventionMessages.length > 0) {
      return (
        <div
          style={ {
            marginTop: '-1rem',
            paddingTop: '1rem',
          } }
          ref={ this.topRef }>
          <p className={ 'text-center' }>
            Helpful message { this.state.activeIndex + 1 } of { interventionMessages.length }
            <br/>(swipe to see more)
          </p>
          <hr/>
          <ReactSwipe
            ref={ this.reactSwipe }
            childCount={ interventionMessages.length }
            swipeOptions={ swipeOptions }
            setActiveIndex={ this.setActiveIndex }
          >
            { interventionMessages }
          </ReactSwipe>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    interventionMessagesActions: bindActionCreators(interventionMessagesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Messages);
