import moment from 'moment-timezone';
import axios from 'axios';

export const FETCHING_SCHEDULE = 'FETCHING_SCHEDULE';
export const FETCHING_SURVEY = 'FETCHING_SURVEY';
export const SET_FUTURESURVEYS = 'SET_FUTURESURVEYS';
export const SET_OPENSURVEYS = 'SET_OPENSURVEYS';
export const SET_PASTSURVEYS = 'SET_PASTSURVEYS';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';

export const setFutureSurveys = (schedule) => {
  const now = moment();
  const futureSurveys = [];

  for (const burst in schedule) {
    if (schedule.hasOwnProperty(burst)) {
      const burstSchedule = schedule[burst];
      for (const survey in burstSchedule) {
        if (burstSchedule.hasOwnProperty(survey)) {
          if (now < moment(burstSchedule[survey].opened_at)) {
            futureSurveys.push([burst, survey, burstSchedule[survey].slug]);
          }
        }
      }
    }
  }

  return { type: SET_FUTURESURVEYS, surveys: futureSurveys };
};

export const setOpenSurveys = (schedule, responses) => {
  const now = moment();
  const openSurveys = [];

  for (const burst in schedule) {
    if (schedule.hasOwnProperty(burst)) {
      const burstSchedule = schedule[burst];
      for (const survey in burstSchedule) {
        if (burstSchedule.hasOwnProperty(survey)) {
          let opened = false;
          let closed = false;
          let completed = false;

          if (now > moment(burstSchedule[survey].opened_at)) {
            opened = true;
          }

          if (burstSchedule[survey].closed_at !== null && now.diff(moment(burstSchedule[survey].closed_at), 'minutes') > 5) {
            closed = true;
          }

          if (burstSchedule[survey].completed_at !== null) {
            completed = true;
          }

          if (typeof responses[burstSchedule[survey].slug] !== 'undefined'
            && responses[burstSchedule[survey].slug].meta.completed_at !== null) {
            completed = true;
          }

          if (opened === true && closed === false && completed === false) {
            openSurveys.push([burst, survey, burstSchedule[survey].slug, burstSchedule[survey].props]);
          }
        }
      }
    }
  }

  return { type: SET_OPENSURVEYS, surveys: openSurveys };
};

export const setPastSurveys = (schedule, responses) => {
  const now = moment();
  const pastSurveys = [];

  for (const burst in schedule) {
    if (schedule.hasOwnProperty(burst)) {
      const burstSchedule = schedule[burst];
      for (const survey in burstSchedule) {
        if (burstSchedule.hasOwnProperty(survey)) {
          let closed = false;
          let completed = false;

          if (burstSchedule[survey].closed_at !== null && now.diff(moment(burstSchedule[survey].closed_at), 'minutes') > 5) {
            closed = true;
          }

          if (burstSchedule[survey].completed_at !== null) {
            completed = true;
          }

          if (typeof responses[burstSchedule[survey].slug] !== 'undefined'
            && responses[burstSchedule[survey].slug].meta.completed_at !== null) {
            completed = true;
          }

          if (closed === true || completed === true) {
            pastSurveys.push([burst, survey, burstSchedule[survey].slug, burstSchedule[survey].props]);
          }
        }
      }
    }
  }

  return { type: SET_PASTSURVEYS, surveys: pastSurveys };
};

export const updateSurveySchedule = (data) => {
  const map = {};
  const schedule = {};
  let burst = 0;
  let burst_survey = 0;

  for (let i = 0; i < data.length; i++) {
    // if (data[i].survey === 'p3AQ3W') {
    //   // ignore the screening survey for now
    //   continue;
    // }

    if (data[i].props.burst !== burst) {
      burst = data[i].props.burst;
      burst_survey = 0;
      schedule[`burst_${ burst }`] = {};
    }

    if (data[i].props.burst_survey !== burst_survey) {
      burst_survey = data[i].props.burst_survey;
    }

    schedule[`burst_${ burst }`][`survey_${ burst_survey }`] = data[i];
    map[data[i].slug] = data[i];
  }

  return {
    type: UPDATE_SCHEDULE, schedule, map, lastFetch: moment().unix(),
  };
};

export const updateSurveyData = (data) => {
  const survey = data.survey.survey.slug;
  return {
    type: UPDATE_SURVEY, slug: survey, data, lastFetch: moment().unix(),
  };
};

export const fetchSchedule = (accessToken) => {
  const url = `${ process.env.REACT_APP_API_URL }/participant-windows`;
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ accessToken }`,
    },
  };

  return (dispatch, getState) => {
    dispatch({
      type: FETCHING_SCHEDULE,
      value: true,
    });

    axios.get(url, config)
      .then((res) => {
        dispatch(updateSurveySchedule(res.data.data));
      })
      .catch((error) => {
        // error handling
        const log = {
          error: JSON.stringify({
            app: 'uwamps_pwa',
            altpid: getState().participant.data.altpid || 'not set',
            endpoint: error.config.url,
            message: error.message,
            origin: 'surveysActions.fetchSchedule',
          })
        };

        axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: FETCHING_SCHEDULE,
          value: false,
        });
      });
  };
};

export const fetchSurveyData = (survey, accessToken) => {
  const url = `${ process.env.REACT_APP_API_URL }/survey-taker/${ survey }`;
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ accessToken }`,
    },
  };

  return (dispatch, getState) => {
    dispatch({
      type: FETCHING_SURVEY,
      value: true,
    });

    axios.get(url, config)
      .then((res) => {
        dispatch(updateSurveyData(res.data.data));
      })
      .catch((error) => {
        // error handling
        const log = {
          error: JSON.stringify({
            app: 'uwamps_pwa',
            altpid: getState().participant.data.altpid || 'not set',
            endpoint: error.config.url,
            message: error.message,
            origin: 'surveysActions.fetchSurveyData',
            survey: survey,
          })
        };

        axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: FETCHING_SURVEY,
          value: false,
        });
      });
  };
};
