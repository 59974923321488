import produce from 'immer';
import initialState from '../initialState';
import {
  BEGIN_COLLECTION,
  END_COLLECTION,
  RECORD_RESPONSE,
  UPDATE_PAGE,
  UPLOAD_SUCCESS,
  UPLOADING_RESPONSES,
} from '../actions/responsesActions';

export default function update(state = initialState.responses, action) {
  let newState;
  switch (action.type) {
    case BEGIN_COLLECTION:
      if (state.hasOwnProperty(action.participantWindow)) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft[action.participantWindow] = {
          meta: action.meta,
          responses: action.responses,
        };
      });

      return newState;

    case END_COLLECTION:
      newState = produce(state, (draft) => {
        draft[action.participantWindow].meta.completed_at = action.completed_at;
        draft[action.participantWindow].meta.page = action.page;
        draft[action.participantWindow].meta.percent = action.percent;
      });

      return newState;

    case RECORD_RESPONSE:
      if (typeof state[action.participantWindow].responses[action.response.name] !== 'undefined' &&
        state[action.participantWindow].responses[action.response.name].value === action.response.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft[action.participantWindow].meta.active_at = action.active_at;
        draft[action.participantWindow].responses[action.response.name] = action.response;
      });

      return newState;

    case UPDATE_PAGE:
      const greaterPage = Math.max(action.page, state[action.participantWindow].meta.page);
      const greaterPercent = Math.max(action.percent, state[action.participantWindow].meta.percent);

      newState = produce(state, (draft) => {
        draft[action.participantWindow].meta.active_at = action.active_at;
        draft[action.participantWindow].meta.page = greaterPage;
        draft[action.participantWindow].meta.percent = greaterPercent;
      });

      return newState;

    case UPLOAD_SUCCESS:
      newState = produce(state, (draft) => {
        draft[action.participantWindow].meta.uploaded_at = action.uploaded_at;
        draft[action.participantWindow].responses = {};
      });

      return newState;

    case UPLOADING_RESPONSES:
      if (state[action.participantWindow].meta.isUploading === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft[action.participantWindow].meta.isUploading = action.value;
      });
      return newState;

    default:
      return state;
  }
}
