import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import * as responsesActions from "../actions/responsesActions";
import * as surveysActions from "../actions/surveysActions";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toTaker: false,
    };
  }

  handleStartSurvey() {
    this.props.responsesActions.beginCollection(this.props.surveys.openSurveys[0][2]);

    this.setState({
      toTaker: true,
    });
  }

  render() {
    if (this.state.toTaker === true && this.props.surveys.openSurveys.length > 0) {
      const takerLinkStr = `/taker/${ this.props.surveys.openSurveys[0][2] }`;
      return <Redirect to={ takerLinkStr }/>;
    }

    const loggedIn = this.props.settings.auth.signedIn;
    let loggedInIntervention = false;
    let onboardingComplete = false;

    if (loggedIn &&
      typeof this.props.participant.data.rand_code === 'string' &&
      this.props.participant.data.rand_code.indexOf('INTERVENTION') > -1) {
      loggedInIntervention = true;
    }

    try {
      if (this.props.surveys.schedule.schedule.burst_1.survey_0.completed_at !== null) {
        onboardingComplete = true;
      }
    } catch (e) {
    }

    let surveysLink = <div></div>;
    if (loggedIn && this.props.surveys.openSurveys.length > 0 &&
      this.props.surveys.openSurveys[0][1] !== 'survey_0') {
      surveysLink = (
        <Button
          color="primary"
          block
          onClick={ () => {
            this.handleStartSurvey();
          } }
        >
          Daily Survey
        </Button>
      );
    }

    let messagesLink = <div></div>;
    if (loggedInIntervention && onboardingComplete) {
      messagesLink = <Link
        className={ 'btn btn-success btn-block' }
        aria-label={ 'Daily Messages' }
        to="/messages"
      >Daily Messages</Link>
    }

    return (
      <div
        style={ {
          minHeight: '100%',
        } }
        className={ 'd-flex flex-column justify-content-center align-items-center' }>
        <img
          className={ 'mb-5 w-75' }
          src={ '/img/AMPS_logo_trimmed.png' }
          alt={ 'AMPS Logo' }/>

        <div className={ 'w-100' }>
          { surveysLink }

          { messagesLink }

          <Link
            className={ 'btn btn-info btn-block' }
            aria-label={ 'Contact Us' }
            to="/contact-us"
          >Contact Us</Link>
        </div>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
    surveysActions: bindActionCreators(surveysActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
