import { combineReducers } from 'redux';
import calculatorsReducer from './calculatorsReducer';
import interventionMessagesReducer from './interventionMessagesReducer';
import participantReducer from './participantReducer';
import photosReducer from './photosReducer';
import responsesReducer from './responsesReducer';
import settingsReducer from './settingsReducer';
import summaryReducer from './summaryReducer';
import surveysReducer from './surveysReducer';
import videosReducer from './videosReducer';
import { purgeStoredState } from 'redux-persist';
import { persistConfig } from "../configureStore";
import Cookies from "js-cookie";

const appReducer = combineReducers({
  calculators: calculatorsReducer,
  interventionMessages: interventionMessagesReducer,
  participant: participantReducer,
  photos: photosReducer,
  responses: responsesReducer,
  settings: settingsReducer,
  summaries: summaryReducer,
  surveys: surveysReducer,
  videos: videosReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    purgeStoredState(persistConfig);

    Cookies.remove('token');
    Cookies.remove('participant');

    state = undefined
  }

  return appReducer(state, action)
};

export default rootReducer;
