import { connect } from 'react-redux';
import React, { Component } from 'react';
import { VictoryAxis, VictoryBar, VictoryLabel } from 'victory';

class SummaryChartSleepMood extends Component {
  getStyles() {
    const BLUE_COLOR = '#00a3de';
    const GRAY_COLOR = '#7b97a4';
    const GRAY_LT_COLOR = '#d8e0e4';
    const GREEN_COLOR = '00de3b';
    const RED_COLOR = '#7c270b';
    const WHITE_COLOR = '#ffffff';

    return {
      parent: {
        background: WHITE_COLOR,
        boxSizing: 'border-box',
        display: 'inline',
        padding: 0,
        maxWidth: '100%',
        height: 'auto'
      },
      title: {
        textAnchor: 'start',
        verticalAnchor: 'end',
        fill: GRAY_COLOR,
        fontFamily: 'inherit',
        fontSize: '18px',
      },
      labelNumber: {
        textAnchor: 'middle',
        fill: WHITE_COLOR,
        fontFamily: 'inherit',
        fontSize: '14px'
      },

      // INDEPENDENT AXIS
      axisDays: {
        axis: {
          stroke: GRAY_COLOR,
          strokeWidth: 2
        },
        axisLabel: {
          fill: GRAY_COLOR,
          fontFamily: 'inherit',
          fontSize: 16,
          padding: 40
        },
        grid: {
          stroke: GRAY_LT_COLOR,
          strokeDasharray: [10, 5],
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        },
        ticks: {
          size: 10,
          stroke: GRAY_COLOR,
          strokeWidth: 1
        },
        tickLabels: {
          fill: GRAY_COLOR,
          fontFamily: 'inherit',
          fontSize: 16
        }
      },

      // DATA SET ONE
      axisOne: {
        axis: { strokeWidth: 0 },
        axisLabel: {
          fill: BLUE_COLOR,
          fontFamily: 'inherit',
          fontSize: 16,
        },
        grid: {
          stroke: (tick) => tick > 0 ? GRAY_LT_COLOR : 'transparent',
          strokeDasharray: [10, 5],
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        },
        ticks: { strokeWidth: 0 },
        tickLabels: {
          fill: BLUE_COLOR,
          fontFamily: 'inherit',
          fontSize: 16,
        }
      },
      labelOne: {
        fill: BLUE_COLOR,
        fontFamily: 'inherit',
        fontSize: 12,
      },
      lineOne: {
        data: { stroke: BLUE_COLOR, strokeWidth: 4.5 }
      },
      axisOneCustomLabel: {
        fill: BLUE_COLOR,
        fontFamily: 'inherit',
        fontWeight: 300,
        fontSize: 21
      },

      // DATA SET TWO
      axisTwo: {
        axis: { strokeWidth: 0 },
        axisLabel: {
          fill: GREEN_COLOR,
          fontFamily: 'inherit',
          fontSize: 16,
        },
        ticks: { strokeWidth: 0 },
        tickLabels: {
          fill: GREEN_COLOR,
          fontFamily: 'inherit',
          fontSize: 16
        }
      },
      labelTwo: {
        textAnchor: 'end',
        fill: RED_COLOR,
        fontFamily: 'inherit',
        fontSize: 12,
      },
      lineTwo: {
        data: { stroke: RED_COLOR, strokeWidth: 4.5 }
      },
      axisTwoCustomLabel: {
        fill: BLUE_COLOR,
        fontFamily: 'inherit',
        fontWeight: 300,
        fontSize: 21
      },

      // HORIZONTAL LINE
      lineThree: {
        data: { stroke: '#e95f46', strokeWidth: 2 }
      }
    };
  }

  getDataSet() {
    return this.props.dataSet;
  }

  getBarColor(val) {
    switch (val) {
      case 5:
        return '#1f833d';
      case 4:
        return '#82b019';
      case 3:
        return '#fae613';
      case 2:
        return '#ee7200';
      case 1:
        return '#d51c2a';
      default:
        return '#30BCED'
    }
  }

  render() {
    const styles = this.getStyles();
    const dataSet = this.getDataSet();

    return (
      <div>
        <svg
          style={ styles.parent }
          viewBox={ '0 0 450 350' }>
          <VictoryLabel
            x={ 25 }
            y={ 24 }
            style={ styles.title }
            text={ this.props.labelText }
          />

          <g>
            {/* Add shared independent axis */ }
            <VictoryAxis
              domainPadding={ 20 }
              label="Day of the Week"
              scale="linear"
              standalone={ false }
              style={ styles.axisDays }
              tickValues={ this.props.dependantTicks }
            />

            {/*
             Add the dependent axis for the first data set.
             Note that all components plotted against this axis will have the same y domain
             */ }
            <VictoryAxis
              dependentAxis
              domain={ [0, 5] }
              standalone={ false }
              style={ styles.axisOne }
              tickFormat={(t) => {
                switch (t){
                  case 1:
                    return ['very', 'bad'];
                  case 2:
                    return ['bad'];
                  case 3:
                    return ['mod'];
                  case 4:
                    return ['good'];
                  case 5:
                    return ['very', 'good'];
                  default:
                    return [''];
                }
              }}
            />

            {/* data set one */ }
            <VictoryBar
              data={ dataSet }
              domain={ { y: [0, 5] } }
              domainPadding={ 20 }
              labels={(d) => d.y === 0.001 ? 'NR' : ''}
              scale={ { x: 'linear', y: 'linear' } }
              standalone={ false }
              style={ {
                data: {
                  fill: (d) => this.getBarColor(d._y),
                  stroke: (d) => this.getBarColor(d._y),
                  fillOpacity: (d) => d._y < 1 ? 0 : 0.7,
                  strokeWidth: (d) => d._y < 1 ? 0 : 2,
                },
                labels: {
                  fill: styles.title.fill
                }
              } }
            />
          </g>
        </svg>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
)(SummaryChartSleepMood);