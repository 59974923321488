import React, { Component } from 'react';
import classnames from 'classnames';

class TakerPage extends Component {
  componentDidUpdate() {
    this.props.updatePageHeight(this.props.pageNumber, this.takerPageElement.clientHeight);
  }

  render() {
    const wrapperClasses = classnames(
      {
        'd-none': this.props.page !== this.props.currentPage,
      },
    );

    return (
      <div
        className={ wrapperClasses }
        ref={ takerPageElement => this.takerPageElement = takerPageElement }
      >
        { this.props.elements }
      </div>
    );
  }
}

export default TakerPage;
