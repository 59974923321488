import React, { Component } from 'react';

export default class ContactUs extends Component {
  render() {
    return (
      <div>
        <h2>Contact Us</h2>

        <div class="mt-4">
          <div><span className="font-weight-bold">Contact Us</span> (during regular business hours)</div>
          <div className={ 'pl-3' }>
            <div className={ 'my-2' }>
              <a href="mailto:amps@uw.edu">amps@uw.edu</a><br/>
            </div>
            <div className={ 'my-2' }>
              <a href="tel:206-221-6390">206-221-6390</a>
            </div>
            <div className={ 'my-2' }>
              <a href={ 'https://sites.uw.edu/amps/' }>https://sites.uw.edu/amps/</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
