import React, { Component } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';

class TakerButton extends Component {
  render() {
    const buttonClasses = classnames(
      'btn-block', {
        'd-none': !this.props.visible,
      },
    );

    return (
      <Button
        className={ buttonClasses }
        outline
        color={ this.props.color }
        disabled={ this.props.disabled }
        onClick={ this.props.handleClick }
      >
        { this.props.label }
      </Button>
    );
  }
}

export default TakerButton;
