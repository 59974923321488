import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Toolkit extends Component {
  shouldComponentUpdate(nextProps) {
    return false;
  }

  render() {
    const loggedIn = this.props.settings.auth.signedIn;
    let burstScheduled = false;
    let loggedInIntervention = false;

    if (typeof this.props.surveys.schedule.schedule.burst_1 !== 'undefined' &&
      this.props.surveys.schedule.schedule.burst_1['survey_1'] !== 'undefined') {
      burstScheduled = true;
    }

    if (loggedIn &&
      typeof this.props.participant.data.rand_code === 'string' &&
      this.props.participant.data.rand_code.indexOf('INTERVENTION') > -1) {
      loggedInIntervention = true;
    }

    return (
      <div className={'mb-3'}>
        <h2>Tool Kit</h2>
        <h5>
          Below are resources for you to visit at your leisure.
        </h5>
        <Nav vertical>
          {
            loggedInIntervention && (
              <div>
                <NavLink
                  className={'pb-0 pl-1'}
                  tag={ Link }
                  to="/bac-chart"
                >
                  <FontAwesomeIcon
                    icon={ ['fal', 'chart-bar'] }
                    transform="grow-6"
                  />
                  <h5 className={'d-inline-block ml-3'}>BAC Chart</h5>
                </NavLink>
                <p className={'text-muted'}
                   style={{paddingLeft: '2.4rem',}}>
                  A personalized BAC chart for different drinks and hours
                </p>
              </div>
            )
          }

          {
            loggedInIntervention && (
              <div>
                <NavLink
                  className={'pb-0 pl-1'}
                  tag={ Link }
                  to="/calculators"
                >
                  <FontAwesomeIcon
                    icon={ ['fal', 'calculator'] }
                    transform="grow-6"
                  />
                  <h5 className={'d-inline-block ml-3'}>Calculators</h5>
                </NavLink>
                <p className={'text-muted'}
                   style={{paddingLeft: '2.4rem',}}>
                  Estimate BAC and how long it takes to get back to 0.00% and calculate # of standard drinks
                </p>
              </div>
            )
          }

          <div>
            <NavLink
              className={'pb-0 pl-1'}
              tag={ Link }
              to="/standard-drinks"
            >
              <FontAwesomeIcon
                icon={ ['fal', 'beer'] }
                transform="grow-6"
              />
              <h5 className={'d-inline-block ml-3'}>Standard Drinks</h5>
            </NavLink>
            <p className={'text-muted'}
               style={{paddingLeft: '2.4rem',}}>
              A quick guide for # of standard drinks based on type of drink
            </p>
          </div>

          {
            loggedInIntervention && (
              <div>
                <NavLink
                  className={ classnames('pb-0 pl-1', { disabled: burstScheduled === false }) }
                  tag={ Link }
                  to="/helpful-messages"
                >
                  <FontAwesomeIcon
                    icon={ ['fal', 'thumbs-up'] }
                    transform="grow-6"
                  />
                  <h5 className={'d-inline-block ml-3'}>Helpful Messages</h5>
                </NavLink>
                <p className={'text-muted'}
                   style={{paddingLeft: '2.4rem',}}>
                  All the messages you rated as helpful
                </p>
              </div>
            )
          }

          {
            loggedInIntervention && (
              <div>
                <NavLink
                  className={'pb-0 pl-1'}
                  tag={ Link }
                  to="/tips"
                >
                  <FontAwesomeIcon
                    icon={ ['fal', 'lightbulb'] }
                    transform="grow-6"
                  />
                  <h5 className={'d-inline-block ml-3'}>Tips & Strategies</h5>
                </NavLink>
                <p className={'text-muted'}
                   style={{paddingLeft: '2.4rem',}}>
                  Strategies for reducing risk when drinking
                </p>
              </div>
            )
          }

          {
            loggedIn && (
              <div>
                <NavLink
                  className={ classnames('pb-0 pl-1', { disabled: burstScheduled === false }) }
                  tag={ Link }
                  to="/survey-completion"
                >
                  <FontAwesomeIcon
                    icon={ ['fal', 'check-square'] }
                    transform="grow-6"
                  />
                  <h5 className={'d-inline-block ml-3'}>Survey Completion</h5>
                </NavLink>
                <p className={'text-muted'}
                   style={{paddingLeft: '2.4rem',}}>
                  Track # of surveys completed
                </p>
              </div>
            )
          }

          <div>
            <NavLink
              className={'pb-0 pl-1'}
              tag={ Link }
              to="/resources"
            >
              <FontAwesomeIcon
                icon={ ['fal', 'phone'] }
                transform="grow-6"
              />
              <h5 className={'d-inline-block ml-3'}>Contact Us & Resources</h5>
            </NavLink>
          </div>
        </Nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
)(Toolkit);
