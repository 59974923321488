import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.closeNavbar = this.closeNavbar.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.location.pathname !== nextProps.location.pathname;
  }

  closeNavbar() {
    if (this.state.isOpen === true) {
      this.toggle();
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    let settingsIcon = (
      <FontAwesomeIcon
        icon={ ['fal', 'cog'] }
        transform="grow-6"
      />
    );

    switch (this.props.location.pathname) {
      case '/settings':
        settingsIcon = (
          <FontAwesomeIcon
            icon={ ['fas', 'cog'] }
            transform="grow-6"
          />
        );
        break;
      default:
        break;
    }
    return (
      <Navbar
        className="fixed-top"
        color="dark"
        dark
        expand="md"
      >
        <NavbarBrand
          aria-label={ 'Home' }
          tag={ Link }
          to="/"
          onClick={ this.closeNavbar }
        >

          <img
            src={ "/img/AMPS_logo_trimmed_inverted.png" }
            width="35"
            height="25"
            alt={ 'AMPS Logo' }/>
        </NavbarBrand>

        <Nav
          className="mr-auto"
          navbar
        >

          <NavItem className="d-none">
            <NavLink
              aria-label={ 'Survey' }
              tag={ Link }
              to="/survey"
              onClick={ this.closeNavbar }
            >

              Survey
            </NavLink>
          </NavItem>

          <NavItem className="d-none">
            <NavLink
              aria-label={ 'Weekly Summary' }
              tag={ Link }
              to="/weekly-summary"
              onClick={ this.closeNavbar }
            >

              Weekly Summary
            </NavLink>
          </NavItem>

          <UncontrolledDropdown
            className="d-none"
            nav
            inNavbar
          >
            <DropdownToggle
              nav
              caret
            >
              Tool Kit
            </DropdownToggle>
            <DropdownMenu direction="left">
              <DropdownItem
                aria-label={ 'Calculators' }
                tag={ Link }
                to="/calcs"
                onClick={ this.closeNavbar }
              >
                Calculators
              </DropdownItem>
              <DropdownItem
                aria-label={ 'Money' }
                tag={ Link }
                to="/money"
                onClick={ this.closeNavbar }
              >
                Money
              </DropdownItem>
              <DropdownItem
                aria-label={ 'Photos' }
                tag={ Link }
                to="/photos"
                onClick={ this.closeNavbar }
              >
                Photos
              </DropdownItem>
              <DropdownItem
                aria-label={ 'Resources' }
                tag={ Link }
                to="/resources"
                onClick={ this.closeNavbar }
              >
                Resources
              </DropdownItem>
              <DropdownItem
                aria-label={ 'Videos' }
                tag={ Link }
                to="/videos"
                onClick={ this.closeNavbar }
              >
                Videos
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <Nav
          className="ml-auto"
          navbar
        >
          <NavItem className="d-none d-md-block">
            <NavLink
              aria-label={ 'Settings' }
              tag={ Link }
              to="/settings"
              onClick={ this.closeNavbar }
            >
              Settings
            </NavLink>
          </NavItem>
          <NavItem className="d-block d-md-none">
            <NavLink
              aria-label={ 'Settings' }
              tag={ Link }
              to="/settings"
            >
              { settingsIcon }
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default withRouter(Header);
