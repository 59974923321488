import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';
import GridCheckboxRow from './GridCheckboxRow';
import * as responsesActions from '../../../actions/responsesActions';

class GridCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containsPipe: false,
      showElement: true,
    };

    if (props.elements[0].text.indexOf('{{pipe:') > -1) {
      this.state.containsPipe = true;
    }
  }

  componentDidMount() {
    this.evalShowIf();
  }

  componentDidUpdate() {
    this.evalShowIf();
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.elements[0].show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showElement) {
      this.setState({
        showElement: show,
      });
    }
  }

  render() {
    const wrapperClasses = classnames(
      'mt-3',
      'taker__element',
      'taker__mqsa', {
        'd-none': !this.state.showElement,
      },
    );
    const textClasses = classnames(
      'taker__text', {
        taker__pipe: this.state.containsPipe,
      },
    );
    const answers = this.props.elements[0].answers.answer_group_0;
    const labels = answers.map((answer, index) => (
      <Col
        key={ index }
        className="text-center"
      >
        { answer.label }
      </Col>
    ));
    const rows = this.props.elements.filter((row, index) => (index !== 0)).map(row => (
      <GridCheckboxRow
        key={ row.slug }
        gridAnswers={ answers }
        participantWindow={ this.props.participantWindow }
        showElement={ this.state.showElement }
        { ...row }
      />
    ));

    return (
      <div className={ wrapperClasses }>
        <Row>
          <Col
            xs="11"
            className={ textClasses }
          >
            <ReactMarkdown>
              { this.props.elements[0].text }
            </ReactMarkdown>
          </Col>
        </Row>

        {/* labels to be shown on medium screen and up */ }
        <Row className="d-none d-md-flex">
          <Col md={ { size: 8, offset: 4 } }>
            <Row>
              <Col md={ { size: 11, offset: 1 } }>
                <Row>
                  { labels }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* each row is a grid question with all of the grid answers */ }
        { rows }

        <hr/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridCheckbox);
