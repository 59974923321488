import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import FileBase64 from 'react-file-base64';
import { Redirect } from 'react-router-dom';
import * as photosActions from '../actions/photosActions';


class Photos extends Component {
  getFiles(files) {
    this.props.photosActions.savePhoto(files);
  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to="/tool-kit"/>;
    }

    const images = this.props.photos.uploaded.map((item, index) => (
      <div
        key={ index }
        className="col-xs-12 col-sm-6 col-lg-3 mt-3"
      >
        <div className="card">
          <img
            className="card-img-top"
            src={ item.base64 }
            alt="Card cap"
          />
          <div className="card-body">
            <p className="card-text">{ item.name }</p>
          </div>
        </div>
      </div>
    ));

    return (
      <div>
        <h2>Photos</h2>

        <form>
          <div className="form-group">
            <label>Select images to upload.</label>
            <div>
              <FileBase64
                className="form-control-file"
                multiple
                onDone={ this.getFiles.bind(this) }
              />
            </div>
          </div>
        </form>

        <div className="row">
          { images }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    photosActions: bindActionCreators(photosActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Photos);
