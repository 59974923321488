import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

class GridRadioInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.checkedVal,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== this.props.checkedVal;
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.checkedVal) {
      this.setState({ value: this.props.checkedVal });
    }
  }

  render() {
    return (
      <FormGroup check className={'mb-3 mb-md-0'}>
        <Label check>
          <Input
            className="input response position-relative"
            name={ this.props.name }
            type="radio"
            value={ this.props.value }
            checked={ this.props.value === this.state.value }
            onChange={ this.props.handleChange }
          />
          <span className="d-inline d-md-none ml-1">
              { this.props.label }
          </span>
        </Label>
      </FormGroup>
    );
  }
}

export default GridRadioInput;
