import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as calculatorsActions from '../actions/calculatorsActions';
import React, { Component } from 'react';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import style from 'styled-components';

const ChartGrid = style.div`
    display: grid;
    grid-template-columns: 2.5rem 1fr  1fr 1fr 1fr;
    grid-template-rows:    1fr    1fr  1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "......... ........... column-label   column-label   column-label"
      "......... ........... column-count-1 column-count-2 column-count-3"
      "row-label row-count-1 bac-1-1        bac-1-2        bac-1-3"
      "row-label row-count-2 bac-2-1        bac-2-2        bac-2-3"
      "row-label row-count-3 bac-3-1        bac-3-2        bac-3-3"
      "row-label row-count-4 bac-4-1        bac-4-2        bac-4-3"
      "row-label row-count-5 bac-5-1        bac-5-2        bac-5-3";
    text-align: center;
`;


class BacChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minHour: 1,
      minDrink: 1,
    }
  }

  bacChange = event => {
    const name = event.target.name;
    const value = Number(event.target.value);

    if (event.target.type === 'checkbox' || event.target.type === 'radio') {
      if (!event.target.checked) {
        return;
      }
    }

    this.props.calculatorsActions.setBac(name, value);
  };

  increment(key, value) {
    let newValue = this.state[key] + (value);

    if (newValue < 0) {
      newValue = 0;
    }

    if (key === 'minHour' && newValue > 22) {
      newValue = 22;
    }

    if (key === 'minDrink' && newValue > 21) {
      newValue = 21;
    }

    this.setState({
      [key]: newValue,
    })
  }

  setBac(drinks, hours) {
    const sex = this.props.calculators.bac.sex;
    const weight = this.props.calculators.bac.weight;
    const gf = this.props.calculators.bac.gf[sex];
    let bac = 0;

    if (sex === null || weight === null) {
      return '??';
    }

    if (drinks > 0 && weight > 0) {
      bac = ((drinks / 2) * (gf / weight)) - (0.016 * hours);
    }

    if (bac < 0) {
      bac = 0;
    }

    if (isNaN(bac)) {
      return '??';
    }

    return bac.toFixed(2);
  }

  render() {
    return (
      <div>
        <h2>BAC Chart</h2>

        <p>
          Below you can personalize a BAC chart based on sex, weight, and different # of drinks that are consumed over
          various amounts of time.
        </p>

        <Row
          className={ 'mb-4' }
          form>
          <Col className={ 'align-self-center' }>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="sex"
                  value="0"
                  defaultChecked={ this.props.calculators.bac.sex === 0 }
                  onChange={ (event) => this.bacChange(event) }
                />
                Male
              </Label>
            </FormGroup>
            <FormGroup
              check
              disabled>
              <Label check>
                <Input
                  type="radio"
                  name="sex"
                  value="1"
                  defaultChecked={ this.props.calculators.bac.sex === 1 }
                  onChange={ (event) => this.bacChange(event) }
                /> Female</Label>
            </FormGroup>
          </Col>

          <Col className={ 'align-self-center' }>
            <InputGroup>
              <Input
                type="number"
                name="weight"
                step="5"
                min="0"
                defaultValue={ this.props.calculators.bac.weight }
                onChange={ (event) => this.bacChange(event) }
              />
              <InputGroupAddon
                addonType="append">Weight</InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>

        <ChartGrid className={ 'mt-3' }>

          <div style={ { gridArea: 'column-label' } }>
            <div className="input-group">
              <div className="input-group-prepend">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={ () => this.increment('minHour', -1) }
                >-
                </button>
              </div>

              <input
                type="text"
                className={ 'form-control text-center' }
                placeholder=""
                defaultValue={ 'Hours' }
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
                disabled={ true }/>

              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={ () => this.increment('minHour', +1) }>+
                </button>
              </div>
            </div>
          </div>

          <div style={ { gridArea: 'column-count-1', alignSelf: 'center', } }>{ this.state.minHour }</div>
          <div style={ { gridArea: 'column-count-2', alignSelf: 'center', } }>{ this.state.minHour + 1 }</div>
          <div style={ { gridArea: 'column-count-3', alignSelf: 'center', } }>{ this.state.minHour + 2 }</div>

          <div style={ { position: 'relative', gridArea: 'row-label' } }>
            <div
              className="input-group"
              style={ {
                transform: 'rotate(-90deg)',
                position: 'absolute',
                width: '12rem',
                left: '-75px',
                top: '75px',
              } }>
              <div className="input-group-prepend">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={ () => this.increment('minDrink', +1) }
                ><span style={ { transform: 'rotate(90deg)', } }>+</span>
                </button>
              </div>

              <input
                type="text"
                className={ 'form-control text-center' }
                placeholder=""
                defaultValue={ 'Drinks' }
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
                disabled={ true }/>

              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={ () => this.increment('minDrink', -1) }>
                  <span style={ { transform: 'rotate(90deg)', } }>-</span>
                </button>
              </div>
            </div>
          </div>

          <div style={ { gridArea: 'row-count-1', alignSelf: 'center', } }>{ this.state.minDrink }</div>
          <div
            style={ {
              gridArea: 'bac-1-1',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink, this.state.minHour) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-1-2',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink, this.state.minHour + 1) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-1-3',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink, this.state.minHour + 2) }%
          </div>

          <div style={ { gridArea: 'row-count-2', alignSelf: 'center', } }>{ this.state.minDrink + 1 }</div>
          <div
            style={ {
              gridArea: 'bac-2-1',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 1, this.state.minHour) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-2-2',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 1, this.state.minHour + 1) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-2-3',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 1, this.state.minHour + 2) }%
          </div>

          <div style={ { gridArea: 'row-count-3', alignSelf: 'center', } }>{ this.state.minDrink + 2 }</div>
          <div
            style={ {
              gridArea: 'bac-3-1',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 2, this.state.minHour) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-3-2',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 2, this.state.minHour + 1) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-3-3',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 2, this.state.minHour + 2) }%
          </div>

          <div style={ { gridArea: 'row-count-4', alignSelf: 'center', } }>{ this.state.minDrink + 3 }</div>
          <div
            style={ {
              gridArea: 'bac-4-1',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 3, this.state.minHour) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-4-2',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 3, this.state.minHour + 1) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-4-3',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 3, this.state.minHour + 2) }%
          </div>

          <div style={ { gridArea: 'row-count-5', alignSelf: 'center', } }>{ this.state.minDrink + 4 }</div>
          <div
            style={ {
              gridArea: 'bac-5-1',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 4, this.state.minHour) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-5-2',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 4, this.state.minHour + 1) }%
          </div>
          <div
            style={ {
              gridArea: 'bac-5-3',
              alignSelf: 'center',
            } }>{ this.setBac(this.state.minDrink + 4, this.state.minHour + 2) }%
          </div>
        </ChartGrid>

        <small
          className="form-text text-muted my-3">
          These are estimates and may underestimate true BAC.
          These values are not intended to convey that any drinking is safe.
          Alcohol use is illegal if under 21 and driving after any drinking is <u>not</u> recommended.
        </small>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    calculatorsActions: bindActionCreators(calculatorsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BacChart);