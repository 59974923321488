import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import * as settingsActions from '../../actions/settingsActions';

class SettingStorage extends Component {
  componentDidMount() {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate().then((estimate) => {
        this.props.settingsActions.setUsage(estimate);
      });
    }
  }

  render() {
    return (
      <div>
        <h5>

          Storage
        </h5>
        <Row>
          <Col>

            Used:
          </Col>
          <Col className="text-right text-muted">
            { this.props.settings.usage.usageStr }
          </Col>
        </Row>
        <Row>
          <Col>

            Available:
          </Col>
          <Col className="text-right text-muted">
            { this.props.settings.usage.quotaStr }
          </Col>
        </Row>
        <Row>
          <Col>

            Remaining:
          </Col>
          <Col className="text-right text-muted">
            { 100 - this.props.settings.usage.usagePercent }

            %
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingStorage);
