import produce from 'immer';
import initialState from '../initialState';
import {
  REMOVE_EVENTS,
  UPLOADING_VIDEOS,
  VIDEO_COMPLETED,
  VIDEO_EVENT,
  VIDEO_PROGRESS,
  VIDEO_STARTED
} from '../actions/videosActions';

export default function update(state = initialState.videos, action) {
  let newState;
  switch (action.type) {
    case REMOVE_EVENTS:
      newState = produce(state, (draft) => {
        draft[action.key].events = state[action.key].events.slice(action.length);
      });

      return newState;

    case UPLOADING_VIDEOS:
      if (state[action.key].uploading === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft[action.key].uploading = action.value;
      });
      return newState;

    case VIDEO_COMPLETED:
      if (state[action.key].completed === true) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft[action.key].completed = true;
        draft[action.key].progress = action.progress;
      });
      return newState;

    case VIDEO_EVENT:
      // this always results in new state
      newState = produce(state, (draft) => {
        draft[action.key].events.push(
          {
            event: action.event,
            progress: action.progress,
            stamp: action.stamp,
          }
        );
      });
      return newState;

    case VIDEO_PROGRESS:
      if (state[action.key].progress >= action.progress) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft[action.key].progress = action.progress;
      });
      return newState;

    case VIDEO_STARTED:
      if (state[action.key].started === true) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft[action.key].started = true;
      });
      return newState;

    default:
      return state;
  }
}
