import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';
import * as responsesActions from '../../../actions/responsesActions';
import moment from 'moment-timezone';

class Block extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayText: '',
      showElement: true,
    };
  }

  componentDidMount() {
    this.evalShowIf();
    this.evalPipes();
  }

  componentDidUpdate() {
    this.evalShowIf();
    this.evalPipes();
  }

  evalPipes() {
    const re = /{{pipe:(.+?):(.+?)}}/m;
    let matches;
    let { text } = this.props;
    let replacement = '??';

    while ((matches = re.exec(text))) {
      switch (matches[1]) {
        case 'count':
          if (matches[2] in this.props.count) {
            replacement = this.props.count[matches[2]];
          }
          break;
        case 'participant':
          if (matches[2] in this.props.participant.data) {
            replacement = this.props.participant.data[matches[2]];
          }
          break;
        case 'getCalendarString':
          switch (matches[2]) {
            case 'participantWindow.closed_at':
              replacement = moment(this.props.participantWindow.closed_at).calendar();
              break;
            default:
              break;
          }
          break;
        case 'getMomentInFormat':
          const pcs = matches[2].split('|');
          switch (pcs[0]) {
            case 'participantWindow.closed_at':
              replacement = moment(this.props.participantWindow.closed_at).format(pcs[1]);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      text = text.replace(matches[0], replacement);
    }

    if (text !== this.state.displayText) {
      this.setState({
        displayText: text,
      });
    }
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showElement) {
      this.setState({
        showElement: show,
      });
    }
  }

  render() {
    const wrapperClasses = classnames(
      'mt-3',
      'taker__element',
      'taker__text', {
        'd-none': !this.state.showElement,
        taker__pipe: this.state.containsPipe,
      },
    );

    return (
      <div className={ wrapperClasses }>
        <ReactMarkdown>
          { this.state.displayText }
        </ReactMarkdown>

        <hr/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);
