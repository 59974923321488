import produce from 'immer';
import initialState from '../initialState';
import { SET_ABV, SET_BAC } from '../actions/calculatorsActions';

export default function update(state = initialState.calculators, action) {
  let newState;
  switch (action.type) {
    case SET_BAC:
      if (state.bac[action.key] === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.bac[action.key] = action.value;
      });
      return newState;

    case SET_ABV:
      if (state.abv[action.key] === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.abv[action.key] = action.value;
      });
      return newState;

    default:
      return state;
  }
}
