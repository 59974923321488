import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import ErrorBoundary from './blocks/ErrorBoundary';
import WeeklySummaryBlock from './blocks/summary/WeeklySummaryBlock';
import styled from "styled-components";

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

class WeeklySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.setActiveTab(),
      currentWeek: this.countWeeks(),
      viewPage: 1,
    };
  }

  setActiveTab() {
    const weeksPassed = this.countWeeks();

    if (weeksPassed < 1) {
      return '1';
    }

    if (weeksPassed > 3) {
      return '3';
    }

    return weeksPassed.toString();
  }

  countWeeks() {
    const daysPassed = Math.floor((this.props.surveys.pastSurveys.length - 1) / 2);

    return Math.floor(daysPassed / 7);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        viewPage: 1,
      });
    }
  }

  summaryPage(page) {
    this.setState({
      viewPage: page,
    })
  }

  render() {
    if (!this.props.settings.auth.signedIn) {
      return <Redirect to="/tool-kit"/>;
    }

    let week1 = null;
    let week2 = null;
    let week3 = null;
    let pagination = null;

    if (this.state.activeTab === '1') {
      week1 = <WeeklySummaryBlock
        buildWeek={ 1 }
        currentWeek={ this.state.currentWeek }
        viewPage={ this.state.viewPage }
      />;
    }

    if (this.state.activeTab === '2') {
      week2 = <WeeklySummaryBlock
        buildWeek={ 2 }
        currentWeek={ this.state.currentWeek }
        viewPage={ this.state.viewPage }
      />;
    }

    if (this.state.activeTab === '3') {
      week3 = <WeeklySummaryBlock
        buildWeek={ 3 }
        currentWeek={ this.state.currentWeek }
        viewPage={ this.state.viewPage }
      />;
    }

    if (this.state.activeTab <= this.state.currentWeek) {
      pagination = <Row className="my-3 justify-content-center">
        <Col
          xs="6"
          sm="4"
          md="3"
          xl="2"
        >
          <div>
            <Button
              className={classnames('btn-block', {
                invisible: this.state.viewPage === 1
              })}
              color="secondary"
              outline
              label="back"
              onClick={ () => this.summaryPage(1) }
              disabled={ this.state.viewPage === 1 }
            >back</Button>
          </div>
        </Col>
        <Col
          xs="6"
          sm="4"
          md="3"
          xl="2"
        >
          <div>
            <Button
              className={classnames('btn-block', {
                invisible: this.state.viewPage === 2
              })}
              color="primary"
              outline
              label="next"
              onClick={ () => this.summaryPage(2) }
              disabled={ this.state.viewPage === 2 }
            >next</Button>
          </div>
        </Col>
      </Row>
    }

    return (
      <PageWrapper>
        <div>
          <h2>Weekly Summary</h2>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={ classnames({ active: this.state.activeTab === '1' }) }
                onClick={ () => {
                  this.toggle('1');
                } }
              >
                Week 1
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={ classnames({ active: this.state.activeTab === '2' }) }
                onClick={ () => {
                  this.toggle('2');
                } }
              >
                Week 2
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={ classnames({ active: this.state.activeTab === '3' }) }
                onClick={ () => {
                  this.toggle('3');
                } }
              >
                Week 3
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={ this.state.activeTab }
            className={ 'pt-3' }>
            <TabPane tabId="1">
              <ErrorBoundary>
                { week1 }
              </ErrorBoundary>
            </TabPane>

            <TabPane tabId="2">
              <ErrorBoundary>
                { week2 }
              </ErrorBoundary>
            </TabPane>

            <TabPane tabId="3">
              <ErrorBoundary>
                { week3 }
              </ErrorBoundary>
            </TabPane>
          </TabContent>
        </div>

        { pagination }

      </PageWrapper>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
)(WeeklySummary);
