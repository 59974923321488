import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';
import NumberInput from './NumberInput';
import * as responsesActions from '../../../actions/responsesActions';

class Number extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containsPipe: false,
      showElement: true,
    };

    if (props.text.indexOf('{{pipe:') > -1) {
      this.state.containsPipe = true;
    }
  }

  componentDidMount() {
    this.evalShowIf();
  }

  componentDidUpdate() {
    this.evalShowIf();
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showElement) {
      this.setState({
        showElement: show,
      });
    }
  }

  render() {
    const wrapperClasses = classnames(
      'mt-3',
      'taker__element',
      'taker__sqsa', {
        'd-none': !this.state.showElement,
      },
    );

    return (
      <div className={ wrapperClasses }>
        <Row>
          <Col
            md="6"
            className={ classnames('taker__text', { taker__pipe: this.state.containsPipe }) }
          >
            <ReactMarkdown>
              { this.props.text }
            </ReactMarkdown>
          </Col>
          <Col md="6">
            <Row>
              <Col xs={ { size: 11, offset: 1 } }>
                <NumberInput
                  label={ this.props.answers.answer_group_0[0].label }
                  parent={ this.props.slug }
                  slug={ this.props.answers.answer_group_0[0].slug }
                  responses={ this.props.responses }
                  nonValue={ this.props.surveys.data[this.props.participantWindow.survey].settings.non_value.value }
                  notShown={ this.props.surveys.data[this.props.participantWindow.survey].settings.not_shown.value }
                  participantWindow={ this.props.participantWindow }
                  props={ this.props.answers.answer_group_0[0].props }
                  showElement={ this.state.showElement }
                  showIf={ this.props.answers.answer_group_0[0].show_if }
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <hr/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Number);
