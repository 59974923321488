import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import ReactMarkdown from "react-markdown";

class RadioInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.checkedVal,
    };
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.checkedVal) {
      this.setState({ value: this.props.checkedVal });
    }
  }

  render() {
    return (
      <FormGroup check>
        <Label check>
          <Input
            className="input response"
            name={ this.props.name }
            type="radio"
            value={ this.props.value }
            checked={ this.props.value === this.state.value }
            onChange={ this.props.handleChange }
          />
          <ReactMarkdown>
          { this.props.label }
          </ReactMarkdown>
        </Label>
      </FormGroup>
    );
  }
}

export default RadioInput;
