import React, { Component } from 'react';
import { Input } from 'reactstrap';
import classnames from 'classnames';
import isEqual from 'react-fast-compare/index';
import { Link } from "react-router-dom";


class PageTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: 0,
    };
  }

  componentDidUpdate() {
    this.isDataLoaded();
  }

  isDataLoaded() {
    let loaded = (
      typeof this.props.summaries === "object" &&
      typeof this.props.summaries[this.state.period] === "object" &&
      Object.entries(this.props.summaries[this.state.period]).length > 0 &&
      this.state.dataLoaded === false);

    if (loaded) {
      this.setState({
        dataLoaded: true
      });

      this.viewStart();
      this.setBarChart();
    }

    return loaded;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(this.props.summaries, nextProps.summaries)) {
      return true;
    }

    if (nextProps.viewPage !== this.props.viewPage) {
      return true;
    }

    if (!isEqual(nextState, this.state)) {
      return true;
    }

    return false;
  }

  handleSelectChange(event) {
    const selectedCategory = parseInt(event.target.value);

    this.setState({ selectedCategory });
  }

  setConsequencesEndorsed() {
    const consequences = {
      'AM_CONSEQ_02_NEG': { text: 'had a hangover', count: 0 },
      'AM_CONSEQ_04_NEG': { text: 'became aggressive', count: 0 },
      'AM_CONSEQ_06_NEG': { text: 'felt nauseated or vomited', count: 0 },
      'AM_CONSEQ_07_NEG': { text: 'hurt or injured yourself by accident', count: 0 },
      'AM_CONSEQ_09_NEG': { text: 'didn\'t remember what you did while drinking', count: 0 },
      'AM_CONSEQ_11_NEG': { text: 'were unable to study', count: 0 },
      'AM_CONSEQ_13_NEG': { text: 'became rude or obnoxious', count: 0 },
      'AM_CONSEQ_15_NEG': { text: 'did something that embarrassed yourself', count: 0 },
      'AM_CONSEQ_16_NEG': { text: 'got into a serious fight', count: 0 },
      'AM_CONSEQ_17_NEG': { text: 'passed out or fainted suddenly', count: 0 },
      'AM_CONSEQ_18_NEG': { text: 'had a blackout', count: 0 },
      'AM_CONSEQ_20_NEG': { text: 'became overly emotional', count: 0 },
      'AM_CONSEQ_21_NEG': { text: 'drunk text /dialed', count: 0 },
      'AM_CONSEQ_22_NEG': { text: 'posted something you regretted on social media (e.g., Snapchat)', count: 0 },
      'AM_CONSEQ_24_NEG': { text: 'didn\'t get up and do what you were supposed to do', count: 0 },
      'AM_CONSEQ_26_NEG': { text: 'damaged property on purpose', count: 0 },
      'AM_CONSEQ_27_NEG': { text: 'spent too much money on things', count: 0 },
    };
    const consequencesEndorsed = [];

    if (this.state.selectedCategory !== 0) {
      for (const key in this.props.summary.day) {
        if (!this.props.summary.day.hasOwnProperty(key)) {
          continue;
        }

        const dayValues = this.props.summary.day[key];
        let nonBingeDay = false;
        let bingeDay = false;

        if (('AM_ALC02' in dayValues) && dayValues['AM_ALC02'] > 0) {
          nonBingeDay = true;
        }

        if (('AM_ALC02' in dayValues) && dayValues['AM_ALC02'] >= this.props.summary.binge_val) {
          nonBingeDay = false;
          bingeDay = true;
        }

        for (const varName in dayValues) {
          if (!dayValues.hasOwnProperty(varName)) {
            continue;
          }

          if (!(varName in consequences)) {
            continue;
          }

          if (dayValues[varName] !== 1) {
            continue;
          }

          if ((this.state.selectedCategory === 1 && nonBingeDay) ||
            (this.state.selectedCategory === 2 && bingeDay)) {
            consequences[varName].count++;
          }
        }
      }

      for (const varName in consequences) {
        if (!consequences.hasOwnProperty(varName)) {
          continue;
        }

        if (consequences[varName].count > 0) {
          consequencesEndorsed.push([consequences[varName].text, consequences[varName].count]);
        }
      }
    }

    if (consequencesEndorsed.length === 0) {
      consequencesEndorsed.push(['No consequences reported', 0])
    }

    return consequencesEndorsed;
  }

  setDaysInCategory() {
    let daysInCategory = 0;

    for (const key in this.props.summary.day) {
      if (!this.props.summary.day.hasOwnProperty(key)) {
        continue;
      }

      const dayValues = this.props.summary.day[key];
      let nonDrinkingDay = true;
      let nonBingeDay = false;
      let bingeDay = false;

      if (('AM_ALC02' in dayValues) && dayValues['AM_ALC02'] > 0) {
        nonDrinkingDay = false;
        nonBingeDay = true;
      }

      if (('AM_ALC02' in dayValues) && dayValues['AM_ALC02'] >= this.props.summary.binge_val) {
        nonDrinkingDay = false;
        nonBingeDay = false;
        bingeDay = true;
      }

      if (this.state.selectedCategory === 0 && nonDrinkingDay === true) {
        daysInCategory++;
      }

      if (this.state.selectedCategory === 1 && nonBingeDay === true) {
        daysInCategory++;
      }

      if (this.state.selectedCategory === 2 && bingeDay === true) {
        daysInCategory++;
      }
    }

    return daysInCategory;
  }

  avgMoodString() {
    let val;

    switch (this.state.selectedCategory) {
      case 0:
        val = this.props.summary.avg['AM_DAYMOOD_NON-DRINK'];
        break;
      case 1:
        val = this.props.summary.avg['AM_DAYMOOD_NON-BINGE'];
        break;
      case 2:
        val = this.props.summary.avg['AM_DAYMOOD_BINGE'];
        break;
      default:
        break;
    }

    if (val <= 0) {
      return 'Not reported';
    }
    if (val < 1.5) {
      return 'Very bad';
    }
    if (val < 2.5) {
      return 'Bad';
    }
    if (val < 3.5) {
      return 'Moderate';
    }
    if (val < 4.5) {
      return 'Good';
    }
    return 'Very good';
  };

  avgSleepString() {
    let val;

    switch (this.state.selectedCategory) {
      case 0:
        val = this.props.summary.avg['AM_SLEEP04_NON-DRINK'];
        break;
      case 1:
        val = this.props.summary.avg['AM_SLEEP04_NON-BINGE'];
        break;
      case 2:
        val = this.props.summary.avg['AM_SLEEP04_BINGE'];
        break;
      default:
        break;
    }

    if (val <= 0) {
      return 'Not reported';
    }
    if (val < 1.5) {
      return 'Very bad';
    }
    if (val < 2.5) {
      return 'Bad';
    }
    if (val < 3.5) {
      return 'Moderate';
    }
    if (val < 4.5) {
      return 'Good';
    }
    return 'Very good';
  };

  moodStatement() {
    let moodStatement = 0;

    if (this.state.selectedCategory === 1) {
      // compare NON-BINGE with NON-DRINK
      moodStatement = 2;
      if (this.props.summary.avg['AM_DAYMOOD_NON-BINGE'] > this.props.summary.avg['AM_DAYMOOD_NON-DRINK']) {
        moodStatement = 1;
      }
    }

    if (this.state.selectedCategory === 2) {
      // compare BINGE with NON-DRINK
      moodStatement = 2;
      if (this.props.summary.avg['AM_DAYMOOD_BINGE'] > this.props.summary.avg['AM_DAYMOOD_NON-DRINK']) {
        moodStatement = 1;
      }
    }

    return moodStatement;
  }

  static setConsequencesRows(consequencesEndorsed) {
    return consequencesEndorsed.map((item, index) => {
      if (item[1] === 0) {
        return (
          <tr key={ index }>
            <td>{ item[0] }</td>
          </tr>
        )
      }

      return (
        <tr key={ index }>
          <td>{ item[0] }</td>
          <td className={ 'w-25' }>
            { item[1] < 1 ? '' : (item[1] === 1 ? `${ item[1] } day` : `${ item[1] } days`) }
          </td>
        </tr>
      )
    });
  }

  render() {
    const consequencesEndorsed = this.setConsequencesEndorsed();
    const consequencesRows = PageTwo.setConsequencesRows(consequencesEndorsed);
    const drinkingCategory = [
      'non-drinking days',
      `days you drank ${ this.props.summary.binge_val - 1 } or fewer drinks`,
      `days you drank ${ this.props.summary.binge_val } or more drinks`
    ];
    const daysInCategory = this.setDaysInCategory();
    let burstSummaryLink = null;

    if (this.props.buildWeek === 3) {
      burstSummaryLink = <div className={'mt-5 d-flex align-items-center'}>
        <Link to={'/burst-summary'}>
          <img src={'/img/21-day-summary.png'} alt={'21 Day Summary button'} style={{ maxWidth: '5rem',}}/>
        </Link>
        <div className={'pl-3'}>
          <Link to={'/burst-summary'}>Click here</Link> to view your 21 day summary.
        </div>
      </div>
    }

    return (
      <div>
        <p>
          This past week you had { this.props.summary.sum.AM_ALC02 } drinks.
        </p>

        <p>
          Oftentimes we can find patterns in what and how we experience things.{ ' ' }
          <span className={ 'font-weight-bold' }>
              Select a drinking level to see your patterns this past week.
            </span>
        </p>

        <Input
          className={ 'my-3' }
          type={ 'select' }
          name={ 'selectPattern' }
          value={ this.state.selectedCategory }
          onChange={ (e) => this.handleSelectChange(e) }>
          <option
            value={ 0 }>
            { drinkingCategory[0] }
          </option>

          <option
            value={ 1 }>
            { drinkingCategory[1] }
          </option>

          <option
            value={ 2 }>
            { drinkingCategory[2] }
          </option>
        </Input>

        <p className={ classnames({
          'd-none': this.state.selectedCategory === 0 || daysInCategory === 0
        }) }>
          On { drinkingCategory[this.state.selectedCategory] } you reported the following not-so-good alcohol effects:
        </p>

        <table className={ classnames(
          'table', 'table-info', 'table-bordered', {
            'd-none': this.state.selectedCategory === 0 || daysInCategory === 0
          }) }>
          <tbody>
          { consequencesRows }
          </tbody>
        </table>

        <p className={ classnames({
          'd-none': this.state.selectedCategory === 0 || daysInCategory === 0
        }) }>
          Staying at a lower amount of drinks is less likely to result in negative experiences,
          and more likely to result in positive experiences. Most positive social effects of alcohol
          are influenced by our expectations and are best at lower doses of alcohol.
        </p>

        <p className={ classnames('table-info', 'px-2', 'py-1', 'my-4', {
          'd-none': daysInCategory === 0
        }) }>
          Average Mood: { this.avgMoodString() }
        </p>

        <p className={ classnames('text-muted', {
          'd-none': this.state.selectedCategory !== 0 || (this.state.selectedCategory === 0 && daysInCategory !== 0)
        }) }>
          You only reported drinking days this week.
          Please select the other drinking levels for mood and sleep patterns.
        </p>

        <p className={ classnames('text-muted', {
          'd-none': this.state.selectedCategory !== 1 || (this.state.selectedCategory === 1 && daysInCategory !== 0)
        }) }>
          You did not report any days you drank { this.props.summary.binge_val - 1 } or fewer drinks this week.
          Please select the other drinking levels for mood and sleep patterns.
        </p>

        <p className={ classnames('text-muted', {
          'd-none': this.state.selectedCategory !== 2 || (this.state.selectedCategory === 2 && daysInCategory !== 0)
        }) }>
          You did not report any days you drank { this.props.summary.binge_val } or more drinks this week.
          Please select the other drinking levels for mood and sleep patterns.
        </p>

        <p className={ classnames({
          'd-none': this.moodStatement() !== 1 || daysInCategory === 0
        }) }>
          People may drink on days when they are already in a better mood as a way to "keep feeling good" and
          continue to enhance their mood. Research from UW has shown that being social (without drinking)
          is one way to increase positive mood and protect against negative mood the next day.
          What social activities could you do on days when you're already in a good mood?
        </p>

        <p className={ classnames({
          'd-none': this.moodStatement() !== 2  || daysInCategory === 0
        }) }>
          People may drink on days when they are already in a low (or "bad") mood because they expect that
          drinking will help them feel better. However, research from UW has shown that one way to increase
          your mood is to be social (without drinking). What social activities could you do on days when your
          mood is low?
        </p>

        <p className={ classnames('table-info', 'px-2', 'py-1', 'my-4', {
          'd-none': daysInCategory === 0
        }) }>
          Average Sleep Quality: { this.avgSleepString() }
        </p>

        <p className={ classnames({
          'd-none': this.state.selectedCategory === 0 || daysInCategory === 0
        }) }>
          Drinking alcohol close to falling asleep can affect your sleep cycles.
          Depending on how much alcohol is in your system, it can affect your sleep for up to three nights.
        </p>

        {burstSummaryLink}
      </div>
    );
  }
}

export default (PageTwo);