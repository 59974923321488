import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import style from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Foot = style.footer`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-around;
`;
const IconWrapper = style.div`
  padding-top: 5px; 
  padding-bottom: 5px; 
`;

const LinkWrapper = style.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
`;

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.closeNavbar = this.closeNavbar.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  closeNavbar() {
    if (this.state.isOpen === true) {
      this.toggle();
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const loggedIn = this.props.settings.auth.signedIn;
    let loggedInIntervention = false;
    let onboardingComplete = false;

    if (loggedIn &&
      typeof this.props.participant.data.rand_code === 'string' &&
      this.props.participant.data.rand_code.indexOf('INTERVENTION') > -1) {
      loggedInIntervention = true;
    }

    try {
      if (this.props.surveys.schedule.schedule.burst_1.survey_0.completed_at !== null) {
        onboardingComplete = true;
      }
    } catch (e) {
    }

    let surveyIcon = (
      <FontAwesomeIcon
        icon={ ['fal', 'ballot-check'] }
        transform="grow-6"
      />
    );
    let summaryIcon = (
      <FontAwesomeIcon
        icon={ ['fal', 'chart-pie'] }
        transform="grow-6"
      />
    );
    let toolboxIcon = (
      <FontAwesomeIcon
        icon={ ['fal', 'toolbox'] }
        transform="grow-6"
      />
    );
    let videosIcon = (
      <FontAwesomeIcon
        icon={ ['fal', 'video'] }
        transform="grow-6"
      />
    );
    let messagesIcon = (
      <FontAwesomeIcon
        icon={ ['fal', 'comment-alt-lines'] }
        transform="grow-6"
      />
    );

    switch (this.props.location.pathname) {
      case '/survey':
        surveyIcon = (
          <FontAwesomeIcon
            icon={ ['fas', 'ballot-check'] }
            transform="grow-6"
          />
        );
        break;
      case '/weekly-summary':
        summaryIcon = (
          <FontAwesomeIcon
            icon={ ['fas', 'chart-pie'] }
            transform="grow-6"
          />
        );
        break;
      case '/bac-chart':
      case '/calculators':
      case '/helpful-messages':
      case '/resources':
      case '/standard-drinks':
      case '/survey-completion':
      case '/tips':
      case '/tool-kit':
        toolboxIcon = (
          <FontAwesomeIcon
            icon={ ['fas', 'toolbox'] }
            transform="grow-6"
          />
        );
        break;
      case '/videos':
        videosIcon = (
          <FontAwesomeIcon
            icon={ ['fas', 'video'] }
            transform="grow-6"
          />
        );
        break;
      case '/messages':
        messagesIcon = (
          <FontAwesomeIcon
            icon={ ['fas', 'comment-alt-lines'] }
            transform="grow-6"
          />
        );
        break;
      default:
        break;
    }

    let surveysLink = <div></div>;
    if (loggedIn) {
      surveysLink = <Link
        className={'nav-link p-0'}
        aria-label={ 'Survey' }
        to="/survey"
      >
        <LinkWrapper>
          <IconWrapper>
            { surveyIcon }
          </IconWrapper>
          <small>Surveys</small>
        </LinkWrapper>
      </Link>
    }

    let messagesLink = <div></div>;
    if (loggedInIntervention && onboardingComplete) {
      messagesLink = <Link
        className={'nav-link p-0'}
        aria-label={ 'Messages' }
        to="/messages"
      >
        <LinkWrapper>
          <IconWrapper>
            { messagesIcon }
          </IconWrapper>
          <small>Messages</small>
        </LinkWrapper>
      </Link>
    }

    let weeklySummaryLink = <div></div>;
    if (loggedInIntervention && onboardingComplete) {
      weeklySummaryLink = <Link
        className={'nav-link p-0'}
        aria-label={ 'Weekly Summary' }
        to="/weekly-summary"
      >
        <LinkWrapper>
          <IconWrapper>
            { summaryIcon }
          </IconWrapper>
          <small>Summary</small>
        </LinkWrapper>
      </Link>
    }

    let videosLink = <div></div>;
    if (loggedInIntervention && onboardingComplete) {
      videosLink = <Link
        className={'nav-link p-0'}
        aria-label={ 'Videos' }
        to="/videos"
      >
        <LinkWrapper>
          <IconWrapper>
            { videosIcon }
          </IconWrapper>
          <small>Videos</small>
        </LinkWrapper>
      </Link>
    }

    let toolKitLink = <div></div>;
    if (loggedIn === false || onboardingComplete) {
      toolKitLink = <Link
        className={'nav-link p-0'}
        aria-label={ 'Tool Kit' }
        to="/tool-kit"
      >
        <LinkWrapper>
          <IconWrapper>
            { toolboxIcon }
          </IconWrapper>
          <small>Toolkit</small>
        </LinkWrapper>
      </Link>
    }

    return (
      <Foot>
        { surveysLink }

        { messagesLink }

        { weeklySummaryLink }

        { videosLink }

        { toolKitLink }
      </Foot>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default withRouter(connect(
  mapStateToProps,
)(Footer));
