import moment from 'moment-timezone';
import axios from "axios";

export const REMOVE_EVENTS = 'REMOVE_EVENTS';
export const UPLOADING_VIDEOS = 'UPLOADING_VIDEOS';
export const VIDEO_COMPLETED = 'VIDEO_COMPLETED';
export const VIDEO_EVENT = 'VIDEO_EVENT';
export const VIDEO_PROGRESS = 'VIDEO_PROGRESS';
export const VIDEO_STARTED = 'VIDEO_STARTED';

export const videoCompleted = (key, progress) => {
  return { type: VIDEO_COMPLETED, key, progress: Math.round(progress) };
};

export const videoEvent = (key, progress, event) => {
  const stamp = parseInt(moment().format('X'));
  return { type: VIDEO_EVENT, key, event, stamp, progress: Math.round(progress) };
};

export const videoProgress = (key, progress) => {
  return { type: VIDEO_PROGRESS, key, progress: Math.round(progress) };
};

export const videoStarted = (key) => {
  return { type: VIDEO_STARTED, key };
};

export const submitEvents = (key) => {
  console.log('submit video ', key);

  return (dispatch, getState) => {
    const url = `${ process.env.REACT_APP_API_URL }/track-events`;
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ getState().settings.auth.accessToken }`,
      },
    };
    const video = getState().videos[key];
    const events = video.events.map((item) => {
      return {
        event: item.event,
        tag: 'video',
        occurred_at: moment.unix(item.stamp).utc().format('YYYY-MM-DD HH:mm:ss'),
        name: video.name,
        key: key,
        progress: item.progress,
      }
    });

    console.log('uploading video events: ', events);

    const data = {
      events,
    };

    dispatch({
      type: UPLOADING_VIDEOS,
      value: moment(),
      key,
    });

    axios.post(url, data, config)
      .then((res) => {
        dispatch({
          type: REMOVE_EVENTS,
          key: res.data.events[0].key,
          length: res.data.events.length,
        });
      })
      .catch((error) => {
        // error handling
        const log = {
          error: JSON.stringify({
            app: 'uwamps_pwa',
            altpid: getState().participant.data.altpid || 'not set',
            endpoint: error.config.url,
            message: error.message,
            origin: 'videosAction.submitEvents',
          })
        };

        axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: UPLOADING_VIDEOS,
          value: null,
          key,
        });
      });
  };
};