import produce from 'immer';
import initialState from '../initialState';
import {
  FETCHING_AUTH,
  IS_ONLINE,
  SET_AUTH,
  SET_USAGE,
  SIGN_IN,
  SIGN_OUT,
  SYNC_PERMISSION,
  UPDATE_TIMEZONE,
} from '../actions/settingsActions';

export default function update(state = initialState.settings, action) {
  let newState;
  switch (action.type) {
    case FETCHING_AUTH:
      if (state.auth.isFetching === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.auth.isFetching = action.value;
      });
      return newState;

    case IS_ONLINE:
      if (state.isOnline === action.bool) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.isOnline = action.bool;
      });
      return newState;

    case SET_AUTH:
      if (state.auth[action.key] === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.auth[action.key] = action.value;
      });
      return newState;

    case SET_USAGE:
      if (state.usage.quota === action.quota && state.usage.usage === action.usage) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.usage.quota = action.quota;
        draft.usage.quotaStr = action.quotaStr;
        draft.usage.usage = action.usage;
        draft.usage.usageStr = action.usageStr;
        draft.usage.usagePercent = action.usagePercent;
      });
      return newState;

    case SIGN_IN:
      newState = produce(state, (draft) => {
        draft.auth.signedIn = true;
        draft.auth.accessToken = action.accessToken;
        draft.auth.accessTokenDecoded = action.accessTokenDecoded;
        draft.auth.slug = action.slug;
      });
      return newState;

    case SIGN_OUT:
      newState = produce(state, (draft) => {
        draft.auth.signedIn = false;
        draft.auth.slug = '';
        draft.auth.phone = '';
        draft.auth.accessToken = '';
        draft.auth.accessTokenDecoded = {};
      });

      return newState;

    case SYNC_PERMISSION:
      if (state.permissions[action.key] === action.value) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.permissions[action.key] = action.value;
      });
      return newState;

    case UPDATE_TIMEZONE:
      if (state.timezone === action.timezone) {
        return state;
      }

      newState = produce(state, (draft) => {
        draft.timezone = action.timezone;
      });
      return newState;

    default:
      return state;
  }
}
