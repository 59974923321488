import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as interventionMessagesActions from '../../actions/interventionMessagesActions';
import React, { Component } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown/with-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';

function Image(props) {
  return <img { ...props } alt={ '' }
              style={ { maxWidth: '80vw', display: 'block', marginLeft: 'auto', marginRight: 'auto', } }/>
}

class InterventionMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewStart: null,
      ratingText: '',
    };
  }

  componentDidMount() {
    if (this.props.active && this.state.viewStart === null) {
      this.viewStart();
    }
  }

  componentDidUpdate() {
    if (this.props.active && this.state.viewStart === null) {
      this.viewStart();
    }

    if (!this.props.active && this.state.viewStart !== null) {
      this.viewEnd();
    }
  }

  componentWillUnmount() {
    if (this.state.viewStart !== null) {
      this.viewEnd();
    }
  }

  handleTextareaChange(event) {
    this.setState({
      ratingText: event.target.value
    });
  }

  setHelpful(helpful) {
    const index = this.props.msgIndex;
    const item = this.props.interventionMessages.messages[index];

    this.props.interventionMessagesActions.setHelpful(
      index,
      helpful,
      item,
      this.props.settings.auth.accessToken
    );
  }

  setRating(event) {
    const index = this.props.msgIndex;
    const item = this.props.interventionMessages.messages[index];
    const rating = event.target.value;

    this.props.interventionMessagesActions.setRating(
      index,
      rating,
      item,
      this.props.settings.auth.accessToken
    );
  }

  setRatingText(event) {
    const index = this.props.msgIndex;
    const item = this.props.interventionMessages.messages[index];
    const rating = this.state.ratingText;

    this.props.interventionMessagesActions.setRating(
      index,
      rating,
      item,
      this.props.settings.auth.accessToken
    );
  }

  viewStart() {
    const item = this.props.interventionMessages.messages[this.props.msgIndex];

    this.props.interventionMessagesActions.setViewedLast(this.props.msgIndex);

    if (item.viewed_at === null) {
      this.props.interventionMessagesActions.setViewedAt(
        this.props.msgIndex,
        item,
        this.props.settings.auth.accessToken
      );
    }

    this.setState({
      viewStart: moment(),
    });
  }

  viewEnd() {
    const viewEnd = moment();
    const elapsed = Math.ceil((viewEnd - this.state.viewStart) / 1000);

    this.props.interventionMessagesActions.incrementViewed(this.props.msgIndex, elapsed);

    this.setState({
      viewStart: null
    });
  }

  getIntroString(templateId) {
    switch (templateId) {
      case 'am-2':
      case 'am_fallback-2':
        return { __html: '<b>To what extent do you feel the images from media/advertisements tries to portray alcohol as enhancing one’s sex life?</b>' };
      case 'am-7':
      case 'am_fallback-7':
        return { __html: 'To what extent do you feel the alcohol-related posts you see by your friends on social media are embellished to show positive effects of alcohol?' };
      case 'am-11':
      case 'am_fallback-11':
        return { __html: 'To what extent do you feel the alcohol-related images/messages seen on TV, movies, or in advertisements <u>over portray</u> positive effects of alcohol?' };
      case 'am-16':
      case 'am_fallback-16':
        return { __html: 'To what extent do you think positive images/media about alcohol influences what college student\'s expect will happen if they drink?' };
      case 'am-20':
      case 'am_fallback-20':
        return { __html: 'To what extent does your family influence and inform your beliefs about alcohol today?' };
      case 'non_drinking-8':
        return { __html: 'To what extent do you think your mood, sleep, and alcohol use influence each other?' };
      case 'drinking-15':
      case 'non_drinking-15':
        return { __html: 'To what extent do you think your mood, sleep, and alcohol use influence each other?' };
      default:
        // do nothing
        return { __html: '' };
    }
  }

  render() {
    let rating = null;
    let helpful = null;

    if (this.props.rating === null &&
      [
        'am-2', 'am-7', 'am-11', 'am-16', 'am-20',
        'am_fallback-2', 'am_fallback-7', 'am_fallback-11', 'am_fallback-16', 'am_fallback-20',
        'non_drinking-8', 'non_drinking-15',
        'drinking-15',
      ].indexOf(this.props.templateId) > -1) {
      rating =
        <div>
          <p dangerouslySetInnerHTML={ this.getIntroString(this.props.templateId) }/>
          <FormGroup
            tag="fieldset"
            className={ 'ml-3 my-3' }>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={ 'msgRate_' + this.props.templateId }
                  value={ 0 }
                  onChange={ (e) => this.setRating(e) }/>{ ' ' }
                Not at all
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={ 'msgRate_' + this.props.templateId }
                  value={ 1 }
                  onChange={ (e) => this.setRating(e) }/>{ ' ' }
                A little
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={ 'msgRate_' + this.props.templateId }
                  value={ 2 }
                  onChange={ (e) => this.setRating(e) }/>{ ' ' }
                Moderately
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={ 'msgRate_' + this.props.templateId }
                  value={ 3 }
                  onChange={ (e) => this.setRating(e) }/>{ ' ' }
                Very
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={ 'msgRate_' + this.props.templateId }
                  value={ 4 }
                  onChange={ (e) => this.setRating(e) }/>{ ' ' }
                Extremely
              </Label>
            </FormGroup>
          </FormGroup>
        </div>;
    }

    if (this.props.rating === null &&
      [
        'am-19', 'am-21',
        'am_fallback-19', 'am_fallback-21',
      ].indexOf(this.props.templateId) > -1) {
      let str = '';

      switch (this.props.templateId) {
        case 'am-19':
          str = '';
          break;
        case 'am-21':
          str = '';
          break;
        default:
          // do nothing
          break;
      }

      rating =
        <div>
          <p>
            { str }
          </p>
          <FormGroup
            className={ 'my-3' }>
            <Input
              type="textarea"
              name={ 'msgRate_' + this.props.templateId }
              onChange={ (e) => this.handleTextareaChange(e) }
              value={ this.state.ratingText }/>
          </FormGroup>
          <div className={ 'd-flex justify-content-end' }>
            <Button
              outline
              color="success"
              onClick={ (e) => this.setRatingText(e) }
            >save</Button>
          </div>
        </div>
    }

    if (this.props.rating === null &&
      [
        'am-8', 'am-15', 'am-22',
        'am_fallback-8', 'am_fallback-15', 'am_fallback-22'
      ].indexOf(this.props.templateId) > -1) {
      rating =
        <div></div>
    }

    if (rating === null && this.props.helpful === null) {
      helpful =
        <Row className={ 'mx-1 my-3' }>
          <Col>
            <Button
              className={ 'px-1 w-100 d-flex justify-content-around' }
              onClick={ () => this.setHelpful(true) }
              outline
              color="success"
            >
              <FontAwesomeIcon
                icon={ ['fal', 'thumbs-up'] }
                transform="flip-h down-4"/>
              <span>{ 'helpful' }</span>
            </Button>
          </Col>
          <Col>
            <Button
              className={ 'px-1 w-100 d-flex justify-content-around' }
              onClick={ () => this.setHelpful(false) }
              outline
              color="danger"
            >
              <span>{ 'not helpful' }</span>
              <FontAwesomeIcon
                icon={ ['fal', 'thumbs-up'] }
                transform="flip-v down-4"/>
            </Button>
          </Col>
        </Row>;
    }

    return (
      <div>
        <ReactMarkdown
          renderers={ { image: Image } }
          source={ this.props.message }
          escapeHtml={ false }
        />

        { rating }

        { helpful }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    interventionMessagesActions: bindActionCreators(interventionMessagesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionMessage);