import jwtDecode from 'jwt-decode';
import axios from 'axios';
import Cookies from 'js-cookie';

export const FETCHING_AUTH = 'FETCHING_AUTH';
export const IS_ONLINE = 'IS_ONLINE';
export const SET_AUTH = 'SET_AUTH';
export const SET_USAGE = 'SET_USAGE';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SYNC_PERMISSION = 'SYNC_PERMISSION';
export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE';

export const getReadableFileSizeString = (bytes) => {
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    bytes /= 1024;
    i++;
  } while (bytes > 1024);

  return Math.max(bytes, 0.1).toFixed(1) + byteUnits[i];
};


export const setAuth = (key, value) => {
  return {
    type: SET_AUTH,
    key,
    value,
  };
};

export const setUsage = (estimate) => {
  const { usage } = estimate;
  const quota = Math.round(estimate.quota / 5);

  return (dispatch) => {
    dispatch({
      type: SET_USAGE,
      usage,
      usageStr: getReadableFileSizeString(usage),
      quota,
      quotaStr: getReadableFileSizeString(quota),
      usagePercent: Math.round(usage / quota * 100),
    });
  };
};

export const signIn = (accessToken, slug) => {
  const accessTokenDecoded = jwtDecode(accessToken);
  return {
    type: SIGN_IN,
    accessToken,
    accessTokenDecoded,
    slug,
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const syncPermission = (key, value) => {
  return {
    type: SYNC_PERMISSION,
    key,
    value,
  };
};

export const updateTimezone = (timezone) => {
  return {
    type: UPDATE_TIMEZONE,
    timezone,
  };
};

export const fetchAuthUser = (phone, slug) => {
  const url = `${ process.env.REACT_APP_API_URL }/participant-tokens`;
  const config = {
    headers: {
      Accept: 'application/json',
    },
  };
  const data = {
    phone,
    slug,
  };

  return (dispatch, getState) => {
    dispatch({
      type: FETCHING_AUTH,
      value: true,
    });

    axios.post(url, data, config)
      .then((res) => {
        const token = res.data.data.access_token;
        const { slug } = res.data.data;

        Cookies.set('token', token, { expires: 365 });
        Cookies.set('participant', slug, { expires: 365 });

        dispatch(
          signIn(token, slug),
        );
      })
      .catch((error) => {
        // error handling
        const log = {
          error: JSON.stringify({
            app: 'uwamps_pwa',
            altpid: getState().participant.data.altpid || 'not set',
            endpoint: error.config.url,
            message: error.message,
            origin: 'settingsAction.fetchAuthUser',
            phone: phone,
            slug: slug,
          })
        };

        axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);

        dispatch(signOut());
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: FETCHING_AUTH,
          value: false,
        });
      });
  };
};

export const fetchConnectionStatus = () => {
  const url = 'https://ipv4.icanhazip.com/';
  const timeout = 5000;

  return (dispatch) => {
    axios.get(url, { timeout })
      .then(() => {
        dispatch({
          type: IS_ONLINE,
          bool: true,
        });
      })
      .catch(() => {
        dispatch({
          type: IS_ONLINE,
          bool: false,
        });
      });
  };
};
