import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import jsonLogic from 'json-logic-js';
import GridCheckboxInput from './GridCheckboxInput';
import * as responsesActions from '../../../actions/responsesActions';

class GridCheckboxRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containsPipe: false,
      showRow: true,
    };

    if (props.text.indexOf('{{pipe:') > -1) {
      this.state.containsPipe = true;
    }
  }

  componentDidMount() {
    this.evalShowIf();
  }

  componentDidUpdate() {
    this.evalShowIf();
  }

  evalShowIf() {
    const data = {
      ...this.props.responses[this.props.participantWindow.slug].responses,
      ...this.props.surveys.schedule.map[this.props.participantWindow.slug],
      streak: this.props.streak,
      rand_code: this.props.participant.data.rand_code,
    };
    const rules = (JSON.parse(this.props.show_if));
    const show = jsonLogic.apply(rules, data);

    if (rules === null) {
      return;
    }

    if (show !== this.state.showRow) {
      this.setState({
        showRow: show,
      });
    }
  }

  render() {
    const wrapperClasses = classnames(
      'mt-3',
      'taker__mqsa--row', {
        'd-none': !this.state.showRow,
      },
    );
    const textClasses = classnames(
      'taker__text', {
        taker__pipe: this.state.containsPipe,
      },
    );
    const checkboxes = this.props.gridAnswers.map((answer) => {
      const key = `${ this.props.slug }|${ answer.slug }`;
      const name = `${ answer.element }|${ answer.slug }`;
      return (
        <div
          key={ key }
          className="col-md text-md-center align-self-start"
        >
          <GridCheckboxInput
            answer={ answer.slug }
            element={ answer.element }
            name={ name }
            participantWindow={ this.props.participantWindow }
            showElement={ this.props.showElement && this.state.showRow }
            { ...answer }
          />
        </div>
      );
    });

    return (
      <Row className={ wrapperClasses }>
        <Col
          xs="12"
          md="4"
          className={ textClasses }
        >
          <ReactMarkdown>
            { this.props.text }
          </ReactMarkdown>
        </Col>
        <Col
          xs={ { size: 11, offset: 1 } }
          md={ { size: 8, offset: 0 } }
        >
          <Row>
            <Col md={ { size: 11, offset: 1 } }>
              <Row>
                { checkboxes }
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    responsesActions: bindActionCreators(responsesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridCheckboxRow);
