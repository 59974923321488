import moment from 'moment-timezone';
import axios from 'axios';

export const BEGIN_COLLECTION = 'BEGIN_COLLECTION';
export const END_COLLECTION = 'END_COLLECTION';
export const RECORD_RESPONSE = 'RECORD_RESPONSE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOADING_RESPONSES = 'UPLOADING_RESPONSES';

export const beginCollection = (participantWindow) => {
  return (dispatch) => {
    dispatch({
      type: BEGIN_COLLECTION,
      participantWindow,
      meta: {
        started_at: moment(),
        active_at: null,
        completed_at: null,
        page: 1,
        percent: 0,
        uploaded_at: null,
        isUploading: null,
      },
      responses: {},
    });
  };
};

export const endCollection = (participantWindow, page, percent) => {
  return (dispatch) => {
    dispatch({
      type: END_COLLECTION,
      participantWindow,
      completed_at: moment(),
      page,
      percent,
    });
  };
};

export const recordResponse = (answer, element, name, value, participantWindow) => {
  return (dispatch) => {
    dispatch({
      type: RECORD_RESPONSE,
      participantWindow,
      active_at: moment(),
      response: {
        stamp: moment(),
        answer,
        element,
        name,
        value,
      },
    });
  };
};

export const responseCleanup = (participantWindow) => {
  return (dispatch, getState) => {
    const uploadedAt = getState().responses[participantWindow].meta.uploaded_at;

    dispatch({
      type: UPLOAD_SUCCESS,
      participantWindow,
      uploaded_at: uploadedAt,
    });
  }
};

export const updatePage = (participantWindow, page, percent) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PAGE,
      participantWindow,
      active_at: moment(),
      page,
      percent,
    });
  };
};

export const submitResponses = (participantWindow) => {
  return (dispatch, getState) => {
    const url = `${ process.env.REACT_APP_API_URL }/submit`;
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ getState().settings.auth.accessToken }`,
      },
    };
    const responses = getState().responses[participantWindow];

    const data = { ...responses.meta };
    data.participantWindow = participantWindow;
    data.responses = { ...responses.responses };

    dispatch({
      type: UPLOADING_RESPONSES,
      participantWindow,
      value: moment(),
    });

    axios.post(url, data, config)
      .then(() => {
        dispatch({
          type: UPLOAD_SUCCESS,
          participantWindow,
          uploaded_at: moment(),
        });
      })
      .catch((error) => {
        // error handling
        const log = {
          error: JSON.stringify({
            app: 'uwamps_pwa',
            altpid: getState().participant.data.altpid || 'not set',
            endpoint: error.config.url,
            message: error.message,
            meta: responses.meta,
            origin: 'responsesAction.submitResponses',
            participantWindow: participantWindow,
          })
        };

        axios.post('https://intense-cliffs-12346.herokuapp.com/errors', log);
      })
      .then(() => {
        // this will always fire
        dispatch({
          type: UPLOADING_RESPONSES,
          participantWindow,
          value: null,
        });
      });
  };
};
