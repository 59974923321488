import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    // You can also log error messages to an error reporting service here
    fetch('https://intense-cliffs-12346.herokuapp.com/errors',{
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({
        error: error,
        errorInfo: errorInfo
      })
    })
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={ { whiteSpace: 'pre-wrap' } }>
            { this.state.error && this.state.error.toString() }
          </details>
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}